import { SponsoredGamesDataProvider } from '../../contexts/SponsoredGamesContextProvider'
import { SponsoredShopPageLayout } from './SponsoredShopPageLayout'

export const SponsoredShopPage: React.FC = () => {
  return (
    <SponsoredGamesDataProvider>
      <SponsoredShopPageLayout />
    </SponsoredGamesDataProvider>
  )
}
