import { useTranslation } from 'react-i18next'
import { WelcomeBlockData } from '../helpers'
import { WelcomeBanner } from './WelcomeBanner'
import SvgWelcomeDay04Icon from '../../../common/components/icons/WelcomeDay04Icon'
import { useUser } from '../../../contexts/userContext'
import { useCallback } from 'react'

type GenericDashboardBannerProps = {
  onClose: (messageId: string) => void
}

export const GenericDashboardBanner: React.FC<GenericDashboardBannerProps> = ({ onClose }) => {
  const { user } = useUser()
  const { t } = useTranslation()

  let genericeBannerData: WelcomeBlockData | undefined = undefined

  const handleCloseInternal = useCallback(() => {
    if (genericeBannerData) {
      onClose(genericeBannerData.id)
    }
  }, [onClose, genericeBannerData])

  if (user?.dashboardMessage) {
    genericeBannerData = {
      id: user.dashboardMessage.id + '',
      title: user.dashboardMessage.title,
      body: user.dashboardMessage.message,
      primaryButton: {
        label: t(user.dashboardMessage.buttonLabel, 'Read more'),
        type: 'link-external',
        link: user.dashboardMessage.buttonLink,
        icon: 'help',
      },
    }
    return (
      <WelcomeBanner
        injectRawBody
        onClose={handleCloseInternal}
        icon={<SvgWelcomeDay04Icon />}
        data={genericeBannerData}
      />
    )
  } else {
    return null
  }
}
