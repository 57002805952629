import * as React from 'react'
import { SVGProps } from 'react'
const SvgTextFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.963 8.438a.76.76 0 0 0 0 1.52h12.165a.76.76 0 1 0 0-1.52H5.963ZM5.203 13.76c0-.42.34-.76.76-.76h12.165a.76.76 0 1 1 0 1.52H5.963a.76.76 0 0 1-.76-.76ZM5.963 17.562a.76.76 0 1 0 0 1.52h6.083a.76.76 0 0 0 0-1.52H5.963Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.721 1.483a2.28 2.28 0 0 0-1.593-.648H2.876a2.28 2.28 0 0 0-2.28 2.28v19.77a2.281 2.281 0 0 0 2.28 2.28h18.248a2.281 2.281 0 0 0 2.28-2.28V6.037a2.28 2.28 0 0 0-.688-1.633L19.72 1.483Zm-1.592.873a.76.76 0 0 1 .53.215l2.995 2.923a.76.76 0 0 1 .23.545v16.845a.76.76 0 0 1-.76.76H2.876a.76.76 0 0 1-.76-.76V3.116a.76.76 0 0 1 .76-.76H18.13Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgTextFileIcon
