import * as React from 'react'
import { SVGProps } from 'react'
const SvgPinPlain = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.08 9.64a5.948 5.948 0 0 0-8.16 0 5.44 5.44 0 0 0 0 7.92c.48.48 1.08.36 1.44.24.36-.12.72-.36.84-.84.12-.24.24-.48.48-.72.72-.72 1.92-.72 2.64 0 .24.24.36.48.48.72.12.36.48.72.84.84.48.24.96.12 1.44-.24a5.44 5.44 0 0 0 0-7.92Zm-6.84 1.44a4.067 4.067 0 0 1 5.52 0c1.2 1.2 1.44 3.12.48 4.56-.12-.24-.36-.48-.6-.72-1.44-1.44-3.84-1.44-5.28 0-.24.24-.48.48-.6.72-.96-1.44-.84-3.36.48-4.56Z'
      fill={props.color ?? '#CC3E76'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.48 21.76 16 29.8l8.52-8.04C26.8 19.6 28 16.72 28 13.72c0-3.12-1.2-6-3.6-8.16-4.68-4.44-12.24-4.44-16.92 0C5.2 7.72 4 10.6 4 13.6c0 3.12 1.2 6 3.48 8.16Zm8.52 5.4-7.08-6.72c-1.92-1.92-3-4.32-3-6.84 0-2.52 1.08-4.92 2.88-6.72 3.96-3.72 10.44-3.72 14.4.12 1.8 1.8 2.88 4.2 2.88 6.72 0 2.52-1.08 4.8-2.88 6.6L16 27.16Z'
      fill={props.color ?? '#CC3E76'}
    />
  </svg>
)
export default SvgPinPlain
