import { useCallback, useState } from 'react'
import { Button } from '../../common/components/button/Button'
import { InputField } from '../../common/components/input/InputField'
import { DEFAULT_THEME_SETTINGS, useTheme } from '../../contexts/ThemeContext'
import { getRandomHex } from '../../util/colors'
import styles from './Debug.module.css'
import { useUser } from '../../contexts/userContext'

export const DebugTheme: React.FC = () => {
  const { user } = useUser()
  const { colorPrimary, colorSecondary, updateThemeSettings } = useTheme()
  const [customUrl, setCustomUrl] = useState<string>('')

  const handleCustomUrlInputChange = useCallback((e: React.SyntheticEvent<HTMLInputElement>) => {
    setCustomUrl(e.currentTarget.value)
  }, [])

  const handleCustomUrlSubmit = useCallback(() => {
    updateThemeSettings({ logoUrl: customUrl })
    setCustomUrl('')
  }, [updateThemeSettings, customUrl])

  return (
    <div className={styles.themeDebug}>
      <div>
        <h5>Theme primary color</h5>
        <div className={styles.colorPickingContainer}>
          <div>
            <input
              type='color'
              id='customColorPrimary'
              name='customColorPrimary'
              value={colorPrimary}
              onChange={(e: any) => updateThemeSettings({ colorPrimary: e.target.value })}
            />
            <label htmlFor='customColorPrimary' style={{ color: 'var(--primary-normal)' }}>
              pick
            </label>
          </div>
          <div className={styles.colorPreviewContainer}>
            <div style={{ backgroundColor: 'var(--primary-background)' }} />
            <div style={{ backgroundColor: 'var(--primary-light)' }} />
            <div style={{ backgroundColor: 'var(--primary-normal)' }} />
            <div style={{ backgroundColor: 'var(--primary-dark)' }} />
          </div>
        </div>

        <Button variant='tiny' onClick={() => updateThemeSettings({ colorPrimary: getRandomHex() })}>
          random color
        </Button>
        <Button
          variant='outline-tiny'
          onClick={() =>
            updateThemeSettings({
              colorPrimary: user?.theme?.themeMainColor || DEFAULT_THEME_SETTINGS.colorPrimary,
            })
          }
        >
          reset color
        </Button>
      </div>

      <div>
        <h5>Theme secondary color</h5>
        <div className={styles.colorPickingContainer}>
          <div>
            <input
              type='color'
              id='customColorSecondary'
              name='customColorSecondary'
              value={colorSecondary}
              onChange={(e: any) => updateThemeSettings({ colorSecondary: e.target.value })}
            />
            <label htmlFor='customColorSecondary' style={{ color: 'var(--secondary-normal)' }}>
              pick
            </label>
          </div>
          <div className={styles.colorPreviewContainer}>
            <div style={{ backgroundColor: 'var(--secondary-background)' }} />
            <div style={{ backgroundColor: 'var(--secondary-light)' }} />
            <div style={{ backgroundColor: 'var(--secondary-normal)' }} />
            <div style={{ backgroundColor: 'var(--secondary-dark)' }} />
          </div>
        </div>
        <Button secondary variant='tiny' onClick={() => updateThemeSettings({ colorSecondary: getRandomHex() })}>
          random color
        </Button>
        <Button
          variant='outline-tiny'
          secondary
          onClick={() =>
            updateThemeSettings({
              colorSecondary: user?.theme?.themeSecondaryColor || DEFAULT_THEME_SETTINGS.colorSecondary,
            })
          }
        >
          reset color
        </Button>
      </div>

      <div>
        <h5>Theme logo</h5>
        <InputField
          placeholder='custom logo url'
          value={customUrl}
          onChange={handleCustomUrlInputChange}
          button={{ onClick: handleCustomUrlSubmit }}
        />
        <Button onClick={() => updateThemeSettings({ logoUrl: 'https://picsum.photos/200/100' })} variant='tiny'>
          random image logo
        </Button>
        <Button onClick={() => updateThemeSettings({ logoUrl: 'https://placekitten.com/150/50' })} variant='tiny'>
          place kitten logo
        </Button>
        <Button
          variant='outline-tiny'
          label=''
          onClick={() => updateThemeSettings({ logoUrl: user?.theme?.themeLogoUrl || DEFAULT_THEME_SETTINGS.logoUrl })}
        >
          local seppo logo
        </Button>
      </div>

      <div>
        <h5>Theme favicon</h5>
        <Button onClick={() => updateThemeSettings({ faviconUrl: 'https://picsum.photos/20' })} variant='tiny'>
          random image favicon
        </Button>
        <Button
          label=''
          onClick={() => updateThemeSettings({ faviconUrl: DEFAULT_THEME_SETTINGS.faviconUrl })}
          variant='outline-tiny'
        >
          local seppo favicon
        </Button>
      </div>
    </div>
  )
}
