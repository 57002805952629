import { t } from 'i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import { MapType } from '../../../../../../api/gameTypes'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../../common/components/PopupMenu/PopupActionMenu'
import { Button } from '../../../../../../common/components/button/Button'
import { Tooltip } from '../../../../../../common/components/tooltip/Tooltip'
import { Task, TaskConnection } from '../../../../../../types/commonTypes'
import { isEmpty } from '../../../../../../util/functional'
import { MapInstanceID, useMapApi } from '../../../../../../util/map'
import { TabType } from '../../../../types'
import { PIN_WIDTH } from '../../../Marker/DraggableMarker'
import styles from './TaskHoverHandler.module.css'

type TaskHoverHandlerProps = {
  activeTab: TabType
  mapType: MapType
  isBranching?: boolean
  tasks: Task[]
  taskConnections: TaskConnection[]
  viewOnly: boolean
  onEditTask: (id: number) => void
  onCopyTask: (id: number) => void
  onDeleteTask: (id: number) => void
  onAddConnectionLine: (fromId: number, toId: number) => void
  onDeleteConnectionLine: (fromId: number, toId: number) => void
  rootTaskId?: number
  onSetStartingTask?: (id: number) => void
  onClearStartingTask?: () => void
  onSetGoalTaskStatus?: (id: number, isGoalTask: boolean) => void
}

export const TaskHoverHandler: React.FC<TaskHoverHandlerProps> = ({
  activeTab = TabType.BUILD,
  mapType,
  isBranching = false,
  tasks,
  taskConnections,
  viewOnly,
  onEditTask,
  onCopyTask,
  onDeleteTask,
  onAddConnectionLine,
  onDeleteConnectionLine,
  rootTaskId,
  onSetStartingTask,
  onClearStartingTask,
  onSetGoalTaskStatus,
}) => {
  const [activeHoverPinId, setActiveHoverPinId] = useState(0)
  const [activeHoverConnectionIds, setActiveHoverConnectionIds] = useState<number[]>([])
  const [hoverAreaStyle, setHoverAreaStyle] = useState<React.CSSProperties>({})
  const hoverMenuCloseTimeout = useRef(0)
  const [draggingConnectionFromId, setDraggingConnectionFromId] = useState(0)
  const { map } = useMapApi(MapInstanceID.EDITOR)

  const removeMenu = useCallback(() => {
    setActiveHoverPinId(0)
    setActiveHoverConnectionIds([])
  }, [])

  const activateMenu = useCallback(
    (taskIds: number[], x: number, y: number) => {
      window.clearTimeout(hoverMenuCloseTimeout.current)
      if (taskIds.length === 1) {
        if (activeHoverPinId !== taskIds[0]) {
          removeMenu()
        }
        setTimeout(() => {
          setActiveHoverConnectionIds([])
          setActiveHoverPinId(taskIds[0])
        }, 10)
      } else {
        if (activeHoverConnectionIds !== taskIds) {
          removeMenu()
        }
        setTimeout(() => {
          setActiveHoverPinId(0)
          setActiveHoverConnectionIds(taskIds)
        }, 10)
      }
      setHoverAreaStyle((prev) => {
        return {
          ...prev,
          left: x,
          top: y,
        }
      })
    },
    [activeHoverConnectionIds, activeHoverPinId, removeMenu],
  )

  useEffect(() => {
    if (activeHoverPinId && draggingConnectionFromId) {
      setTimeout(() => {
        document.getElementById('menu_button_hoverMenu')?.click()
      }, 10)
    }
  }, [activeHoverPinId, draggingConnectionFromId])

  useEffect(() => {
    const isTaskPin = (target: any) => {
      if (target?.className.includes) return target?.className.includes('taskPin_')
      return false
    }

    const isMenu = (target: any) => {
      if (target?.className.includes)
        return (
          target?.className.includes('PopupMenu') ||
          target?.className.includes('PopupActionMenu') ||
          target?.className.includes('toolTipArea')
        )
      return false
    }

    const getTaskId = (target: any) => {
      return parseInt(target?.className.split('taskPin_')[1])
    }

    const isConnectionLine = (target: any) => {
      if (target?.className.baseVal) return target?.className.baseVal.includes('connection_')
    }

    const getConnectionTaskIds = (target: any): number[] => {
      if (target?.className.baseVal)
        return target?.className.baseVal
          .split('_')
          .map((s: string) => parseInt(s))
          .slice(1)
      return []
    }

    function mouseEnter(evt: any) {
      if (isMenu(evt.target)) window.clearTimeout(hoverMenuCloseTimeout.current)
      if (isTaskPin(evt.target) && !evt.buttons) {
        const pinElement = evt.target as HTMLElement
        pinElement.style.cursor = 'move'
        const coords = pinElement.getBoundingClientRect()
        activateMenu([getTaskId(evt.target)], coords.left + PIN_WIDTH / 4, coords.top)
      }
      if (isConnectionLine(evt.target) && !evt.buttons && draggingConnectionFromId < 1) {
        activateMenu(getConnectionTaskIds(evt.target), evt.pageX - 20, evt.pageY - 5)
      }
    }

    function mouseDown(evt: any) {
      if (isTaskPin(evt.target)) {
        hoverMenuCloseTimeout.current = window.setTimeout(() => {
          if (mapType === MapType.LIVE) {
            const pinElement = evt.target as HTMLElement
            pinElement.style.cursor = ''
          }
          removeMenu()
        }, 50)
      }
    }

    function mouseLeave(evt: any) {
      if (isTaskPin(evt.target) || isMenu(evt.target) || isConnectionLine(evt.target)) {
        hoverMenuCloseTimeout.current = window.setTimeout(() => {
          removeMenu()
        }, 500)
      }
    }

    function liveLineEnter(evt: any) {
      const el = document.elementsFromPoint(evt.originalEvent.pageX, evt.originalEvent.pageY)[0]
      if (!isTaskPin(el) && !evt.originalEvent.buttons) {
        activateMenu(
          evt.features[0].properties.connection.split('_'),
          evt.originalEvent.pageX - 25,
          evt.originalEvent.pageY - 10,
        )
      }
    }

    function liveLineLeave(evt: any) {
      hoverMenuCloseTimeout.current = window.setTimeout(() => {
        removeMenu()
      }, 500)
    }

    document.addEventListener('mouseover', mouseEnter)
    document.addEventListener('mouseout', mouseLeave)
    document.addEventListener('mousedown', mouseDown)
    if (mapType === MapType.LIVE) {
      map.on('mouseover', 'line-layer', liveLineEnter)
      map.on('mouseout', 'line-layer', liveLineLeave)
    }

    return () => {
      document.removeEventListener('mouseover', mouseEnter)
      document.removeEventListener('mouseout', mouseLeave)
      document.removeEventListener('mousedown', mouseDown)
      if (mapType === MapType.LIVE) {
        map.off('mouseover', 'line-layer', liveLineEnter)
        map.off('mouseout', 'line-layer', liveLineLeave)
      }
    }
  }, [activateMenu, activeHoverPinId, draggingConnectionFromId, map, mapType, removeMenu])

  if (
    (activeHoverPinId < 1 && isEmpty(activeHoverConnectionIds) && draggingConnectionFromId < 1) ||
    (!isEmpty(activeHoverConnectionIds) && activeTab === TabType.PLAY)
  )
    return null

  const task = tasks.find((t) => t.id === activeHoverPinId)
  const tooltipContent = task?.name ?? t('game_editor.branching.connection_tooltip_label', 'Connection')
  const isGoalTask = task?.isGoalTask
  const possibleGoalTask =
    taskConnections.findIndex((tc) => tc.fromId === activeHoverPinId) < 0 && activeHoverPinId !== rootTaskId

  if (draggingConnectionFromId > 0) {
    return (
      <>
        <div className={styles.connectingInfo}>
          <p>{t('game_editor.branching.connecting_info', 'Select the task pin to connect to or cancel')}</p>
          <Button variant='outline-tiny' onClick={() => setDraggingConnectionFromId(0)}>
            {t('common.cancel', 'Cancel')}
          </Button>
        </div>
        {activeHoverPinId > 0 && (
          <Tooltip
            tooltipContent={tooltipContent}
            timeoutMs={10}
            tooltipClassName={activeHoverPinId > 0 && styles.pinTooltipOffset}
          >
            {(tooltipProps) => (
              <div className={styles.toolTipArea} style={hoverAreaStyle} {...tooltipProps}>
                <div className={styles.hoverMenuBase}>
                  <PopupActionMenu
                    id={'hoverMenu'}
                    position='below-down'
                    direction='right'
                    buttonClassName={styles.moreMenuButton}
                  >
                    <PopupActionMenuItem
                      icon='link'
                      text={t('game_editor.branching.end_new_connection', 'Connect to here')}
                      onClick={() => {
                        setDraggingConnectionFromId(0)
                        onAddConnectionLine(draggingConnectionFromId, activeHoverPinId)
                      }}
                    />
                  </PopupActionMenu>
                </div>
              </div>
            )}
          </Tooltip>
        )}
      </>
    )
  }

  return (
    <Tooltip
      tooltipContent={tooltipContent}
      timeoutMs={10}
      tooltipClassName={activeHoverPinId > 0 ? styles.pinTooltipOffset : styles.lineTooltipOffset}
    >
      {(tooltipProps) => (
        <div className={styles.toolTipArea} style={hoverAreaStyle} {...tooltipProps}>
          <div className={styles.hoverMenuBase}>
            <PopupActionMenu
              id={'hoverMenu'}
              position='below-down'
              direction='right'
              buttonClassName={styles.moreMenuButton}
            >
              {activeTab === TabType.BUILD && activeHoverPinId > 0 && (
                <PopupActionMenuItem
                  icon={viewOnly ? 'eye' : 'text'}
                  text={viewOnly ? t('common.view', 'View') : t('common.edit', 'Edit')}
                  onClick={() => {
                    setActiveHoverPinId(0)
                    onEditTask(activeHoverPinId)
                  }}
                />
              )}
              {!viewOnly && activeTab === TabType.BUILD && activeHoverPinId > 0 && (
                <>
                  {/* The normal BUILD tab menu items */}
                  <PopupActionMenuItem
                    icon='duplicate'
                    text={t('common.duplicate', 'Duplicate')}
                    onClick={() => {
                      setActiveHoverPinId(0)
                      onCopyTask(activeHoverPinId)
                    }}
                  />
                  <PopupActionMenuItem
                    icon='trash'
                    text={t('common.delete', 'Delete')}
                    onClick={() => {
                      setActiveHoverPinId(0)
                      onDeleteTask(activeHoverPinId)
                    }}
                  />
                  {isBranching && (
                    <>
                      {!rootTaskId ? (
                        <PopupActionMenuItem
                          icon='link'
                          text={t('game_editor.branching.set_as_starting_task', 'Set as starting task')}
                          onClick={() => {
                            setActiveHoverPinId(0)
                            if (onSetStartingTask) onSetStartingTask(activeHoverPinId)
                          }}
                        />
                      ) : (
                        <>
                          {isGoalTask ? (
                            <PopupActionMenuItem /* If goal task then can't connect, only clear that status  */
                              icon='close'
                              text={t('game_editor.branching.remove_goal_task', 'Clear goal task status')}
                              onClick={() => {
                                setActiveHoverPinId(0)
                                if (onSetGoalTaskStatus) onSetGoalTaskStatus(activeHoverPinId, false)
                              }}
                            />
                          ) : (
                            <>
                              <PopupActionMenuItem /* If not branch end then can connect */
                                icon='link'
                                text={t('game_editor.branching.start_new_connection', 'Start connecting')}
                                onClick={() => {
                                  setActiveHoverPinId(0)
                                  setDraggingConnectionFromId(activeHoverPinId)
                                }}
                              />
                              {possibleGoalTask && (
                                <PopupActionMenuItem /* Can be made goal task only if no starting connections from this */
                                  icon='star'
                                  text={t('game_editor.branching.set_goal_task', 'Set as goal task')}
                                  onClick={() => {
                                    setActiveHoverPinId(0)
                                    if (onSetGoalTaskStatus) onSetGoalTaskStatus(activeHoverPinId, true)
                                  }}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                      {rootTaskId === activeHoverPinId && (
                        <PopupActionMenuItem
                          icon='close'
                          text={t('game_editor.branching.clear_starting_task', 'Remove starting task status')}
                          onClick={() => {
                            setActiveHoverPinId(0)
                            if (onClearStartingTask) onClearStartingTask()
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {!viewOnly &&
                activeTab === TabType.BUILD &&
                !isEmpty(activeHoverConnectionIds) &&
                draggingConnectionFromId < 1 && (
                  <PopupActionMenuItem /* Menu for connection line on the  BUILD tab, not shown when creating connection  */
                    icon='trash'
                    text={t('common.delete', 'Delete')}
                    onClick={() => {
                      setActiveHoverConnectionIds([])
                      onDeleteConnectionLine(activeHoverConnectionIds[0], activeHoverConnectionIds[1])
                    }}
                  />
                )}
              {activeTab === TabType.PLAY && (
                <PopupActionMenuItem
                  icon='text'
                  text={t('game_editor.sidebar.evaluate_answer_panel.evaluate_button', 'Evaluate')}
                  onClick={() => {
                    setActiveHoverPinId(0)
                    document.getElementById(`task_card_${activeHoverPinId}`)?.click()
                  }}
                />
              )}
            </PopupActionMenu>
          </div>
        </div>
      )}
    </Tooltip>
  )
}
