import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from 'use-onclickoutside'
import { MessageSubtypeEnum } from '../../../../api/messagingTypes'
import { useChat } from '../../../../contexts/ChatContextProvider'
import { useGame } from '../../../../contexts/GameContext'
import { NOTIFICATION_DISMISS_BUTTON_ID_PREFIX, useNotification } from '../../../../contexts/NotificationContext'
import { useUser } from '../../../../contexts/userContext'
import { CHAT_BUTTON_ID } from '../../constants'
import styles from './Chat.module.css'
import { DirectChatInboxes } from './components/DirectChat/DirectChatInboxes'
import { GeneralChat } from './components/GeneralChat/GeneralChat'
import { ChatTypeEnum, Header } from './components/Header/Header'
import { useConfirmation } from '../../../../contexts/ConfirmationContext'
import { getAudioChatMessage } from './helpers'

const handleClick = (evt: any) => {
  if (evt.target.className === 'audio-reload-button') {
    const audioElement = evt.target.previousSibling
    if (audioElement.nodeName === 'AUDIO') {
      audioElement.pause()
      audioElement.load()
      audioElement.play()
    }
  }
}

const DO_NOT_CLOSE_ID = 'confirmation_dialog_button'

export const Chat: React.FC = () => {
  const { t } = useTranslation()
  const [chatType, setChatType] = useState<ChatTypeEnum>(ChatTypeEnum.GENERAL)
  const { allMessages, people, isEdit } = useGame()
  const { sendMessage, toggleChat, deleteMessage } = useChat()
  const [message, setMessage] = useState<string>()
  const { user } = useUser()
  const chatRef = useRef<HTMLDivElement>(null)
  const { requestConfirmation } = useConfirmation()
  const { notifyError } = useNotification()

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  useOnClickOutside(chatRef, (e: any) => {
    const targetId = (e.target as any)?.getAttribute?.('id')
    if (
      targetId?.startsWith(NOTIFICATION_DISMISS_BUTTON_ID_PREFIX) ||
      targetId?.startsWith(DO_NOT_CLOSE_ID) ||
      targetId === CHAT_BUTTON_ID
    ) {
      return
    }
    toggleChat()
  })

  const toggleChatType = (id: ChatTypeEnum) => {
    setChatType(id)
  }

  const handleSubmitMessage = (messageSubtype: MessageSubtypeEnum, receiverId?: number) => {
    if (message != null && message.trim().length > 0) {
      if (messageSubtype === MessageSubtypeEnum.PRIVATE_MESSAGE && receiverId != null) {
        sendMessage(message.trim(), receiverId + '')
      }
      if (messageSubtype === MessageSubtypeEnum.SHOUTBOX_MESSAGE) {
        sendMessage(message.trim())
      }
    }
    setMessage(undefined)
  }

  const handleSubmitAudio = (messageSubtype: MessageSubtypeEnum, receiverId?: number, audioUrl?: string) => {
    if (audioUrl != null && audioUrl.trim().length > 0) {
      const message = getAudioChatMessage(audioUrl)
      if (messageSubtype === MessageSubtypeEnum.PRIVATE_MESSAGE && receiverId != null) {
        sendMessage(message, receiverId + '')
      }
      if (messageSubtype === MessageSubtypeEnum.SHOUTBOX_MESSAGE) {
        sendMessage(message)
      }
    }
    setMessage(undefined)
  }

  const getGeneralMessages = useMemo(
    () => allMessages.filter(({ isGeneralMessage }) => isGeneralMessage),
    [allMessages],
  )

  const getDirectMessages = useMemo(
    () => allMessages.filter(({ isGeneralMessage }) => !isGeneralMessage),
    [allMessages],
  )

  if (user == null) {
    return null
  }

  const handleChangeMessage = (message: string) => {
    setMessage(message)
  }

  const handleDeleteMessage = async (messageId: number) => {
    const message = allMessages.find((m) => m.id === messageId)
    const messageText =
      (message?.text ?? '').indexOf('<audio') > -1
        ? t('game_editor.chat.audio_message', 'Audio message')
        : message?.text
    requestConfirmation({
      title: t('game_editor.chat.delete_message_confirmation.title', 'Confirm action'),
      text: t(
        'game_editor.chat.delete_message_confirmation.content',
        'Are you sure you want to delete message "%{text}" ?',
        { text: messageText },
      ),
    }).then((confirm) => {
      if (confirm) {
        const response = deleteMessage(messageId)
        if (!response) {
          notifyError({
            title: t('game_editor.chat.delete_message_failed.title', 'Delete failed'),
            content: t(
              'game_editor.chat.delete_message_failed.content',
              'Something went wrong, failed to delete the message.',
            ),
          })
        }
      }
    })
  }

  if (!isEdit) {
    return (
      <div ref={chatRef} className={classNames(styles.chatContainer, styles.noGameContainer)}>
        {t('game_editor.chat.create_game_first', 'To use chat, make sure the game is set up in the settings')}
      </div>
    )
  }

  return (
    <div ref={chatRef} className={styles.chatContainer}>
      <Header onSelectChatType={toggleChatType} checkedChatTypeId={chatType} />
      {chatType === ChatTypeEnum.GENERAL && (
        <GeneralChat
          onSubmit={handleSubmitMessage}
          onSubmitAudio={handleSubmitAudio}
          onChangeMessage={handleChangeMessage}
          messageText={message}
          messages={getGeneralMessages}
          onDeleteMessage={handleDeleteMessage}
        />
      )}
      {chatType === ChatTypeEnum.DIRECT && (
        <DirectChatInboxes
          onSubmit={handleSubmitMessage}
          onSubmitAudio={handleSubmitAudio}
          onChangeMessage={handleChangeMessage}
          people={people.players}
          messageText={message}
          messages={getDirectMessages}
          onDeleteMessage={handleDeleteMessage}
        />
      )}
    </div>
  )
}
