import * as React from 'react'
import { SVGProps } from 'react'
const SvgCircleCheckmarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M3.999 15.999C3.999 9.37 9.372 3.997 16 3.997c6.628 0 12.001 5.374 12.001 12.002C28.001 22.627 22.628 28 16 28 9.372 28 3.999 22.627 3.999 15.999zM16 1.997C8.268 1.997 1.999 8.267 1.999 16 1.999 23.73 8.268 30 16 30c7.732 0 14.001-6.269 14.001-14.001 0-7.733-6.269-14.002-14.001-14.002zm7.719 8.646a1 1 0 1 0-1.57-1.24L13.087 20.87l-.012.015a.213.213 0 0 1-.346-.01l-.013-.02-2.832-4.018A1 1 0 1 0 8.25 17.99L11.074 22a2.213 2.213 0 0 0 3.061.596l.005-.003a2.24 2.24 0 0 0 .52-.488z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
)
export default SvgCircleCheckmarkIcon
