import * as React from 'react'
import { SVGProps } from 'react'
const SvgTaskCreativeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17 7.874a4 4 0 1 0-2 0V15H7.874a4 4 0 1 0 0 2H15v7.126a4.001 4.001 0 1 0 2 0V17h7.126a4.001 4.001 0 1 0 0-2H17V7.874ZM14 4a2 2 0 1 1 3.999-.001A2 2 0 0 1 14 4Zm2 22a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 26ZM2 16a2 2 0 1 1 3.999-.001A2 2 0 0 1 2 16Zm26 2a2 2 0 1 1-.001-3.999A2 2 0 0 1 28 18Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.961 9.547a4 4 0 1 0-7.446-2.032 4 4 0 0 0 6.032 3.446l2.746 2.746a1 1 0 0 0 1.414-1.414l-2.746-2.746ZM7.515 5.515a2 2 0 1 0 1.401 3.427l.013-.013.013-.013a2 2 0 0 0-1.427-3.401ZM20.485 24.485c0-.742.202-1.436.554-2.032l-2.746-2.746a1 1 0 0 1 1.414-1.414l2.746 2.746a4 4 0 1 1 2.032 7.446 4 4 0 0 1-4-4Zm2 0c0-.546.219-1.04.573-1.401l.013-.013.013-.013a2 2 0 1 1-.599 1.427ZM22.453 10.961a4 4 0 1 0 2.032-7.446 4 4 0 0 0-3.446 6.032l-2.746 2.746a1 1 0 0 0 1.414 1.414l2.746-2.746Zm2.032-5.446a2 2 0 1 0 0 3.999 2 2 0 0 0 0-4ZM7.515 20.485c.742 0 1.436.202 2.032.554l2.746-2.746a1 1 0 0 1 1.414 1.414l-2.746 2.746a4 4 0 1 1-7.446 2.032 4 4 0 0 1 4-4Zm-2 4a2 2 0 0 1 3.401-1.427l.013.013.013.013a2 2 0 1 1-3.427 1.401Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgTaskCreativeIcon
