import classNames from 'classnames'
import { addHotSpot, getConfig, mouseEventToCoords, removeHotSpot } from 'react-pannellum'
import { DOOR_ANCHOR_Y_FIX, DOOR_HEIGHT, DOOR_WIDTH } from './DraggableDoor'
import styles from './DraggableDoor.module.css'
import { DoorIconRaw, getDoorIdClass } from './DoorHelper'
import { Door, GameBoard } from '../../types'

const getInnerIcon = (doorId: number) => {
  const innerElement = document.createElement('span')
  innerElement.innerHTML = DoorIconRaw
  innerElement.className = classNames(styles.icon, getDoorIdClass(doorId))
  innerElement.style.width = `${DOOR_WIDTH}px`
  innerElement.style.height = `${DOOR_HEIGHT}px`
  innerElement.style.pointerEvents = 'none'
  return innerElement
}

const getIcon = (doorId: number) => {
  const iconElement = document.createElement('div')
  iconElement.id = 'door360-' + doorId
  iconElement.className = classNames(styles.icon, getDoorIdClass(doorId))
  iconElement.style.width = `${DOOR_WIDTH}px`
  iconElement.style.height = `${DOOR_HEIGHT}px`
  iconElement.style.position = 'absolute'
  iconElement.style.top = `-${DOOR_HEIGHT - DOOR_ANCHOR_Y_FIX}px`
  iconElement.style.left = `-${DOOR_WIDTH / 2}px`
  iconElement.appendChild(getInnerIcon(doorId))
  return iconElement
}

export const refreshDraggableDoorsToMap = (
  board: GameBoard,
  doors: Door[],
  onUpdateExplorationDoor: (board: GameBoard | undefined, door: Door) => Promise<boolean>,
) => {
  getConfig().hotSpots.forEach((spot: any) => {
    setTimeout(() => {
      if (spot.id?.includes('door')) removeHotSpot(spot.id, 'seppoScene')
    }, 100)
  })
  //Remove possibly existing movement icon and create new
  //The normal dragging inside pannellum did not work on Safari / Firefox, thus workaround with generic mouse-events and 'manual' moveventIcon
  const movementIcon = getIcon(0)
  movementIcon.style.top = '-200px'
  movementIcon.style.left = '-200px'
  setTimeout(() => {
    document.getElementById('door360-0')?.remove()
    document.getElementById('root')?.append(movementIcon)
  }, 50)

  doors.forEach((aDoor) => {
    const markerId = 'door_wrapper_' + aDoor.id + '_' + Math.random() * 10000
    const marker = {
      pitch: aDoor.latitude,
      yaw: aDoor.longitude,
      id: markerId,
      //Classes RFU to for example make a differencet between a door and a task pin
      cssClass: 'marker-360 is-door door_class_' + markerId,
    }
    addHotSpot(marker, 'seppoScene')
    const markerIcon = getIcon(aDoor.id)
    getConfig()
    setTimeout(() => {
      const markerElement = document.getElementsByClassName('door_class_' + markerId)[0]
      markerElement.append(markerIcon)
    }, 20)

    //Drag and drop datatransfer not working properly so tranferring the data from DragStart to DragEnd via vars local to this marker
    let markerCenterXDiff = 0
    let markerCenterYDiff = 0

    function onDrag(evt: any) {
      movementIcon.style.top = evt.clientY - DOOR_HEIGHT - markerCenterYDiff + 'px'
      movementIcon.style.left = evt.clientX - DOOR_WIDTH / 2 - markerCenterXDiff + 'px'
      markerIcon.style.opacity = '0.01'
    }

    function onDragEnd(evt: any) {
      window.removeEventListener('mousemove', onDrag)
      window.removeEventListener('mouseup', onDragEnd)
      evt.preventDefault()
      // Make our own evt coordinates with correction to drag position relative to icon focus point
      const _evt = {
        clientX: evt.clientX - markerCenterXDiff,
        clientY: evt.clientY - markerCenterYDiff - 0.5 - DOOR_ANCHOR_Y_FIX,
      }
      const newPitchAndYaw = mouseEventToCoords(_evt)
      const _doors: Door[] = JSON.parse(JSON.stringify(doors))
      const movedDoor = _doors.find((d) => d.id === aDoor.id)
      if (movedDoor) {
        movedDoor.longitude = newPitchAndYaw[1]
        movedDoor.latitude = newPitchAndYaw[0]

        //Refresh directly locally for faster response
        refreshDraggableDoorsToMap(board, _doors, onUpdateExplorationDoor)
        onUpdateExplorationDoor(board, movedDoor)
      }
    }

    function onDragStart(evt: any) {
      window.addEventListener('mousemove', onDrag)
      window.addEventListener('mouseup', onDragEnd)
      const markerRect = evt.target.getBoundingClientRect()
      markerCenterXDiff = evt.clientX - (markerRect.left + markerRect.width / 2)
      markerCenterYDiff = evt.clientY - (markerRect.top + markerRect.height)
      movementIcon.replaceChildren(getInnerIcon(aDoor.id))
    }

    markerIcon.onmousedown = onDragStart
  })
}
