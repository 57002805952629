import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../common/components/Form/InputFormField/InputFormField'
import styles from './ShareGameModal.module.css'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../common/components/PopupMenu/PopupActionMenu'
import { ValidationErrors } from 'final-form'
import { LibraryGameDetails, TUser } from '../../../../types/commonTypes'
import { GameSettingsToggle } from '../GameSettings/components/GameSettingsToggle'

type LibraryShareSettingsAndMenuProps = {
  errors: ValidationErrors
  libraryGameDetails?: LibraryGameDetails
  user: TUser
  onDelete: () => void
}

export const LibraryShareSettingsAndMenu: React.FC<LibraryShareSettingsAndMenuProps> = ({
  errors,
  libraryGameDetails,
  user,
  onDelete,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={!!errors?.togglerError ? styles.togglerAreaHighlight : styles.togglerArea}>
        <div className={styles.publishTitle}>
          {t('game_editor.share_game.share_to_title', 'Publish in')}
          {libraryGameDetails && (
            <PopupActionMenu id={'share-more-menu'} position='inline-down' buttonClassName={styles.taskMoreMenuButton}>
              <PopupActionMenuItem
                icon={'trash'}
                text={t('game_editor.share_game.remove_from_library', 'Remove from library')}
                onClick={onDelete}
              />
            </PopupActionMenu>
          )}
        </div>
        {user.hasOrgLibrary && (
          <GameSettingsToggle
            name='shareToPrivate'
            toggleContainerClassName={styles.thinnerToggler}
            label={t('game_editor.share_game.share_to_private_label', 'Organization library')}
            description={t(
              'game_editor.share_game.share_to_private_disabled',
              'Game will not be added to organization library.',
            )}
            descriptionChecked={t(
              'game_editor.share_game.share_to_private_enabled',
              'Game will be added to organization library for duplication by others.',
            )}
          />
        )}
        {user.hasCommunity && (
          <GameSettingsToggle
            name='shareToPublic'
            toggleContainerClassName={styles.thinnerToggler}
            label={t('game_editor.share_game.share_to_public_label', 'Publish community')}
            description={t(
              'game_editor.share_game.share_to_public_disabled',
              "Game won't be shared publicly to our community.",
            )}
            descriptionChecked={t(
              'game_editor.share_game.share_to_public_enabled',
              'Game will be added to public community for duplication by all Seppo users.',
            )}
          />
        )}
      </div>
      {!!errors?.togglerError && (
        <InputFormField
          id='togglerError'
          name='togglerError'
          fieldContainerClassName={styles.hiddenContainer}
          className={styles.hiddenField}
          label={''}
        />
      )}
    </>
  )
}
