import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../../../common/components/Form/InputFormField/InputFormField'
import { Button } from '../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../common/components/button/CloseButton'
import { IconName, getIcon } from '../../../../../common/components/icons/utils'
import { Evaluator, FormErrorType, Instructor, RoleType, StudentInstructor } from '../../../../../types/commonTypes'
import { dateTimeToLocalDateString, safeIsNullOrEmpty } from '../../../../../util/string'
import { requiredValidation } from '../../../../../util/validate'
import styles from '../AddPerson.module.css'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../common/components/PopupMenu/PopupActionMenu'

export type InstructorForm = {
  userName: string
}

type instructorMenuProps = {
  label: string
  menuLabel: string
  icon: IconName
  instructors: Instructor[] | Evaluator[] | StudentInstructor[]
  placeholder: string
  onAdd: (userName: string) => Promise<boolean>
  onDelete: (name: string, displayName?: string) => void
  initialIsOpen: boolean
}

export const InstructorMenu: React.FC<instructorMenuProps> = ({
  label,
  menuLabel,
  icon,
  instructors,
  placeholder,
  onAdd,
  onDelete,
  initialIsOpen,
}) => {
  const { t } = useTranslation()
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(initialIsOpen)

  const handleClickAccordion = useCallback(() => setIsAccordionOpen((prevState) => !prevState), [])

  const [isAdding, setIsAdding] = useState<boolean>(false)
  const toggleIsAdding = () => setIsAdding((prev) => !prev)
  const useDisplayNameInDeletePrompt = instructors[0]?.role !== RoleType.Instructor

  const validate = (values: Partial<InstructorForm>): FormErrorType<InstructorForm> => {
    return {
      userName: requiredValidation(values.userName, t),
    }
  }

  const onSubmit = async (values: InstructorForm) => {
    const success = await onAdd(values.userName)
    if (success) {
      toggleIsAdding()
    }
  }

  return (
    <div>
      <div className={classNames(styles.instructorMenuHeader, isAccordionOpen && styles.instructorMenuHeader_open)}>
        <span className='detailBold' onClick={handleClickAccordion}>
          {menuLabel}
        </span>
        <button type='button' onClick={handleClickAccordion}>
          {getIcon('arrowDown')}
        </button>
      </div>
      {isAccordionOpen && (
        <div className={styles.listContainer}>
          {instructors?.length ? (
            <ul className={styles.instructorsList}>
              {instructors.map((instructor, index) => (
                <li key={`${instructor}${index}`} className={styles.listItem}>
                  <span className={styles.indexStyle}> {index + 1}</span>
                  <div className={styles.listContent}>
                    <span className={styles.listItemTitle}>
                      {!safeIsNullOrEmpty(instructor.playerCode) ? instructor.playerCode : instructor.name}
                    </span>
                    {!safeIsNullOrEmpty(instructor.validUntil) && (
                      <span className={styles.validText}>
                        {t('game_editor.add_people.instructor_menu_valid_until', {
                          defaultValue: 'valid until: %{valid_until}',
                          valid_until: dateTimeToLocalDateString(instructor.validUntil),
                        })}
                      </span>
                    )}
                  </div>
                  <PopupActionMenu id={`${instructor.id}_actionsMenu`} position='inline-down'>
                    <PopupActionMenuItem
                      icon='trash'
                      text={t('common.remove', 'Remove')}
                      onClick={() =>
                        onDelete(instructor.name, useDisplayNameInDeletePrompt ? instructor.playerCode : undefined)
                      }
                    />
                  </PopupActionMenu>
                </li>
              ))}
            </ul>
          ) : (
            <span className={styles.emptyList}>
              {t('game_editor.add_people.no_instructors_added', 'None added yet')}
            </span>
          )}
          {!isAdding ? (
            <div className={styles.addInstructorButton}>
              <Button variant='outline-normal' onClick={toggleIsAdding}>
                <span className='iconWrapperBig'>{getIcon(icon)}</span>
                {label}
              </Button>
            </div>
          ) : (
            <Form<InstructorForm> onSubmit={onSubmit} validate={validate}>
              {({ submitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className={styles.inputFieldContainer}>
                    <InputFormField
                      name='userName'
                      label={placeholder}
                      srOnlyLabel
                      placeholder={placeholder}
                      icon='multipleUsers'
                    />
                    <Button type='submit' disabled={submitting}>
                      {t('common.add', 'Add')}
                    </Button>
                    <CloseButton disabled={submitting} onClick={toggleIsAdding} style={{ fontSize: '1.5rem' }} />
                  </div>
                </form>
              )}
            </Form>
          )}
        </div>
      )}
    </div>
  )
}
