import * as React from 'react'
import { SVGProps } from 'react'
const SvgTaskMultipleChoiceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M21.814 4.58a1 1 0 1 0-1.628-1.163l-4.314 6.04-3.165-3.165a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.52-.126l5-7Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.498 0h17.006a7.52 7.52 0 0 1 7.497 7.497v.006A7.52 7.52 0 0 1 24.504 15H7.5A7.518 7.518 0 0 1 0 7.503v-.006A7.52 7.52 0 0 1 7.498 0ZM24.5 2H7.503A5.52 5.52 0 0 0 2 7.5 5.518 5.518 0 0 0 7.503 13H24.5A5.52 5.52 0 0 0 30 7.5 5.52 5.52 0 0 0 24.5 2ZM7.498 17h17.006a7.52 7.52 0 0 1 7.497 7.497v.006A7.52 7.52 0 0 1 24.504 32H7.5A7.518 7.518 0 0 1 0 24.503v-.006A7.52 7.52 0 0 1 7.498 17ZM24.5 19H7.503A5.52 5.52 0 0 0 2 24.5 5.518 5.518 0 0 0 7.503 30H24.5A5.52 5.52 0 0 0 30 24.5 5.52 5.52 0 0 0 24.5 19Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgTaskMultipleChoiceIcon
