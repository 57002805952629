import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconName, getIcon } from '../../common/components/icons/utils'
import { ThreeDots } from '../../common/components/loaders/ThreeDots/ThreeDots'
import { useFadeAnimation } from '../../hooks/FadeAnimation/useFadeAnimation'
import styles from './Notification.module.css'
import { ARABIC } from '../../common/constants'
import { NOTIFICATION_DISMISS_BUTTON_ID_PREFIX } from '../../contexts/NotificationContext'

export enum NotificationType {
  Success = 'Success',
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
  Loading = 'Loading',
  Position = 'Position',
  DoorsAdded = 'DoorsAdded',
}

type NotifyCustomButtonProps = {
  text: string
  onClick: () => void
  closeOnClick?: boolean
}

export type NotificationItemProps = {
  title: string | JSX.Element
  content: string | JSX.Element
  type: NotificationType
  id: string
  customButton?: NotifyCustomButtonProps
  onClose?: () => void
  closeOnRouteChange?: boolean
}

const getIconNameForType = (type: NotificationType): IconName => {
  switch (type) {
    case NotificationType.Success:
      return 'circleCheckmark'
    case NotificationType.Warning:
    case NotificationType.Error:
      return 'alert'
    case NotificationType.Position:
      return 'pinPlain'
    case NotificationType.DoorsAdded:
      return 'door'
    default:
      if (type !== NotificationType.Info) {
        console.warn(`Unknown notification type to icon mapping. Type is: ${type}. Returning icon for type info.`)
      }
      return 'info'
  }
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  title,
  content,
  type,
  customButton,
  onClose,
  id,
}) => {
  const [isArabic, setIsArabic] = useState(false)
  const { t, i18n } = useTranslation()
  const { fadeTransition, closeWithDelay } = useFadeAnimation()
  useEffect(() => {
    if (i18n.language === ARABIC) {
      setIsArabic(true)
    } else {
      setIsArabic(false)
    }
  }, [i18n.language])

  const handleCustomButtonClick = useCallback(() => {
    customButton?.onClick()
    if (customButton?.closeOnClick && onClose != null) {
      closeWithDelay(350, onClose)
    }
  }, [closeWithDelay, customButton, onClose])

  const handleClickCloseWithDelay = () => {
    if (onClose != null) {
      closeWithDelay(350, onClose)
    }
  }

  return (
    <div className={classNames(styles.notificationItem, fadeTransition, styles.slideIn)} dir={isArabic ? 'RTL' : 'LTR'}>
      <div className={classNames(styles.iconAndTextContainer, isArabic && styles.iconAndTextContainerMirroring)}>
        <div className={styles.iconContainer}>
          {type === NotificationType.Loading ? (
            <ThreeDots remSize={0.625} />
          ) : (
            <span className={classNames(styles.notificationItemIcon, styles[`notificationItemIcon_${type}`])}>
              {getIcon(getIconNameForType(type))}
            </span>
          )}
        </div>
        <div className={styles.textContainer}>
          <div>{title}</div>
          <div>{content}</div>
        </div>
      </div>
      {customButton != null && (
        <div className={classNames(styles.actionsContainer, isArabic && styles.actionsContainerMirroring)}>
          <button className={styles.actionButton} onClick={handleCustomButtonClick}>
            {customButton.text}
          </button>
        </div>
      )}
      {onClose != null && (
        <div className={classNames(styles.actionsContainer, isArabic && styles.actionsContainerMirroring)}>
          <button
            className={styles.actionButton}
            onClick={handleClickCloseWithDelay}
            id={`${NOTIFICATION_DISMISS_BUTTON_ID_PREFIX}_${id}`}
          >
            {t('notification_toast.dismiss_button_text', 'Dismiss')}
          </button>
        </div>
      )}
    </div>
  )
}
