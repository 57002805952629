import * as React from 'react'
import { SVGProps } from 'react'
const SvgProfileCircledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 25 24' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M22.934 12c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.7-10.5 10.5-10.5 10.5 4.701 10.5 10.5zm1.5 0c0 6.627-5.373 12-12 12-6.628 0-12-5.373-12-12s5.372-12 12-12c6.627 0 12 5.373 12 12zM12.4 3.811a3.595 3.595 0 0 1 3.61 3.61c0 1.939-1.604 3.543-3.61 3.543-1.94 0-3.543-1.604-3.543-3.543 0-2.005 1.604-3.61 3.543-3.61zm-2.74 8.757h5.481c2.14 0 3.81 1.538 4.078 3.61-1.337 2.407-3.944 3.945-6.82 3.945-2.807 0-5.414-1.538-6.819-3.945a4.113 4.113 0 0 1 4.078-3.61z'
      fill={props.color || 'currentColor'}
      fillRule='evenodd'
    />
  </svg>
)
export default SvgProfileCircledIcon
