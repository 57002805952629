import classNames from 'classnames'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { TextAreaFormField } from '../../../../../common/components/Form/TextAreaFormField/TextAreaFormField'
import { Button } from '../../../../../common/components/button/Button'
import { getIcon } from '../../../../../common/components/icons/utils'
import { useUser } from '../../../../../contexts/userContext'
import { AllowedOption, FormErrorType, Game } from '../../../../../types/commonTypes'
import { hasPermission } from '../../../../../util/permissions'
import { lowerThanOrEqualToValidation, requiredValidation } from '../../../../../util/validate'
import styles from '../AddPerson.module.css'
import { StartingTaskSelection } from './StartingTaskSelection'
import { BackButton } from '../../../../../common/components/button/BackButton'
import { ShareButtons } from './ShareButtons'

type PlayersMenuProps = {
  game: Game
  playersCount: number
  maxPlayers?: number | null
  playerLicenseCount?: number
  usedPlayerLicenseCount?: number
  isPlayerAccountModalOpen: boolean
  onAddPlayerAccount: () => void
  onAddManually: () => void
  onAddByEmail: (values: PlayerEmailForm) => Promise<boolean>
}

export type PlayerEmailForm = {
  emails: string
  showStartingTaskSelection: boolean
  startingTask?: number
}

const re =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const isDev = process.env.REACT_APP_ENV === 'development'

export const PlayersMenu: React.FC<PlayersMenuProps> = ({
  game,
  playersCount,
  maxPlayers,
  playerLicenseCount,
  usedPlayerLicenseCount = 0,
  isPlayerAccountModalOpen,
  onAddPlayerAccount,
  onAddManually,
  onAddByEmail,
}) => {
  const { user } = useUser()
  const [isInputOpen, setIsInputOpen] = useState<boolean>(false)
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const handleInputField = () => {
    setIsInputOpen((prev) => !prev)
  }

  const handleOptionsField = () => {
    setIsOptionsOpen((prev) => !prev)
  }

  const handleSendEmail = async (values: PlayerEmailForm) => {
    const result = await onAddByEmail(values)
    if (result) {
      handleInputField()
    }
  }

  const validate = (values: Partial<PlayerEmailForm>): FormErrorType<PlayerEmailForm> => {
    const addedEmails = values.emails?.split(',')
    const validationText = t('game_editor.add_people.player_limit_reached', 'Player limit reached')
    return {
      emails:
        (maxPlayers != null
          ? lowerThanOrEqualToValidation(addedEmails?.length, maxPlayers - playersCount, t, validationText)
          : requiredValidation(values.emails, t)) ??
        (addedEmails?.some((email) => !re.test(email.trim()))
          ? t('validation_errors.invalid_email', 'Invalid email')
          : undefined),
    }
  }

  return (
    <div>
      <div className={styles.buttonHeader}>
        <span>{t('game_editor.add_people.add_players', 'Add players')}</span>
        {playerLicenseCount != null && (
          <span className={styles.licenceCount}>
            {t('game_editor.add_people.licence_count', {
              defaultValue: '%{licence_count} licences available',
              licence_count: playerLicenseCount - usedPlayerLicenseCount,
            })}
          </span>
        )}
        {maxPlayers && <span className={styles.maxTeams}>{`${playersCount}/${maxPlayers}`}</span>}
      </div>
      {!isInputOpen && !isOptionsOpen && !isPlayerAccountModalOpen && (
        <div className={styles.buttonArea}>
          <div className={styles.firstButtons}>
            {hasPermission(user, AllowedOption.ADD_PLAYERS_BY_EMAIL) && (
              <Button onClick={handleInputField} className={styles.addPlayerButton}>
                {t('game_editor.add_people.email', 'E-mail')}
              </Button>
            )}
            {user?.hasPlayerAccounts && (
              <Button onClick={onAddPlayerAccount} className={styles.addPlayerButton}>
                {t('game_editor.add_people.player_account_button', 'Player Account')}
              </Button>
            )}
          </div>
          <div className={styles.secondaryButtons}>
            <Button variant='outline-normal' onClick={onAddManually} className={styles.addPlayerButton}>
              {t('game_editor.add_people.manual', 'Add manually')}
            </Button>
            <Button variant='outline-normal' onClick={handleOptionsField} className={styles.addPlayerButton}>
              {t('game_editor.add_people.more_options', 'More options')}
            </Button>
          </div>
          {isDev && (
            <div className={classNames('hide-outscoped-feature', styles.secondaryButtons)}>
              <button>
                <span className='iconWrapperBig'>{getIcon('msTeams')}</span>
                {t('game_editor.add_people.ms_teams', 'MS Teams')}
              </button>
              <button>
                <span className='iconWrapperBig'>{getIcon('googleClassroom')}</span>
                {t('game_editor.add_people.classrooms', 'Classrooms')}
              </button>
            </div>
          )}
        </div>
      )}
      {isInputOpen && (
        <>
          <BackButton onClick={handleInputField} />
          <Form<PlayerEmailForm> onSubmit={handleSendEmail} validate={validate}>
            {({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} className={styles.playerEmailForm}>
                {maxPlayers && playersCount >= maxPlayers && (
                  <span className={styles.maxPlayerValidationText}>
                    {t('game_editor.add_people.max_teams_validation', 'Maximum player limit reached')}
                  </span>
                )}
                <div className={styles.inputFieldContainer}>
                  <TextAreaFormField
                    name='emails'
                    label={t('game_editor.add_people.email', 'E-mail')}
                    placeholder={t('game_editor.add_people.player_email_input_placeholder', 'Type e-mail here')}
                    srOnlyLabel
                    rows={2}
                    fieldContainerClassName={styles.emailInput}
                    errorClassName={styles.emailInputError}
                  />
                  <Button type='submit' disabled={submitting} className={styles.addPlayerByEmailButton}>
                    {t('game_editor.add_people.add', 'Add')}
                  </Button>
                </div>
                <span className='tiny grey-900'>
                  {t('game_editor.add_people.player_email_input_instructions', 'Separate addresses with a comma')}
                </span>
                {game.advancedSettings.orderingEnabled && <StartingTaskSelection tasks={game.tasks} values={values} />}
              </form>
            )}
          </Form>
        </>
      )}
      {isOptionsOpen && <ShareButtons game={game} handleOptionsField={handleOptionsField} />}
    </div>
  )
}
