import React from 'react'
import { CloseButton } from '../../../common/components/button/CloseButton'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll'
import { useOnEscPress } from '../../../hooks/useOnEscPress'
import { useTrapFocus } from '../../../hooks/useTrapFocus'
import { TGameCard, Try } from '../../../types/commonTypes'
import styles from './SponsoredDownloadModal.module.css'
import { Modal } from '../../../common/components/Modal/Modal'
import { getIcon } from '../../../common/components/icons/utils'
import { useTranslation } from 'react-i18next'
import { DownloadGameForm, SponsoredGamesDownloadForm } from './SponsoredGameDownloadForm'
import { GetSponsoredActivation } from '../../../api/gameTypes'

export type SponsoredDownloadModalProps = {
  game: TGameCard
  onClose: () => void
  activateGame: (formProps: DownloadGameForm, game: TGameCard) => Promise<Try<GetSponsoredActivation> | null>
  sponsorCode?: string
  isInstructorView: boolean
}

export const SponsoredDownloadModal: React.FC<SponsoredDownloadModalProps> = ({
  game,
  onClose,
  activateGame,
  sponsorCode,
  isInstructorView = false,
}) => {
  const { t } = useTranslation()
  const modalRef = useTrapFocus<HTMLDivElement>()
  useOnEscPress(onClose)
  useDisableBodyScroll()

  const { closeWithDelay } = useFadeAnimation()

  const onCloseInternal = () => {
    closeWithDelay(350, onClose)
  }

  return (
    <Modal>
      <div className={styles.modalContainerSticky}>
        <div ref={modalRef} className={styles.modalContainer}>
          <div className={styles.modalTop}>
            <div className={styles.modalTopIcon}>{getIcon('sponsored')}</div>
            <div className={styles.modalTopText}>
              {t('sponsored_games.download_modal.download_header', 'Download sponsored game')}
            </div>

            <CloseButton className={styles.closeButton} autoFocus onClick={onCloseInternal} tabIndex={0} />
          </div>
          <div className={styles.modalContent}>
            <h3>"{game.gameName}"</h3>
            <div className={styles.modalDescriptionBox}>
              <div className={styles.modalDescriptionBoxBroughtBy}>
                {t('sponsored_games.download_modal.brought_by', 'Game is brought to you by')} {game.gameOwners[0]}
              </div>
              <br />"{game.description}"
            </div>
            <SponsoredGamesDownloadForm
              onClose={onClose}
              activateGame={activateGame}
              game={game}
              sponsorCode={sponsorCode}
              isInstructorView={isInstructorView}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
