import * as React from 'react'
import { SVGProps } from 'react'
const SvgLevel5Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='level_5_icon_svg__a' gradientUnits='userSpaceOnUse' x1={16} x2={16} y1={1} y2={31}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm-.3 17.192a9.055 9.055 0 0 1-2.32-.304c-.758-.213-1.409-.512-1.953-.896l1.024-1.984c.427.31.917.555 1.472.736a5.576 5.576 0 0 0 1.712.272c.65 0 1.163-.128 1.536-.384s.56-.613.56-1.072c0-.288-.075-.544-.224-.768s-.416-.395-.8-.512c-.373-.117-.901-.176-1.584-.176h-2.864L12.84 6.8h6.832v2.08h-4.665l-.19 2.128h.92c1.108 0 2 .155 2.671.464.683.299 1.179.71 1.488 1.232s.464 1.115.464 1.776-.165 1.275-.496 1.84c-.33.555-.843 1.008-1.536 1.36-.683.341-1.557.512-2.624.512zm-11.363.958-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='currentColor'
      />
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm-.3 17.192a9.055 9.055 0 0 1-2.32-.304c-.758-.213-1.409-.512-1.953-.896l1.024-1.984c.427.31.917.555 1.472.736a5.576 5.576 0 0 0 1.712.272c.65 0 1.163-.128 1.536-.384s.56-.613.56-1.072c0-.288-.075-.544-.224-.768s-.416-.395-.8-.512c-.373-.117-.901-.176-1.584-.176h-2.864L12.84 6.8h6.832v2.08h-4.665l-.19 2.128h.92c1.108 0 2 .155 2.671.464.683.299 1.179.71 1.488 1.232s.464 1.115.464 1.776-.165 1.275-.496 1.84c-.33.555-.843 1.008-1.536 1.36-.683.341-1.557.512-2.624.512zm-11.363.958-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='url(#level_5_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgLevel5Icon
