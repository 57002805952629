import classNames from 'classnames'
import { PropsWithChildren, useState } from 'react'
import { getIcon } from '../../common/components/icons/utils'
import styles from './Debug.module.css'

type DebugSectionProps = {
  title: string
  initialExpanded?: boolean
}

export const DebugSection: React.FC<PropsWithChildren<DebugSectionProps>> = ({ title, initialExpanded, children }) => {
  const [expanded, setExpanded] = useState<boolean>(initialExpanded ?? false)
  const toggleExpanded = () => setExpanded((prev) => !prev)

  return (
    <div className={styles.debugSection}>
      <div className={styles.debugSectionHeader}>
        <span onClick={toggleExpanded}>{title}</span>
        <button className={classNames(expanded && styles.iconExpanded)} type='button' onClick={toggleExpanded}>
          {getIcon('arrowDown')}
        </button>
      </div>
      <div className={styles.debugSectionContent}>{expanded && children}</div>
    </div>
  )
}
