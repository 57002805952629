import * as React from 'react'
import { SVGProps } from 'react'
const SvgAlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M14.038 3.044a4 4 0 0 1 5.382 1.411l.004.007 11.293 18.853.011.018a3.999 3.999 0 0 1-3.42 6H4.692a4 4 0 0 1-3.42-6l.01-.018L12.577 4.462l1.144.685-1.14-.692a4 4 0 0 1 1.458-1.41zm.824 2.792L3.577 24.674a1.334 1.334 0 0 0 1.138 1.993h22.57a1.334 1.334 0 0 0 1.138-1.992L17.14 5.838l-.002-.002a1.333 1.333 0 0 0-2.276 0zM16 10.666c.736 0 1.333.598 1.333 1.334v5.333a1.333 1.333 0 1 1-2.666 0V12c0-.736.597-1.333 1.333-1.333zm0 10.668A1.333 1.333 0 0 0 16 24h.013a1.333 1.333 0 0 0 0-2.666z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
)
export default SvgAlertIcon
