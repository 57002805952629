import React, { useCallback, useMemo, useState } from 'react'
import { GameDataProviderInterface } from '../../contexts/OwnGamesContextProvider'
import { GameAction, TGameCard } from '../../types/commonTypes'
import { TFunction } from 'i18next'
import { ConfirmationDialog, ConfirmationProps } from '../../composites/confirmationDialog/ConfirmationDialog'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getFocusableGameItemId } from '../../composites/GamesOverview/helpers'
import { routes } from '../../routes'
import { DownloadGameForm } from './components/SponsoredGameDownloadForm'
import { GamePreviewModal } from '../../composites/GamePreviewModal/GamePreviewModal'
import { SponsoredDownloadModal } from './components/SponsoredDownloadModal'
import { LoadingModal } from '../../composites/LoadingModal/LoadingModal'
import { useGameNotifications } from '../../hooks/useGameNotifications'

type SponsoredModalWrapperChildrenProps = {
  handleGameActionWithSponsoredModal: (action: GameAction, game: TGameCard, sponsorResponse?: SponsorResponse) => void
}

export type SponsoredModalWrapperProps = {
  children: (props: SponsoredModalWrapperChildrenProps) => React.ReactNode
  gameData: GameDataProviderInterface
  handleGameAction: ((action: GameAction, game: TGameCard) => void) | null
  isInstructorView: boolean
}

const getDefaultConfirmationProps = (t: TFunction): ConfirmationProps => ({
  title: t('confirmation_dialog.default_title', 'Warning'),
  text: t('confirmation_dialog.default_text', 'Are you sure you want to proceed?'),
  confirmActionText: t('confirmation_dialog.default_confirm_action_text', 'Confirm'),
  cancelActionText: t('confirmation_dialog.default_cancel_action_text', 'Cancel'),
})

type SponsorResponse = {
  email: string
  loginUrl: string
  msg: string
  product: string
  success: boolean
  errorMessage: string
}

export const SponsoredModalWrapper: React.FC<SponsoredModalWrapperProps> = ({
  children,
  gameData,
  handleGameAction,
  isInstructorView = false,
}) => {
  const [gameBeingPreviewed, setGameBeingPreviewed] = useState<TGameCard>()
  const [gameShownInDownloadModal, setGameShownInDownloadModal] = useState<TGameCard>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [showContinueConfirmation, setShowContinueConfirmation] = useState<boolean>(false)
  const [showWrongCodeConfirmation, setShowWrongCodeConfirmation] = useState<boolean>(false)
  const [showErrorConfirmation, setShowErrorConfirmation] = useState<boolean>(false)
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)

  const defaultConfirmationProps = useMemo<ConfirmationProps>(() => getDefaultConfirmationProps(t), [t])
  const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps>(defaultConfirmationProps)

  const [loginUrl, setLoginUrl] = useState<string>('')

  const { notifyGameImportStart, notifyGameImportFinish, notifyFetchingGamesFailed } = useGameNotifications()

  const showContinueConfirmationNewAccountProps = {
    title: t('sponsored_games.download.confirm_redirect_new_account_title', 'Activation succesfull!'),
    text: t(
      'sponsored_games.download.confirm_redirect_new_account_text',
      'Your new Seppo account is all set up, and we’ve sent you a confirmation email with your login details. Log in now to get started with your new game!',
    ),
    confirmActionText: t('sponsored_games.download.confirm_redirect_new_account_confirm_action', 'Go to login'),
    cancelActionText: t('sponsored_games.download.confirm_redirect_new_account_cancel_action', 'Cancel'),
  }

  const showContinueConfirmationProps = {
    title: t('sponsored_games.download.confirm_redirect_title', 'Activation succesfull!'),
    text: t(
      'sponsored_games.download.confirm_redirect_text',
      'Your game has been successfully added to your existing Seppo account. We´ve sent you a confirmation email with all the details. Ready to log in now?',
    ),
    confirmActionText: t('sponsored_games.download.confirm_redirect_confirm_action', 'Go to login'),
    cancelActionText: t('sponsored_games.download.confirm_redirect_cancel_action', 'Cancel'),
  }

  const showWrongCodeConfirmationProps = {
    title: t('sponsored_games.download.confirm_wrong_code_title', 'Activation code incorrect!'),
    text: t('sponsored_games.download.confirm_wrong_code_text', 'The code you gave was incorrect.'),
    confirmActionText: t('sponsored_games.download.confirm_wrong_code_confirm_action', 'OK'),
  }

  const showErrorConfirmationProps = {
    title: t('sponsored_games.download.confirm_error_title', 'Something went wrong!'),
    text: t(
      'sponsored_games.download.confirm_error_text',
      'Something went wrong while activating the game. Please try again or contact customer support if problem persists.',
    ),
    confirmActionText: t('sponsored_games.download.confirm_error_confirm_action', 'OK'),
  }

  const handleGameActionWithSponsoredModal = (
    action: GameAction,
    game: TGameCard,
    sponsorResponse?: SponsorResponse,
  ) => {
    if (game.librarySource === 'SPONSORED') {
      if (action === GameAction.VIEW) {
        setGameBeingPreviewed(game)
      } else if (action === GameAction.DOWNLOADMODAL) {
        setGameShownInDownloadModal(game)
      } else if (action === GameAction.DOWNLOAD) {
        handleSponsoredGameDownload(!isInstructorView, sponsorResponse)
      }
    } else {
      if (handleGameAction) handleGameAction(action, game)
    }
  }

  const handleSponsoredGameDownload = (showConfirmation: boolean, sponsorResponse?: SponsorResponse) => {
    if (sponsorResponse && sponsorResponse.success && sponsorResponse.msg === 'OK') {
      setLoginUrl(sponsorResponse?.loginUrl)
      setShowLoadingModal(false)
      if (showConfirmation) {
        if (sponsorResponse.loginUrl.trim() === '/') {
          setConfirmationProps(showContinueConfirmationProps)
        } else {
          setConfirmationProps(showContinueConfirmationNewAccountProps)
        }
        setShowContinueConfirmation(true)
      }
    } else if (sponsorResponse && sponsorResponse.success && sponsorResponse.msg === 'WRONG_CODE') {
      setShowLoadingModal(false)
      if (showConfirmation) {
        setConfirmationProps(showWrongCodeConfirmationProps)
        setShowWrongCodeConfirmation(true)
      }
    } else {
      setShowLoadingModal(false)
      if (showConfirmation) {
        setConfirmationProps(showErrorConfirmationProps)
        setShowErrorConfirmation(true)
      }
    }
    if (!showConfirmation) {
      handleClosePreviewModal()
      handleCloseDownloadModal()
    }
  }

  const handleClosePreviewModal = () => {
    if (gameBeingPreviewed == null) {
      return
    }
    const gameId = gameBeingPreviewed.id
    setGameBeingPreviewed(undefined)
    document.querySelector<any>(`#${getFocusableGameItemId(gameId)}`)?.focus?.()
  }

  const handleCloseDownloadModal = () => {
    if (gameShownInDownloadModal == null) {
      return
    }
    const gameId = gameShownInDownloadModal.id
    setGameShownInDownloadModal(undefined)
    document.querySelector<any>(`#${getFocusableGameItemId(gameId)}`)?.focus?.()
  }

  const resolveConfirmation = useCallback(
    (answer: boolean) => {
      setShowContinueConfirmation(false)
      setConfirmationProps(defaultConfirmationProps)
      if (answer) {
        if (loginUrl.trim() === '/' || loginUrl.trim() === '') {
          navigate(routes.login)
        } else {
          navigate(loginUrl)
        }
      }
      if (gameShownInDownloadModal) setGameShownInDownloadModal(undefined)
      if (gameBeingPreviewed) setGameBeingPreviewed(undefined)
      setLoginUrl('')
    },
    [defaultConfirmationProps, gameBeingPreviewed, loginUrl, navigate, gameShownInDownloadModal],
  )

  const resolveWrongCodeConfirmation = useCallback(
    (answer: boolean) => {
      setShowWrongCodeConfirmation(false)
      setConfirmationProps(defaultConfirmationProps)
    },
    [defaultConfirmationProps],
  )

  const resolveErrorConfirmation = useCallback(
    (answer: boolean) => {
      setShowErrorConfirmation(false)
      setConfirmationProps(defaultConfirmationProps)
    },
    [defaultConfirmationProps],
  )

  const GameActivation = async (formProps: DownloadGameForm, game: TGameCard) => {
    if (!isInstructorView) {
      setShowLoadingModal(true)
    }
    const { sponsorCode, email, organization } = formProps
    let notifyId: string = ''
    if (isInstructorView) {
      notifyId = notifyGameImportStart(game.gameName)
    }
    if (gameData.activateGame) {
      const response = await gameData.activateGame(email, organization, sponsorCode, game.id)
      if (response && response.success && response.value) {
        const newSponsorResponse: SponsorResponse = {
          email: response?.value?.email ?? '',
          loginUrl: response?.value?.login_redir_url ?? '',
          msg: response?.value?.msg ?? '',
          product: response?.value?.product_row ?? '',
          success: response?.success,
          errorMessage: '',
        }
        if (isInstructorView && notifyId) {
          notifyGameImportFinish(notifyId, game.gameName, response.success)
        }
        handleGameActionWithSponsoredModal(GameAction.DOWNLOAD, game, newSponsorResponse)
      } else if (response && !response.success) {
        const newSponsorResponse: SponsorResponse = {
          email: '',
          loginUrl: '',
          msg: '',
          product: '',
          success: response?.success,
          errorMessage: response?.error.toString() ?? '',
        }
        if (isInstructorView && notifyId) {
          notifyGameImportFinish(notifyId, game.gameName, response.success)
          notifyFetchingGamesFailed()
        }
        handleGameActionWithSponsoredModal(GameAction.DOWNLOAD, game, newSponsorResponse)
      } else {
        const newSponsorResponse: SponsorResponse = {
          email: '',
          loginUrl: '',
          msg: '',
          product: '',
          success: false,
          errorMessage: 'Unknown error',
        }
        if (isInstructorView && notifyId) {
          notifyFetchingGamesFailed()
        }
        handleGameActionWithSponsoredModal(GameAction.DOWNLOAD, game, newSponsorResponse)
      }
      return response
    }
    if (isInstructorView && notifyId) {
      notifyFetchingGamesFailed()
    }
    return null
  }

  return (
    <>
      {children({ handleGameActionWithSponsoredModal })}
      {gameBeingPreviewed != null && (
        <GamePreviewModal
          game={gameBeingPreviewed}
          onClose={handleClosePreviewModal}
          onGameAction={handleGameActionWithSponsoredModal}
          isSponsored={true}
          sponsorCode={gameData.filters?.activationCode}
        />
      )}
      {gameShownInDownloadModal != null && (
        <SponsoredDownloadModal
          game={gameShownInDownloadModal}
          onClose={handleCloseDownloadModal}
          activateGame={GameActivation}
          sponsorCode={gameData.filters?.activationCode}
          isInstructorView={isInstructorView}
        />
      )}
      <ConfirmationDialog
        show={showContinueConfirmation}
        confirmationProps={confirmationProps}
        resolveConfirmation={resolveConfirmation}
      />
      <ConfirmationDialog
        show={showWrongCodeConfirmation}
        confirmationProps={confirmationProps}
        resolveConfirmation={resolveWrongCodeConfirmation}
        showCancelButton={false}
      />
      <ConfirmationDialog
        show={showErrorConfirmation}
        confirmationProps={confirmationProps}
        resolveConfirmation={resolveErrorConfirmation}
        showCancelButton={false}
      />
      <LoadingModal show={showLoadingModal} />
    </>
  )
}
