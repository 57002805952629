import { getCurrentIsoDate } from './string'

export const isPastDate = (dateTime: string, defaultValue: boolean = false) => {
  try {
    return new Date(getCurrentIsoDate()).getTime() > new Date(dateTime).getTime()
  } catch (error) {
    console.error(error)
    return defaultValue
  }
}

export const addYearToTimestamp = (dateTime: string): string => {
  try {
    const plusYear = new Date(dateTime)
    plusYear.setFullYear(plusYear.getFullYear() + 1)
    return plusYear.toISOString()
  } catch (error) {
    console.error(error)
    return dateTime
  }
}
