import * as React from 'react'
import { SVGProps } from 'react'
const SvgLevel10Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='level_10_icon_svg__a' gradientUnits='userSpaceOnUse' x1={16} x2={16} y1={1} y2={31}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm-5.947 17V9.45h-2.1V7.5h4.53V18zm9.054.18c-.86 0-1.63-.21-2.31-.63-.68-.43-1.215-1.05-1.605-1.86s-.585-1.79-.585-2.94.195-2.13.585-2.94.925-1.425 1.605-1.845c.68-.43 1.45-.645 2.31-.645.87 0 1.64.215 2.31.645.68.42 1.215 1.035 1.605 1.845s.585 1.79.585 2.94-.195 2.13-.585 2.94-.925 1.43-1.605 1.86c-.67.42-1.44.63-2.31.63zm0-2.055a1.7 1.7 0 0 0 1.065-.345c.31-.23.55-.595.72-1.095.18-.5.27-1.145.27-1.935s-.09-1.435-.27-1.935c-.17-.5-.41-.865-.72-1.095a1.7 1.7 0 0 0-1.065-.345c-.4 0-.755.115-1.065.345-.3.23-.54.595-.72 1.095-.17.5-.255 1.145-.255 1.935s.085 1.435.255 1.935c.18.5.42.865.72 1.095.31.23.665.345 1.065.345zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='currentColor'
      />
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm-5.947 17V9.45h-2.1V7.5h4.53V18zm9.054.18c-.86 0-1.63-.21-2.31-.63-.68-.43-1.215-1.05-1.605-1.86s-.585-1.79-.585-2.94.195-2.13.585-2.94.925-1.425 1.605-1.845c.68-.43 1.45-.645 2.31-.645.87 0 1.64.215 2.31.645.68.42 1.215 1.035 1.605 1.845s.585 1.79.585 2.94-.195 2.13-.585 2.94-.925 1.43-1.605 1.86c-.67.42-1.44.63-2.31.63zm0-2.055a1.7 1.7 0 0 0 1.065-.345c.31-.23.55-.595.72-1.095.18-.5.27-1.145.27-1.935s-.09-1.435-.27-1.935c-.17-.5-.41-.865-.72-1.095a1.7 1.7 0 0 0-1.065-.345c-.4 0-.755.115-1.065.345-.3.23-.54.595-.72 1.095-.17.5-.255 1.145-.255 1.935s.085 1.435.255 1.935c.18.5.42.865.72 1.095.31.23.665.345 1.065.345zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='url(#level_10_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgLevel10Icon
