import * as React from 'react'
import { SVGProps } from 'react'
const SvgBadgeGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='badge_gradient_icon_svg__b' gradientUnits='userSpaceOnUse' x1={16} x2={16} y1={0} y2={32}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <clipPath id='badge_gradient_icon_svg__a'>
      <path d='M0 0h32v32H0z' />
    </clipPath>
    <g clipPath='url(#badge_gradient_icon_svg__a)' clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M7.515 3.515a12 12 0 0 1 19.267 13.752l4.992 6.1a1 1 0 0 1-.578 1.614l-4.43.886-1.817 5.45a1 1 0 0 1-1.795.216l-4.884-7.75a11.997 11.997 0 0 1-4.54 0l-4.884 7.75a1 1 0 0 1-1.795-.217l-1.816-5.45-4.431-.885a1 1 0 0 1-.578-1.614l4.992-6.1A11.999 11.999 0 0 1 7.515 3.515zm16.97 16.97c.439-.438.84-.906 1.2-1.4l3.488 4.26-3.37.675a1 1 0 0 0-.752.664l-1.332 3.995-3.445-5.466a12 12 0 0 0 4.211-2.728zm-16.97 0a12 12 0 0 0 4.21 2.728l-3.444 5.466-1.332-3.995a1 1 0 0 0-.753-.664l-3.368-.674 3.486-4.261c.362.493.763.962 1.2 1.4zM16 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 2.296a1.8 1.8 0 0 0-1.59.96l-.008.013-1.387 2.726h-2.213a1.776 1.776 0 0 0-1.268 3.048l2.112 2.078-1.168 2.684-.006.013a1.785 1.785 0 0 0 2.536 2.238L16 16.373l2.992 1.683a1.785 1.785 0 0 0 2.536-2.239l-1.174-2.695 2.111-2.073A1.776 1.776 0 0 0 21.198 8h-2.212L17.6 5.27l-.892.453.884-.468a1.8 1.8 0 0 0-1.591-.959zm-1.48 5.152L16 6.538l1.48 2.915a1 1 0 0 0 .892.547h2.307l-2.217 2.177a1 1 0 0 0-.216 1.113l1.183 2.717-2.939-1.653a1 1 0 0 0-.98 0l-2.939 1.653 1.183-2.717a1 1 0 0 0-.215-1.112L11.32 9.995h2.308a1 1 0 0 0 .891-.547z'
        fill='currentColor'
      />
      <path
        d='M7.515 3.515a12 12 0 0 1 19.267 13.752l4.992 6.1a1 1 0 0 1-.578 1.614l-4.43.886-1.817 5.45a1 1 0 0 1-1.795.216l-4.884-7.75a11.997 11.997 0 0 1-4.54 0l-4.884 7.75a1 1 0 0 1-1.795-.217l-1.816-5.45-4.431-.885a1 1 0 0 1-.578-1.614l4.992-6.1A11.999 11.999 0 0 1 7.515 3.515zm16.97 16.97c.439-.438.84-.906 1.2-1.4l3.488 4.26-3.37.675a1 1 0 0 0-.752.664l-1.332 3.995-3.445-5.466a12 12 0 0 0 4.211-2.728zm-16.97 0a12 12 0 0 0 4.21 2.728l-3.444 5.466-1.332-3.995a1 1 0 0 0-.753-.664l-3.368-.674 3.486-4.261c.362.493.763.962 1.2 1.4zM16 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 2.296a1.8 1.8 0 0 0-1.59.96l-.008.013-1.387 2.726h-2.213a1.776 1.776 0 0 0-1.268 3.048l2.112 2.078-1.168 2.684-.006.013a1.785 1.785 0 0 0 2.536 2.238L16 16.373l2.992 1.683a1.785 1.785 0 0 0 2.536-2.239l-1.174-2.695 2.111-2.073A1.776 1.776 0 0 0 21.198 8h-2.212L17.6 5.27l-.892.453.884-.468a1.8 1.8 0 0 0-1.591-.959zm-1.48 5.152L16 6.538l1.48 2.915a1 1 0 0 0 .892.547h2.307l-2.217 2.177a1 1 0 0 0-.216 1.113l1.183 2.717-2.939-1.653a1 1 0 0 0-.98 0l-2.939 1.653 1.183-2.717a1 1 0 0 0-.215-1.112L11.32 9.995h2.308a1 1 0 0 0 .891-.547z'
        fill='url(#badge_gradient_icon_svg__b)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgBadgeGradientIcon
