import * as React from 'react'
import { SVGProps } from 'react'
const SvgLockGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 30 30' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='lock_gradient_icon_svg__a' gradientUnits='userSpaceOnUse' x1={15} x2={15} y1={0.75} y2={29.25}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M.75 8a7.25 7.25 0 0 1 14.5 0v3c0 .138.112.25.25.25h10.875a2.876 2.876 0 0 1 2.875 2.875v12.25a2.876 2.876 0 0 1-2.875 2.875h-15.75a2.876 2.876 0 0 1-2.875-2.875v-12.25a2.876 2.876 0 0 1 2.875-2.875h2.125A.25.25 0 0 0 13 11V8A5 5 0 0 0 3 8v4.375a1.125 1.125 0 0 1-2.25 0zm9.875 5.5a.626.626 0 0 0-.625.625v12.25c0 .345.28.625.625.625h15.75c.345 0 .625-.28.625-.625v-12.25a.626.626 0 0 0-.625-.625z'
        fill='currentColor'
      />
      <path
        d='M.75 8a7.25 7.25 0 0 1 14.5 0v3c0 .138.112.25.25.25h10.875a2.876 2.876 0 0 1 2.875 2.875v12.25a2.876 2.876 0 0 1-2.875 2.875h-15.75a2.876 2.876 0 0 1-2.875-2.875v-12.25a2.876 2.876 0 0 1 2.875-2.875h2.125A.25.25 0 0 0 13 11V8A5 5 0 0 0 3 8v4.375a1.125 1.125 0 0 1-2.25 0zm9.875 5.5a.626.626 0 0 0-.625.625v12.25c0 .345.28.625.625.625h15.75c.345 0 .625-.28.625-.625v-12.25a.626.626 0 0 0-.625-.625z'
        fill='url(#lock_gradient_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgLockGradientIcon
