import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfirmation } from '../../../../../../../contexts/ConfirmationContext'
import { useGame } from '../../../../../../../contexts/GameContext'
import { useDebounce } from '../../../../../../../hooks/useDebounce'
import { Game, Task } from '../../../../../../../types/commonTypes'
import { addPinHighlight, removePinHighlight } from '../../../../Marker/TaskPinHelper'
import { BoardTasksMap, TaskAction, TaskActionData, TaskActionFn } from '../../types'
import { SimpleTaskCard } from '../TaskCard/SimpleTaskCard'
import { BoardContainer } from './BoardContainer'
import styles from './BoardsList.module.css'
import { changeTaskBoard, changeTaskOrderInGroup, getLevelsTaskData, getTasksMap } from './helpers'

type BoardsListProps = {
  game: Game
  isCompact: boolean
  activeBoardIndex: number
  viewOnly: boolean
  onSetActiveBoard: (boardIndex: number) => void
  onTaskAction: TaskActionFn
  onSetShowDragCancel: (show: boolean) => void
}

export const BoardsList: React.FC<BoardsListProps> = ({
  game,
  isCompact,
  activeBoardIndex,
  viewOnly,
  onSetActiveBoard,
  onTaskAction,
  onSetShowDragCancel,
}) => {
  const { t } = useTranslation()
  const { updateTasksOrder, gameUpdateMeta } = useGame()
  const { requestConfirmation } = useConfirmation()

  const [tasksMap, setTasksMap] = useState<BoardTasksMap>(getTasksMap(game))
  const tasksMapOriginal = useRef<BoardTasksMap>(tasksMap)
  const debouncedTasksMap = useDebounce(tasksMap, 1_000)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const tasksMapDragStartCopy = useRef<BoardTasksMap>()
  const draggingTask = useRef<Task>()
  const shouldSubmitOrderUpdate = useRef<boolean>(false)
  const didMount = useRef<boolean>(false)

  useEffect(() => {
    if (didMount.current && gameUpdateMeta?.lastUpdateType !== 'manual-reordering') {
      const newMap = getTasksMap(game)
      setTasksMap(newMap)
      tasksMapOriginal.current = newMap
    }
  }, [game, gameUpdateMeta])

  useEffect(() => {
    didMount.current = true
  }, [])

  useEffect(() => {
    if (shouldSubmitOrderUpdate.current) {
      shouldSubmitOrderUpdate.current = false
      updateTasksOrder(getLevelsTaskData(debouncedTasksMap)).then((success) => {
        if (!success) {
          requestConfirmation({
            title: t('game_editor.ordering.order_failed_confirmation.title', 'Reordering failed'),
            text: t(
              'game_editor.ordering.order_failed_confirmation.text',
              'An error occurred while reordering tasks. You can try setting the new order again by clicking retry, or revert to old order by clicking revert.',
            ),
            cancelActionText: t('game_editor.ordering.order_failed_confirmation.action_revert', 'Revert'),
            confirmActionText: t('game_editor.ordering.order_failed_confirmation.action_retry', 'Retry'),
          }).then((shouldRetry) => {
            if (shouldRetry) {
              shouldSubmitOrderUpdate.current = true
              setTasksMap((prev) => ({ ...prev }))
            } else {
              setTasksMap(tasksMapOriginal.current)
            }
          })
        }
      })
    }
  }, [updateTasksOrder, debouncedTasksMap, requestConfirmation, t])

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      draggingTask.current = event.active.data.current?.task as Task
      tasksMapDragStartCopy.current = { ...tasksMap }
      setIsDragging(true)
      onSetShowDragCancel(true)
      setTimeout(() => addPinHighlight(parseInt(event.active.id.toString())), 0)
    },
    [tasksMap, onSetShowDragCancel],
  )

  const handleDragCancel = useCallback(
    (event: any) => {
      setIsDragging(false)
      onSetShowDragCancel(false)
      tasksMapDragStartCopy.current = undefined
      removePinHighlight(parseInt(event.active.id.toString()))
    },
    [onSetShowDragCancel],
  )

  // move dragged task to the board group it's currently being dragged over
  const handleDragOver = useCallback((event: DragOverEvent) => {
    if (event.over != null && draggingTask.current) {
      const activeTask = draggingTask.current
      const overTask = event.over.data.current?.task as Task
      if (activeTask.mapIndex !== overTask.mapIndex) {
        setTasksMap((prev) => changeTaskBoard(prev, activeTask, overTask.mapIndex ?? 0))
        draggingTask.current = {
          ...activeTask,
          mapIndex: overTask.mapIndex,
        }
      }
    }
  }, [])

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (event.over == null && tasksMapDragStartCopy.current != null) {
        // revert to state when drag started, if task is dropped outside
        setTasksMap(tasksMapDragStartCopy.current)
      } else if (event.over != null && draggingTask.current != null) {
        shouldSubmitOrderUpdate.current = true
        const oldIndex = event.active.data.current?.sortable.index
        const newIndex = event.over.data.current?.sortable.index
        if (newIndex != null && newIndex !== oldIndex) {
          // place the task on correct position inside the new group
          setTasksMap((prev) =>
            draggingTask.current == null
              ? prev
              : changeTaskOrderInGroup(prev, draggingTask.current, oldIndex, newIndex),
          )
        } else {
          // trigger an update to submit moving the task to the bottom of a new group
          setTasksMap((prev) => ({ ...prev }))
        }
      }
      setIsDragging(false)
      onSetShowDragCancel(false)
      tasksMapDragStartCopy.current = undefined
      removePinHighlight(parseInt(event.active.id.toString()))
    },
    [onSetShowDragCancel],
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )

  const onTaskActionInternal = useCallback(
    (actionData: TaskActionData) => {
      const task = actionData.task
      switch (actionData.action) {
        case TaskAction.MOVE_BOARD:
          shouldSubmitOrderUpdate.current = true
          const newMapIndex = actionData.moveIndex ?? 0
          setTasksMap((prev) => changeTaskBoard(prev, task, newMapIndex))
          break
        case TaskAction.MOVE_ORDER:
          shouldSubmitOrderUpdate.current = true
          setTasksMap((prev) => {
            const oldIndex = prev[task.mapIndex ?? 0].findIndex((aTask) => aTask.id === task.id)
            const newIndex = actionData.moveIndex ?? 0
            return changeTaskOrderInGroup(prev, task, oldIndex, newIndex)
          })
          break
        default:
          onTaskAction(actionData)
      }
    },
    [onTaskAction],
  )

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <ul className={styles.boardsContainer}>
        {Object.keys(tasksMap).map((boardIndexKey) => {
          const boardIndex = parseInt(boardIndexKey)
          if (!game.gameBoardSettings.gameBoards[boardIndex]) return null
          return (
            <BoardContainer
              key={`boardContainer_${boardIndex}`}
              boardIndex={boardIndex ?? 0}
              boardUrl={game.gameBoardSettings.gameBoards[boardIndex].url}
              maxBoardIndex={game.gameBoardSettings.gameBoards.length - 1}
              isActive={activeBoardIndex === boardIndex}
              tasks={tasksMap[boardIndex]}
              title={t('game_editor.game_boards.board', 'Board') + ' ' + ((boardIndex ?? 0) + 1)}
              isCompact={isCompact}
              onSetActiveBoard={onSetActiveBoard}
              onTaskAction={onTaskActionInternal}
              noPointsGame={game.advancedSettings.noPointsGame}
              isDragging={isDragging}
              isOrderEnabled={game.advancedSettings.orderingEnabled}
              viewOnly={viewOnly}
            />
          )
        })}
      </ul>
      <DragOverlay>
        {isDragging && draggingTask.current ? (
          <SimpleTaskCard
            task={draggingTask.current}
            compact={isCompact}
            noPointsGame={game?.advancedSettings.noPointsGame}
            showOrder={game.advancedSettings.orderingEnabled}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}
