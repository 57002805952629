import { Tag } from '../../../../../../types/commonTypes'
import styles from './PlayersAddByPlayerAccountModal.module.css'
import tagStyles from './PlayerAccountModalListLines.module.css'
import { getIcon } from '../../../../../../common/components/icons/utils'
import { KeyboardEventHandler } from 'react'
import classNames from 'classnames'
import { HighlightedLabel } from './PlayerAccountModalListLines'
import { useTranslation } from 'react-i18next'

type SearchDropDownProps = {
  searchInputValue: string
  onKeyDown: KeyboardEventHandler
  onSetSearchInputValue: (value: string) => void
  onClearInputAndSearch: () => void
  onShowAllPlayers: (show: boolean) => void
  onShowDropdownTagResults: (show: boolean) => void
  showDropdownTagResults: boolean
  tags: Tag[]
  dropdownMatchingTags: Tag[]
  onAddActiveTag: (tag: Tag) => void
}

export const SearchDropdown: React.FC<SearchDropDownProps> = ({
  searchInputValue,
  onKeyDown,
  onSetSearchInputValue,
  onClearInputAndSearch,
  onShowAllPlayers,
  onShowDropdownTagResults,
  showDropdownTagResults,
  tags,
  dropdownMatchingTags,
  onAddActiveTag,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.searchAreaContainer}>
        <div className={styles.searchInputContainer}>
          <div onClick={() => document.getElementById('playerAccountInput')?.focus()} className={styles.prefixIcon}>
            {getIcon('multipleUsers')}
          </div>
          <input
            autoComplete='off'
            id='playerAccountInput'
            type='text'
            name='search'
            value={searchInputValue}
            onFocus={() => onShowDropdownTagResults(true)}
            onBlur={() =>
              setTimeout(() => {
                onShowDropdownTagResults(false)
              }, 200)
            }
            onKeyDown={onKeyDown}
            onChange={(evt) => {
              onSetSearchInputValue(evt.target.value)
            }}
            className={styles.plainInputField}
            placeholder={t('game_editor.add_people.player_account.search_placeholder', 'Search tag or name')}
          />
          <div
            onClick={(evt) => {
              onClearInputAndSearch()
              evt.stopPropagation()
              return false
            }}
            className={styles.clearInpuFieldX}
          >
            {getIcon('close')}
          </div>
        </div>
      </div>
      {showDropdownTagResults && (
        <div className={classNames(styles.resultsAreaContainer, styles.instantResultsList)}>
          <div className={styles.searchPeopleHint}>
            {t('game_editor.add_people.player_account.enter_to_search', 'Press enter to search for people')}
          </div>
          <div
            onClick={() => {
              onShowAllPlayers(true)
            }}
            className={styles.showAllPlayerAccountsOption}
          >
            {t('game_editor.add_people.player_account.show_all_player_accounts_option', 'Show all Player accounts')}
          </div>
          <div className={styles.instantTagsTitle}>{t('game_editor.add_people.player_account.all_tags', 'Tags')}</div>
          {(!searchInputValue.length ? tags : dropdownMatchingTags).map((tag, index) => {
            return (
              <div onClick={() => onAddActiveTag(tag)} key={index} className={classNames(tagStyles.clickableTag)}>
                <HighlightedLabel label={tag.label} searchTerm={searchInputValue} />
                <span>{getIcon('plus')}</span>
              </div>
            )
          })}
          {!!searchInputValue.length && !dropdownMatchingTags.length && (
            <div className={classNames(styles.instantTagsTitle, styles.noTagsTitle)}>
              {t('game_editor.add_people.player_account.no_tags', 'No matching tags')}
            </div>
          )}
        </div>
      )}
    </>
  )
}
