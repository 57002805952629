import * as React from 'react'
import { SVGProps } from 'react'
const SvgWelcomeDay04Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 245 250.98' xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' {...props}>
    <g fill='var(--welcome-block-dark)'>
      <path d='M0 163.01c11.88 12.48 17.86 18.79 17.96 18.92.02.03 30.83 2.3 92.44 6.84l22.31 1.64c.27 1.61 1.73 8.94 4.39 21.98 2.67 13.13 4.09 19.94 4.25 20.42v-41.76l71.61 5.24c-.65-1.83-5.26-14.55-13.84-38.19 1.27-2.8 4.11-8.94 8.52-18.44 5.32-11.46 8.98-19.25 10.98-23.39-1.33.25-27.09 3.79-77.28 10.61l-30.94 4.21c-26.76 3.62-58.81 7.94-96.15 12.97z' />
      <path d='M18.48 148.99c2.1-.18 10.34-1.04 24.74-2.59 17.25-1.86 34.42-3.76 51.51-5.71 50.84-5.77 84.66-10.07 101.48-12.9-2.62 8.92-4.89 16.55-6.8 22.89-1.58 5.22-2.39 7.83-2.42 7.82.03.07 1.61 4.4 4.73 13 3.04 8.39 4.91 13.59 5.63 15.6-6.39-.45-36.99-2.26-91.8-5.44-52.62-3.05-80.99-4.68-85.12-4.88-.12 0-.17-.02-.16-.05.02-.05-2.16-2.27-6.54-6.66-4.4-4.4-6.76-6.72-7.09-6.96.26-.32 2.04-2.43 5.34-6.34 3.13-3.71 5.3-6.31 6.51-7.79M141.34 43.07l-30.94-1.79c-28.36-1.64-48.05-2.78-59.07-3.42v79.86c10.89-1.54 30.58-4.35 59.07-8.43l30.94-4.45c5.44-.78 22.82-3.26 52.16-7.46 25.13-3.58 37.69-5.39 37.7-5.4.36-.58 2.6-4.05 6.72-10.4 4.26-6.58 6.62-10.19 7.08-10.83-.09-.06-6.16-7.63-18.19-22.71-.08-.02-28.58-1.67-85.48-4.96' />
      <path d='M128.48 47.63s-10.69.57-32.01 1.7c-21.26 1.12-31.94 1.71-32.03 1.75l-7.63 39.09c0-.5.17-8.26.53-23.27.36-15.07.57-23.01.61-23.84 1.47.12 24.98 1.64 70.54 4.58' />
      <path d='M217.77 66.78c-2.05.59-4.92.94-8.61 1.05-1.16.03-43.57-.63-127.22-1.99-4.1-.06-6.91-.45-8.42-1.18-1.34-.64-1.94-1.71-1.8-3.2 0-.12.03-.23.06-.34.5-1.66 3.53-2.37 9.09-2.13.42.02 20.56.42 60.42 1.2 39.86.79 60.33 1.2 61.39 1.24 5.38.23 9.63.68 12.77 1.34 2.22.47 3.67 1 4.35 1.59.17.14.29.3.37.46.35.74-.45 1.39-2.4 1.95M82.05 91.08c-.8.04-1.56.07-2.27.08-2.89.03-4.96-.21-6.21-.71-1.37-.56-2.04-1.58-2-3.07 0-.19.04-.36.1-.52.56-1.58 3.52-2.43 8.88-2.55.42 0 20.54-.91 60.37-2.7 39.83-1.78 60.28-2.69 61.34-2.73.81-.02 1.6-.03 2.36-.03l6.37.11c1.53.11 2.9.25 4.11.43 2.31.33 3.81.77 4.5 1.33.15.13.27.26.34.39.39.72-.36 1.42-2.26 2.12-2.02.72-4.87 1.25-8.53 1.6-1.16.11-43.52 2.19-127.08 6.24' />
    </g>
    <path
      d='M26.96 160.48c-1.62.68-2.43 1.45-2.42 2.31 0 .86.88 1.58 2.63 2.14 1.94.63 4.65.95 8.13.95 23.43-.03 46.26-.54 68.48-1.51 14.09-.63 32.69-1.7 55.8-3.23 5.04-.35 8.99-.95 11.84-1.8 2.63-.79 3.83-1.61 3.59-2.47-.24-.88-1.92-1.56-5.06-2.03-3.51-.52-8.22-.67-14.14-.44-20.7.72-40.22 1.42-58.56 2.09-35.95 1.32-57.03 2.18-63.26 2.59-2.93.19-5.27.66-7.03 1.41M96.47 49.33c21.31-1.13 31.98-1.7 32.01-1.7-45.55-2.93-69.07-4.46-70.54-4.58-.04.83-.25 8.78-.61 23.84-.36 15.02-.54 22.77-.53 23.27l7.63-39.09c.09-.04 10.77-.63 32.03-1.75'
      fill='#fff'
    />
    <path
      d='m132.7 190.41-22.31-1.64v48.74c0 .98.35 1.82 1.04 2.51 2.33 2.32 5.05 4.46 8.17 6.4 3.62 2.25 6.93 3.67 9.93 4.26 3.57.71 6.35.14 8.34-1.7 2.34-2.15 3.5-5.97 3.46-11.48v-4.7c-.16-.48-1.58-7.29-4.25-20.42-2.65-13.04-4.12-20.36-4.39-21.98M141.34 126.88v-22.05l-30.94 4.45v21.81zM43.22 146.4c-14.4 1.55-22.65 2.41-24.74 2.59-1.21 1.48-3.38 4.08-6.51 7.79-3.3 3.91-5.08 6.02-5.34 6.34.33.25 2.69 2.57 7.09 6.96 4.38 4.39 6.56 6.61 6.54 6.66 0 .03.05.05.16.05 4.13.2 32.5 1.83 85.12 4.88 54.81 3.18 85.41 4.99 91.8 5.44-.72-2.01-2.6-7.21-5.63-15.6-3.12-8.6-4.7-12.93-4.73-13 .03 0 .84-2.6 2.42-7.82 1.91-6.34 4.18-13.97 6.8-22.89-16.82 2.82-50.64 7.12-101.48 12.9-17.1 1.94-34.27 3.85-51.51 5.71'
      fill='var(--welcome-block-light)'
    />
    <path
      d='M24.54 162.78c0-.85.8-1.62 2.42-2.31 1.76-.74 4.1-1.21 7.03-1.41 6.22-.41 27.31-1.27 63.26-2.59 18.35-.68 37.87-1.37 58.56-2.09 5.92-.23 10.63-.08 14.14.44 3.14.47 4.82 1.15 5.06 2.03.24.86-.96 1.69-3.59 2.47-2.86.85-6.81 1.45-11.84 1.8-23.11 1.53-41.71 2.61-55.8 3.23-22.23.97-45.05 1.48-68.48 1.51-3.47 0-6.18-.32-8.13-.95-1.74-.57-2.62-1.28-2.63-2.14'
      fill='#fff'
    />
    <path
      d='m51.32 37.86-10.5-.61.51 81.85 9.99-1.39zM79.78 91.16c.71 0 1.47-.03 2.27-.08 83.56-4.05 125.93-6.13 127.08-6.24 3.67-.35 6.51-.88 8.53-1.6 1.89-.69 2.65-1.4 2.26-2.12-.08-.14-.19-.27-.34-.39-.69-.56-2.19-1-4.5-1.33-1.21-.18-2.58-.32-4.11-.43l-6.37-.11c-.76 0-1.55 0-2.36.03-1.07.03-21.51.94-61.34 2.73-39.82 1.79-59.94 2.69-60.37 2.7-5.36.12-8.32.97-8.88 2.55-.06.16-.09.33-.1.52-.03 1.49.63 2.51 2 3.07 1.25.5 3.32.74 6.21.71M209.16 67.84c3.69-.11 6.56-.46 8.61-1.05 1.94-.57 2.74-1.22 2.4-1.95-.08-.16-.2-.31-.37-.46-.68-.58-2.13-1.11-4.35-1.59-3.14-.67-7.39-1.12-12.77-1.34-1.07-.04-21.53-.46-61.39-1.24-39.85-.79-59.99-1.19-60.42-1.2-5.56-.24-8.59.47-9.09 2.13-.03.11-.05.22-.06.34-.14 1.49.47 2.55 1.8 3.2 1.51.73 4.32 1.12 8.42 1.18 83.66 1.36 126.07 2.02 127.22 1.99M114.95 4.54s-.01 0-.01.01c-3.03 3.01-4.54 6.65-4.54 10.92v25.81l30.94 1.79v-27.6c0-4.27-1.51-7.91-4.53-10.93C133.78 1.51 130.14 0 125.87 0s-7.91 1.51-10.92 4.54'
      fill='var(--welcome-block-light)'
    />
  </svg>
)
export default SvgWelcomeDay04Icon
