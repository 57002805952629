import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../AddPerson.module.css'
import { BackButton } from '../../../../../common/components/button/BackButton'
import { Game } from '../../../../../types/commonTypes'
import { getIcon } from '../../../../../common/components/icons/utils'

type ShareButtonProps = {
  game: Game
  handleOptionsField: () => void
}

export const ShareButtons: React.FC<ShareButtonProps> = ({ game, handleOptionsField }) => {
  const { t } = useTranslation()

  const renderTeamsButtons = () => {
    window.shareToMicrosoftTeams.renderButtons()
  }

  const pinUrl = `${process.env.REACT_APP_OLD_UI_LOGIN_URL}/login/${game.pinCode}`

  const teamsRef = React.useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (teamsRef.current && !teamsRef.current.classList.contains('button-rendered')) {
      renderTeamsButtons()
      teamsRef.current.classList.add('button-rendered')
    }
  })

  const handleGoogleButtonPress = () => {
    window.open(
      `https://classroom.google.com/share?url=${pinUrl}&title=${game.name}&body=${game.description}`,
      'popup',
      'height=500,width=500',
    )
  }

  return (
    <div className={classNames(styles.moreOptions)}>
      <BackButton onClick={handleOptionsField} />
      <button
        ref={teamsRef}
        className={classNames(styles.shareButtons, 'teams-share-button')}
        data-href={pinUrl}
        data-assign-title={game.name}
        data-msg-text={game.name}
        data-assign-instr={game.description}
      >
        <div>{t('game_editor.add_people.share_teams', 'Share game via Microsoft Teams')}</div>
      </button>

      <button className={classNames(styles.shareButtons, 'google-share')} onClick={handleGoogleButtonPress}>
        <div>{t('game_editor.add_people.share_google', 'Share game via Google ClassRoom')}</div>
        <div className={classNames(styles.shareToGoogle)}>
          <span className='iconWrapperBig'>{getIcon('googleClassroom')}</span>
          <div />
        </div>
      </button>
    </div>
  )
}
