import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { CloseButton } from '../../../common/components/button/CloseButton'
import { WelcomeBlockData } from '../helpers'
import { BannerButton } from './BannerButton'
import styles from './WelcomeBanner.module.css'

type WelcomeBannerProps = {
  data: Omit<WelcomeBlockData, 'id'>
  injectRawBody?: boolean
  icon: JSX.Element
  language?: string
  onClose: () => void
}

export const WelcomeBanner: React.FC<WelcomeBannerProps> = ({
  data: { title, body, primaryButton, secondaryButton },
  injectRawBody = false,
  icon,
  language,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.textAndActions}>
        <div className={styles.title}>{title}</div>
        {injectRawBody && (
          <div dangerouslySetInnerHTML={{ __html: body }} className={classNames(styles.welcomeText, 'normal')} />
        )}
        {!injectRawBody && <div className={classNames(styles.welcomeText, 'normal')}>{body}</div>}
        <div className={styles.buttonsContainer}>
          {primaryButton != null && <BannerButton button={primaryButton} language={language} />}
          {secondaryButton != null && <BannerButton button={secondaryButton} language={language} isSecondary />}
        </div>
      </div>

      <CloseButton
        className={styles.closeButton}
        onClick={onClose}
        aria-label={t('accessibility.close_banner_button', 'Dismiss banner')}
      />
      <div className={styles.firstCircle} />
      <div className={styles.secondCircle} />
      <div className={styles.welcomeBannerIcon}>{icon}</div>
    </div>
  )
}
