import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from 'use-onclickoutside'
import { PopupActionMenuItem } from '../../../common/components/PopupMenu/PopupActionMenu'
import { Button } from '../../../common/components/button/Button'
import { getIcon } from '../../../common/components/icons/utils'
import { GameAction } from '../../../types/commonTypes'
import { noop } from '../../../util/functional'
import styles from './GamePreviewModalSidebar.module.css'

export type SidebarActionsProps = {
  isLibrary: boolean
  onGameAction: (action: GameAction) => void
  isExpired: boolean
  isSponsored?: boolean
  sponsorCode?: string | undefined
  hasSponsorCode?: boolean | undefined
}

const buttonId = 'more-button-id'

export const SidebarActions: React.FC<SidebarActionsProps> = ({
  isLibrary,
  onGameAction,
  isExpired,
  isSponsored = false,
  sponsorCode,
  hasSponsorCode = true,
}) => {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false)
  const { t } = useTranslation()

  const handleMenuItemClick = (action: GameAction) => {
    setIsMoreMenuOpen(false)
    onGameAction(action)
  }

  const menuRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(menuRef, (e) => {
    if ((e.target as any)?.getAttribute?.('id') !== buttonId) {
      setIsMoreMenuOpen(false)
    }
  })

  return (
    <div className={styles.sidebarActionsContainer}>
      {isSponsored && (sponsorCode || !hasSponsorCode) && (
        <Button secondary onClick={() => onGameAction(GameAction.DOWNLOADMODAL)} disabled={isExpired}>
          <span className='iconWrapperMedium'>{getIcon('plus')}</span>
          {t('game_preview_modal.actions_text.download', 'Download')}
        </Button>
      )}
      {isSponsored && !sponsorCode && hasSponsorCode && (
        <Button secondary onClick={() => onGameAction(GameAction.DOWNLOADMODAL)} disabled={isExpired}>
          <span className='iconWrapperMedium'>{getIcon('plus')}</span>
          {t('game_preview_modal.actions_text.get_with_sponsor_code', 'Activate with code')}
        </Button>
      )}
      {!isSponsored &&
        (isLibrary ? (
          <Button secondary onClick={!isExpired ? () => onGameAction(GameAction.IMPORT) : noop} disabled={isExpired}>
            <span className='iconWrapperMedium'>{getIcon('plus')}</span>
            {t('game_preview_modal.actions_text.import', 'Import')}
          </Button>
        ) : (
          <>
            <Button className={styles.sidebarActionButton} onClick={() => onGameAction(GameAction.EDIT)}>
              {getIcon('duplicate')}
              {t('game_preview_modal.actions_text.edit', 'Edit game')}
            </Button>
            <Button
              className={styles.sidebarActionButton}
              variant='outline-normal'
              onClick={() => setIsMoreMenuOpen((prev) => !prev)}
              id={buttonId}
            >
              {getIcon('more')}
              {t('game_preview_modal.actions_text.more', 'More')}
            </Button>
            {isMoreMenuOpen && (
              <div className={styles.moreMenuContainer} ref={menuRef}>
                <PopupActionMenuItem
                  icon='duplicate'
                  text={t('context_menu.duplicate', 'Duplicate game')}
                  onClick={!isExpired ? () => handleMenuItemClick(GameAction.COPY) : noop}
                  disabled={isExpired}
                />
                <PopupActionMenuItem
                  icon='trash'
                  text={t('context_menu.delete_game', 'Delete game')}
                  onClick={() => handleMenuItemClick(GameAction.DELETE)}
                />
              </div>
            )}
          </>
        ))}
    </div>
  )
}
