import React, { useEffect, useRef } from 'react'
import { useGameData } from '../../contexts/SponsoredGamesContextProvider'
import styles from './Sponsored.module.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getFocusableGameItemId } from '../../composites/GamesOverview/helpers'
import { LegacyEditorVersion, setLegacyEditorVersion } from '../../util/editorPreference'
import { BannerWithChildren } from '../../common/components/banners/BannerWithChildren'
import { SponsoredGamesSearch } from './components/SponsoredGamesSearch'
import { Sponsored } from './Sponsored'

export const InstructorSponsoredPage: React.FC = () => {
  const { currentPage, setFilters, filters, loading } = useGameData()
  const gameData = useGameData()
  const { t } = useTranslation()

  const lastCurrentPage = useRef<number>(currentPage)

  useEffect(() => {
    if (currentPage !== lastCurrentPage.current) {
      const firstGameElement = document.querySelector<HTMLElement>(`[id^=${getFocusableGameItemId('')}]`)
      firstGameElement?.focus({ preventScroll: true })
      if ((firstGameElement?.getBoundingClientRect().top ?? 0) < 0) {
        firstGameElement?.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' })
      }
    }
    lastCurrentPage.current = currentPage
  }, [currentPage])

  useEffect(() => {
    setLegacyEditorVersion(LegacyEditorVersion.LEGACY_FRAME)
  }, [])

  return (
    <div className={classNames(styles.contentContainerInstructor)}>
      <div className={styles.headerContainer}>
        {/*user && <button onClick={handleSilentLogout}>Click to logout - will be done automatically normally</button>*/}
        <div className={styles.bannerContainer}>
          <div className={styles.introBanner}>
            <BannerWithChildren title={t('sponsored_games.instructor.info_body', 'Sponsored games:')}>
              <div className={styles.firstBannerBody}>
                {t(
                  'sponsored_games.instructor.info_body_1',
                  'Dive into our magical collection of sponsored Seppo games! Browse through the library or search for a game using its activation code.',
                )}
              </div>
              <div className={styles.firstBannerBody}>
                {t(
                  'sponsored_games.instructor.info_body_2',
                  'P.S. You can usually find the games activation code on the game creator´s website.',
                )}
              </div>
            </BannerWithChildren>
          </div>
          <div className={styles.searchBanner}>
            <BannerWithChildren
              title={t('sponsored_games.instructor.search_title', 'Activation code')}
              color='secondary'
            >
              <SponsoredGamesSearch
                loading={loading}
                filters={filters}
                setFilters={setFilters}
                isInstructorView={true}
              />
            </BannerWithChildren>
          </div>
        </div>
      </div>
      <Sponsored isInstructorView={true} gameData={gameData} />
    </div>
  )
}
