import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import { LibrarySource } from '../../api/gameTypes'
import { parseLibraryGamesResponseToGameCards } from '../../api/typeConverters'
import { startNewTrial } from '../../api/userApiService'
import { ValidationNotificationSpy } from '../../common/components/Form/ValidationNotificationSpy/ValidationNotificationSpy'
import { Select, SelectOption } from '../../common/components/Select/Select'
import { Button } from '../../common/components/button/Button'
import { getIcon } from '../../common/components/icons/utils'
import { LANGUAGE_SELECT_DEFAULT_OPTION, LANGUAGE_SELECT_OPTIONS } from '../../common/constants'
import shared from '../../common/styles/shared.module.css'
import { useNotification } from '../../contexts/NotificationContext'
import { useTheme } from '../../contexts/ThemeContext'
import { useUser } from '../../contexts/userContext'
import { useGameEditorNavigation } from '../../hooks/useGameEditorNavigation'
import { routes } from '../../routes'
import { TGameCard } from '../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../util/string'
import styles from './OnboardingWizard.module.css'
import { FORM_STEPS as FORM_STEPS_COMMON, FORM_STEPS_SPONSORED, parseFormValuesToSubmitData } from './helpers'
import { SuggestionStep } from './steps/SuggestionStep'
import { OnboardingForm } from './types'

export const OnboardingWizard: React.FC = () => {
  const navigate = useNavigate()
  const { user, updateUser, doLogout } = useUser()
  const { logoUrl } = useTheme()
  const { t } = useTranslation()
  const { notifyError } = useNotification()
  const { goToCreateGame } = useGameEditorNavigation()

  const [stepIndex, setStepIndex] = useState<number>(0)
  const [remainOnWizardWithTermsAccepted, setRemainOnWizardWithTermsAccepted] = useState<boolean>(false)
  const [suggestedGames, setSuggestedGames] = useState<TGameCard[]>([])
  const [initialValues] = useState<Partial<OnboardingForm>>(() => {
    const userIndustry = user?.activeBusiness?.industry
    return {
      country: user?.activeBusiness?.country || undefined,
      ...(!safeIsNullOrEmpty(userIndustry) && {
        organisationType: userIndustry === 'Corporate' ? 'Business' : userIndustry,
      }),
      termsOfService: false,
    }
  })
  const FORM_STEPS = user?.isSponsoredUser ? FORM_STEPS_SPONSORED : FORM_STEPS_COMMON
  const TOTAL_STEPS_COUNT = FORM_STEPS.length + 1

  useEffect(() => {
    if (user?.termsAccepted && !remainOnWizardWithTermsAccepted) {
      navigate(routes.homepage)
    }
  }, [user, remainOnWizardWithTermsAccepted, navigate])

  if (user == null) {
    return null
  }

  const previous = () => setStepIndex((prev) => Math.max(prev - 1, 0))

  const next = () => setStepIndex((prev) => Math.min(prev + 1, TOTAL_STEPS_COUNT - 1))

  const validate = (values: Partial<OnboardingForm>) => {
    return FORM_STEPS?.[stepIndex]?.validateCreator(t)(values) ?? {}
  }

  const onSubmit = (values: OnboardingForm) => {
    if (stepIndex === FORM_STEPS.length - 1) {
      setRemainOnWizardWithTermsAccepted(true)
      updateUser({ key: 'termsAccepted', value: true }, true)
      return startNewTrial(parseFormValuesToSubmitData(values, user.language)).then((communityGamesResponse) => {
        if (communityGamesResponse.success) {
          if (user?.isSponsoredUser) {
            navigate(routes.homepage)
          } else {
            setSuggestedGames(
              parseLibraryGamesResponseToGameCards(communityGamesResponse.value, LibrarySource.COMMUNITY).slice(0, 2),
            )
            next()
          }
        } else {
          notifyError({
            title: t('onboarding_wizard.submit_error_notification.title', 'Failed to suggest games'),
            content: t(
              'onboarding_wizard.submit_error_notification.content',
              'Please try again or contact us for support',
            ),
          })
        }
      })
    } else {
      next()
    }
  }

  const handleCreateNewGame = () => {
    goToCreateGame()
  }

  const handleToDashboard = () => {
    navigate(routes.homepage)
  }

  const handleChangeLanguage = (selectOption: SingleValue<SelectOption>) => {
    updateUser({ key: 'language', value: selectOption?.value ?? LANGUAGE_SELECT_DEFAULT_OPTION.value })
  }

  const language =
    LANGUAGE_SELECT_OPTIONS.find((option) => option.value === user?.language) ?? LANGUAGE_SELECT_DEFAULT_OPTION

  return (
    <div className={styles.pageContainer}>
      <div className={styles.container}>
        <div className={styles.language}>
          {t('onboarding_wizard.language', 'Language')}
          {': '}
          <Select
            options={LANGUAGE_SELECT_OPTIONS}
            value={language}
            onChange={handleChangeLanguage}
            className={styles.languageSelect}
          />
        </div>

        <div className={styles.stepContentWrapper}>
          <div className={classNames(styles.wizardHeader)}>
            {stepIndex > 0 && (
              <Button onClick={previous} variant='borderless-normal'>
                {t('onboarding_wizard.buttons_text.back', 'Back')}
              </Button>
            )}
            <img
              src={logoUrl}
              alt='logo'
              height={26}
              className={classNames(styles.seppoLogo, stepIndex === 0 && styles.firstStepImageSpacing)}
            />
          </div>

          <Form<OnboardingForm> onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
            {({ handleSubmit, submitting, values }) => {
              return stepIndex <= FORM_STEPS.length - 1 ? (
                <form onSubmit={handleSubmit} className={styles.onboardingForm}>
                  {FORM_STEPS[stepIndex].component}
                  <div className={classNames(styles.buttonsContainer, shared.mAxisSA)}>
                    <Button variant='big' type='submit' disabled={stepIndex === FORM_STEPS.length - 1 && submitting}>
                      {stepIndex === FORM_STEPS.length - 1
                        ? submitting
                          ? t('onboarding_wizard.buttons_text.loading', 'Loading...')
                          : t('onboarding_wizard.buttons_text.submit', 'Submit')
                        : t('onboarding_wizard.buttons_text.continue', 'Continue')}
                    </Button>
                  </div>
                  <ValidationNotificationSpy />
                </form>
              ) : (
                <>
                  {!user?.isSponsoredUser && (
                    <>
                      <SuggestionStep games={suggestedGames} hasTemplates={values.organisationType === 'Business'} />
                      <div className={classNames(styles.suggestionStepButton, shared.mAxisSA)}>
                        <Button onClick={handleCreateNewGame} variant='outline-tiny'>
                          {getIcon('plus')}
                          {t('onboarding_wizard.suggestion_step.create_new_game', 'Create new game from scratch')}
                        </Button>
                      </div>
                    </>
                  )}
                  <div className={classNames(styles.suggestionStepButton, shared.mAxisSA)}>
                    <Button
                      style={{
                        textTransform: 'uppercase',
                        color: 'var(--grey-700)',
                        textDecoration: 'underline',
                      }}
                      onClick={handleToDashboard}
                      variant='borderless-normal'
                    >
                      {t('onboarding_wizard.suggestion_step.go_to_dashboard', 'Take me to the dashboard')}
                    </Button>
                  </div>
                </>
              )
            }}
          </Form>

          {!user.isSponsoredUser && (
            <p>
              {t('onboarding_wizard.step', 'Step')} {stepIndex + 1} / {TOTAL_STEPS_COUNT}
            </p>
          )}
        </div>
      </div>
      <div className={styles.signedInInfo}>
        {t('onboarding_wizard.signed_in_info', {
          defaultValue: 'Signed in as %{user_email}.',
          user_email: user.email,
        })}
        &nbsp;
        <button className={styles.logOutButton} onClick={doLogout}>
          {t('onboarding_wizard.log_out', 'Log out')}
        </button>
      </div>
    </div>
  )
}
