import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next'
import styles from './ShareLoginInfo.module.css'
import { getIcon } from '../../common/components/icons/utils'
import QRCode from 'qrcode.react'
import { useRef } from 'react'
import { Button } from '../../common/components/button/Button'
import i18n from '../../i18n'

type ShareLoginInfoProps = {
  pin: string | null
  title: string | null
}

export const ShareLoginInfo: React.FC<ShareLoginInfoProps> = ({ pin, title }) => {
  const ref = useRef(null)
  const { t } = useTranslation()

  const handleImageDownload = async () => {
    const element = ref.current
    if (element) {
      const canvas = await html2canvas(element)
      const data = canvas.toDataURL('image/jpg')
      downloadImage(data)
    }
  }

  const downloadImage = (blob: string) => {
    const fakeLink = window.document.createElement('a')
    fakeLink.download = 'shareGame.png'

    fakeLink.href = blob

    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)

    fakeLink.remove()
  }

  return (
    <div className={styles.backgroundContainer}>
      <div className={styles.contentContainer} ref={ref}>
        <div className={styles.logoContainer}>{getIcon('seppo')}</div>
        <div className={styles.pinContainer}>
          <div className={styles.pinTitle}>
            <span>{title}</span>
          </div>
          <div className={styles.pinContent}>
            <div className={styles.qrCode}>
              <div className={styles.pinCode}>{pin}</div>
              <div className={styles.playSeppo}>
                <span className={styles.pinIcon}>{getIcon('exercisePin')}</span> play.seppo.io
              </div>
            </div>
            <span className={styles.qr}>
              <QRCode
                value={`${window.location.protocol}//${window.location.host}/login?pin=${pin}&l=${i18n.language}`}
                renderAs='canvas'
                size={175}
              />
            </span>
          </div>
        </div>
        <div className={styles.bottomBorder} />
      </div>
      <Button onClick={handleImageDownload}>
        {getIcon('download')} {t('share_game.download_image', 'Download image')}
      </Button>
    </div>
  )
}
