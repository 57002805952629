import * as React from 'react'
import { SVGProps } from 'react'
const SvgDownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 23 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.67677 8.71089L9.9899 11.7897V0.901127C9.9899 0.375469 10.4747 0 10.9596 0C11.4444 0 11.9293 0.450563 11.9293 0.901127V11.7897L15.2424 8.71089C15.404 8.5607 15.7273 8.41051 15.9697 8.41051C16.2121 8.41051 16.4545 8.48561 16.697 8.71089C17.101 9.08636 17.101 9.68711 16.697 10.0626L11.6869 14.7184C11.6465 14.7559 11.5859 14.7935 11.5253 14.831L11.5253 14.831C11.4646 14.8686 11.404 14.9061 11.3636 14.9437C11.1212 15.0188 10.798 15.0188 10.5556 14.9437C10.3939 14.8686 10.3131 14.7935 10.2323 14.7184L5.30303 10.0626C4.89899 9.68711 4.89899 9.08636 5.30303 8.71089C5.70707 8.33542 6.27273 8.33542 6.67677 8.71089ZM19.65 3.35596H17.775C17.25 3.35596 16.875 3.80596 16.875 4.25596C16.875 4.70596 17.325 5.15596 17.775 5.15596H19.65C20.175 5.15596 20.55 5.60596 20.55 6.05596V21.056C20.55 21.581 20.1 21.956 19.65 21.956H2.85C2.325 21.956 1.95 21.506 1.95 21.056V6.05596C1.95 5.53096 2.4 5.15596 2.85 5.15596H4.725C5.25 5.15596 5.625 4.70596 5.625 4.25596C5.625 3.80596 5.175 3.35596 4.725 3.35596H2.85C1.275 3.35596 0 4.63096 0 6.20596V21.206C0 22.706 1.275 23.981 2.85 23.981H19.725C21.3 23.981 22.575 22.706 22.575 21.131V6.13096C22.5 4.63096 21.225 3.35596 19.65 3.35596Z'
      fill={props.color || 'currentColor'}
    />
  </svg>
)
export default SvgDownloadIcon
