import jwtDecode from 'jwt-decode'
import { useCallback, useEffect, useState } from 'react'

// TODO: This is a test client_id - to be replaced with a real one
const CLIENT_ID = process.env.REACT_APP_GSI_CLIENT_ID!

const initializeGSI = (responseCallback: (resp: google.accounts.id.CredentialResponse) => void, retryCount = 3) => {
  /* global google */
  if (window.google) {
    window.google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: responseCallback,
    })
  } else {
    //NOTE! Sometimes got an error about google which caused the view not to render, thus this retry system
    setTimeout(() => {
      initializeGSI(responseCallback, retryCount - 1)
    }, 500)
  }
}

export const bindGSIButtonToElement = (root: HTMLElement) => {
  google.accounts.id.renderButton(root, { theme: 'outline', size: 'large', type: 'standard' })
}

export const useGoogleSignIn = () => {
  const [credentials, setCredentials] = useState<any>(null)
  const [rawToken, setRawToken] = useState<string | null>(null)

  const handleCallbackResponse = (response: google.accounts.id.CredentialResponse) => {
    setRawToken(response.credential)
    const userObject = jwtDecode(response.credential)
    setCredentials(userObject)
  }

  const clearState = useCallback(() => {
    setRawToken(null)
    setCredentials(null)
  }, [])

  useEffect(() => {
    initializeGSI(handleCallbackResponse)
  }, [])

  return {
    userCredentials: credentials,
    rawToken,
    clearState,
    bindToElement: bindGSIButtonToElement,
  }
}
