import { Modal } from '../../common/components/Modal/Modal'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../hooks/useTrapFocus'
import styles from './LoadingModal.module.css'
import { LoaderBlock } from '../../common/components/loaders/LoaderBlock/LoaderBlock'

type LoadingModalProps = {
  show: boolean
}

export const LoadingModal: React.FC<LoadingModalProps> = ({ show }) => {
  const modalRef = useTrapFocus<HTMLDivElement>(show)
  useDisableBodyScroll(show)

  if (!show) {
    return null
  }

  return (
    <Modal>
      <div ref={modalRef} className={styles.modalContainer}>
        <LoaderBlock variant='secondary' />
      </div>
    </Modal>
  )
}
