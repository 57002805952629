import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GameFilters } from '../../../contexts/OwnGamesContextProvider'
import styles from '../Search.module.css'
import { AddFilter } from './AddFilter'
import { CheckboxFilter } from './CheckboxFilter'
import { DateFilter } from './DateFilter'
import { SelectFilter } from './SelectFilter'
import { getAgeOptions, getLanguageOptions, getSubjectOptions } from './constants'
import { FilterKeys, OptionalFilterKeys, OptionalFiltersVisibility, UpdateFilter } from './types'

type FiltersProps = {
  onUpdateFilter: UpdateFilter
  initialFilters?: GameFilters
  showSubjectFilter?: boolean
  allowAddFilter?: boolean
  showDateFilter?: boolean
  allowRemoving?: boolean
}

export const Filters: React.FC<FiltersProps> = ({
  onUpdateFilter,
  initialFilters,
  showSubjectFilter,
  allowAddFilter = true,
  showDateFilter = true,
  allowRemoving = true,
}) => {
  const { t } = useTranslation()

  const [visibleOptionalFilters, setVisibleOptionalFilters] = useState<OptionalFiltersVisibility>({
    age: initialFilters?.age != null,
    approvedBySeppo: initialFilters?.approvedBySeppo != null,
    language: initialFilters?.language != null,
  })

  const handleFilterChange = useCallback(
    (filterKey: keyof FilterKeys) => (value: string | boolean | null) => {
      onUpdateFilter(filterKey, value)
    },
    [onUpdateFilter],
  )

  const toggleOptionalFilterVisibility = useCallback(
    (filterKey: keyof OptionalFilterKeys) => () => {
      setVisibleOptionalFilters((prev) => {
        return { ...prev, [filterKey]: !prev[filterKey] }
      })
    },
    [],
  )

  const handleAddFilter = useCallback(
    (filterKey: keyof OptionalFilterKeys) => {
      toggleOptionalFilterVisibility(filterKey)()
    },
    [toggleOptionalFilterVisibility],
  )

  return (
    <div className={styles.filtersContainer}>
      {showDateFilter && (
        <DateFilter
          icon='calendar'
          label={t('search_page.filter_label.updated_at', 'Updated at')}
          initialValue={initialFilters?.updatedAt}
          onChange={handleFilterChange('updatedAt')}
        />
      )}
      {showSubjectFilter && (
        <SelectFilter
          icon='textFile'
          label={t('search_page.filter_label.subject', 'Subject')}
          options={getSubjectOptions(t, true)}
          initialValue={initialFilters?.subject}
          onChange={handleFilterChange('subject')}
          isSearchable
        />
      )}
      {visibleOptionalFilters.age && (
        <SelectFilter
          icon='filter'
          label={t('search_page.filter_label.age', 'Age')}
          options={getAgeOptions(t, true)}
          initialValue={initialFilters?.age}
          onChange={handleFilterChange('age')}
          onRemove={allowRemoving ? toggleOptionalFilterVisibility('age') : undefined}
        />
      )}
      {visibleOptionalFilters.language && (
        <SelectFilter
          icon='language'
          label={t('search_page.filter_label.language', 'Language')}
          options={getLanguageOptions(t, true)}
          initialValue={initialFilters?.language}
          onChange={handleFilterChange('language')}
          onRemove={allowRemoving ? toggleOptionalFilterVisibility('language') : undefined}
          isSearchable
        />
      )}
      {visibleOptionalFilters.approvedBySeppo && (
        <CheckboxFilter
          label={t('search_page.filter_label.approved_by_seppo', 'Approved by Seppo')}
          initialValue={initialFilters?.approvedBySeppo != null}
          onChange={handleFilterChange('approvedBySeppo')}
          onRemove={allowRemoving ? toggleOptionalFilterVisibility('approvedBySeppo') : undefined}
        />
      )}
      {allowAddFilter && <AddFilter optionsVisibility={visibleOptionalFilters} onAddFilter={handleAddFilter} />}
    </div>
  )
}
