export enum LegacyEditorVersion {
  LEGACY_FULL = 'LEGACY_FULL',
  LEGACY_FRAME = 'LEGACY_FRAME',
}

export enum EditorPreference {
  LEGACY = 'LEGACY',
  NEW = 'NEW',
}

const LEGACY_EDITOR_VERSION_KEY = 'returnLegacyMode'
const PREFERS_LEGACY_EDITOR_KEY = 'preferLegacyEditor'
const OPEN_WIZARD_ON_GAME_OPEN = 'openWizardOnGameOpen'

export const setLegacyEditorVersion = (version: LegacyEditorVersion) => {
  window.localStorage.setItem(LEGACY_EDITOR_VERSION_KEY, version)
}

export const isLegacyEditorFullVersion = () => {
  return window.localStorage.getItem(LEGACY_EDITOR_VERSION_KEY) === LegacyEditorVersion.LEGACY_FULL
}

export const setEditorPreference = (preference: EditorPreference) => {
  window.localStorage.setItem(PREFERS_LEGACY_EDITOR_KEY, (preference === EditorPreference.LEGACY).toString())
}

export const prefersOldEditor = () => {
  return (
    !window.localStorage.getItem(PREFERS_LEGACY_EDITOR_KEY) ||
    window.localStorage.getItem(PREFERS_LEGACY_EDITOR_KEY) === 'true'
  )
}

export const setShouldOpenWizardOnGameOpen = (gameId: string) => {
  window.localStorage.setItem(OPEN_WIZARD_ON_GAME_OPEN, gameId)
}

export const shouldOpenWizardOnGameOpen = (gameId: string) => {
  return window.localStorage.getItem(OPEN_WIZARD_ON_GAME_OPEN) === gameId && !window.location.href.includes('legacy')
}
