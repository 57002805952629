import * as React from 'react'
import { SVGProps } from 'react'
const SvgWelcomeDay08Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 256.62 197.28' xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' {...props}>
    <path
      d='M243.58 1.57c-1.82-.51-3.97-.89-6.47-1.14-.79.48-29.73 2.08-86.82 4.79-60.32 2.87-91.36 4.37-93.11 4.51-11.17.96-19.67 2.26-25.5 3.92-6.23 1.77-10.96 4.35-14.19 7.74-3.04 3.19-5.4 7.82-7.09 13.89-1.18 4.23-2.57 11.68-4.19 22.36-.51 3.38-1.57 17.44-3.18 42.18C1.47 123.7.46 136.69 0 138.77v10.97c0 7.85 2.78 14.55 8.33 20.1 1.35 1.35 2.77 2.52 4.26 3.51v-3.72l.13.05c-.02-.14.04-1.34.16-3.6.16-2.83.37-5.69.62-8.56.79-8.96 1.81-15.98 3.08-21.06 2.42-9.66 6.89-17.23 13.43-22.73 2.05-1.72 4.17-3.13 6.38-4.22.9-.45 1.67-.78 2.31-1-.74-.54-1.66-1.36-2.75-2.45-5.6-5.59-8.4-12.34-8.4-20.25s2.8-14.66 8.4-20.27c5.6-5.59 12.35-8.38 20.25-8.38 1.18 0 2.33.06 3.45.19 6.44.7 12.05 3.43 16.82 8.19.32.31.63.64.94.98 4.96 5.4 7.44 11.83 7.44 19.29s-2.48 13.86-7.44 19.26l-.94.99c1.31-.04 3.11.11 5.4.44 3.75.56 6.94 1.51 9.57 2.85 6.19 3.13 13.42 8.74 21.7 16.83 4.17 4.08 7.77 7.92 10.79 11.54.65-.33 3.96-4.26 9.92-11.78.96-1.21 1.85-2.34 2.69-3.4l.04-.05s.02-.03.03-.04c1.42-1.8 5.55-6.7 12.39-14.7 6.67-7.8 10.31-12.01 10.91-12.62 1.21-1.23 2.37-2.07 3.47-2.52 1.03-.43 1.84-.46 2.42-.08.59.39.82 1.12.7 2.19-.14 1.18-.71 2.59-1.7 4.25-1.07 1.77-2.87 4.64-5.42 8.62-2.33 3.62-3.53 5.47-3.59 5.57.4.19 1.1.55 2.12 1.08 1.01.52 1.59.83 1.74.93 3.8 2.42 4.25 6.98 1.33 13.7a43.955 43.955 0 0 1-5.54 9.37c-.02.02-.03.03-.04.05-.02.02-.03.03-.04.05h-.01c-.03.05-.07.1-.1.15-2.95 3.75-6.42 7.52-10.4 11.31-7.96 7.61-14.5 11.56-19.63 11.84-5.14.28-11.25-1.7-18.33-5.94-2.3-1.38-4.54-2.9-6.72-4.55-1.59-1.22-2.56-2.02-2.9-2.4.52 2.71 1.06 7.25 1.64 13.62.38 4.18.66 7.91.85 11.2.08 1.63.15 3.16.19 4.58h128.24c7.6.03 13.44-.52 17.53-1.65 3.86-1.07 6.61-2.82 8.24-5.22 1.38-2.03 2.2-4.99 2.47-8.89.1-1.43.16-3.38.16-5.86.02-4.3.03-6.57.03-6.81V30.87c-.05-6.52-.34-11.4-.86-14.65-.66-4.11-1.88-7.28-3.67-9.51-1.9-2.38-4.74-4.1-8.51-5.14'
      fill='var(--welcome-block-dark)'
    />
    <path
      d='M108.02 64.66c4.06-.12 13.05-.19 26.97-.23 16.33-.08 30.47-.03 42.45.13 5.19.02 9.31.4 12.36 1.14 2.7.68 4.13 1.5 4.3 2.46.15.96-.95 1.8-3.3 2.54-2.61.79-6.11 1.26-10.5 1.41-32.51.85-56.37.86-71.59.03-3.01-.14-5.36-.61-7.04-1.43-1.49-.7-2.22-1.52-2.18-2.46.05-.92.8-1.72 2.26-2.4 1.63-.74 3.72-1.13 6.26-1.18M185.87 99.3c.67-.69 1.4-1.37 2.21-2.03 4.9-4.03 11.41-6.73 19.56-8.09 3.71-.63 7.71-.56 12 .23 4.89.9 9.3 2.61 13.25 5.11 4.59 2.93 8.18 6.76 10.78 11.48 2.98 5.43 4.5 11.85 4.57 19.28.04 3.93-.47 7.89-1.55 11.87-1.32 4.88-3.34 9.17-6.07 12.88-4.18 5.66-9.67 9.61-16.47 11.83-4.84 1.58-10.34 2.29-16.5 2.13-5.29-.12-10.13-1.07-14.51-2.87a36.002 36.002 0 0 1-8.04-4.57c-1.45-1.1-2.83-2.31-4.16-3.64-7.37-7.36-11.06-16.26-11.06-26.7 0-6.77 1.56-12.9 4.67-18.38 0 .09-.22 1.67-.65 4.73-.06.63-.11 1.25-.15 1.88-.21 3.07-.21 6.08.01 9.03.84 11.28 4.62 19.8 11.34 25.57 2.89 2.49 6.01 4.39 9.35 5.69 3.6 1.41 7.53 2.16 11.78 2.24 11.68.28 20.51-3.14 26.51-10.25 2.28-2.71 4.06-5.87 5.34-9.49 1.01-2.88 1.64-5.82 1.88-8.81.75-9.44-2.23-17.38-8.95-23.8-7.55-7.19-17.84-9.37-30.88-6.52-4.19.92-8.35 2.3-12.5 4.13-3.18 1.41-4.96 2.35-5.34 2.8.14-.86.65-1.93 1.52-3.2.6-.88 1.3-1.73 2.09-2.56M166.63 32.88c-18.84.44-38.1.38-57.8-.19-3.02-.07-5.39-.48-7.11-1.24-1.5-.67-2.25-1.47-2.23-2.41.03-.92.77-1.74 2.21-2.46 1.6-.77 3.68-1.21 6.24-1.33 6.18-.32 23.92-.71 53.23-1.15 8.77-.14 26.57-.37 53.4-.71 5.21-.1 9.35.18 12.42.85 2.71.61 4.17 1.39 4.38 2.35.17.94-.91 1.81-3.25 2.61-2.56.85-6.05 1.41-10.48 1.66-19.99 1.02-36.98 1.7-50.99 2.03M166.6 52.78c-18.84.45-38.1.39-57.8-.18-3.02-.07-5.39-.49-7.11-1.26-1.5-.66-2.25-1.46-2.23-2.4.03-.92.77-1.74 2.21-2.46 1.6-.77 3.68-1.21 6.24-1.33 6.18-.32 23.92-.71 53.23-1.15 8.77-.14 26.57-.37 53.4-.71 5.21-.1 9.35.18 12.42.84 2.71.62 4.17 1.4 4.38 2.36.17.94-.91 1.81-3.25 2.61-2.56.85-6.05 1.41-10.48 1.66-19.99 1.02-36.98 1.7-50.99 2.02'
      fill='var(--welcome-block-dark)'
    />
    <path
      d='M220.99 111.92c.66-1.3 1.52-2.21 2.59-2.71.97-.46 1.9-.48 2.78-.08.89.41 1.46 1.18 1.71 2.28.27 1.23.05 2.69-.66 4.36-1.48 3.47-4.02 8.08-7.63 13.84-1.49 2.37-4.86 7.51-10.12 15.42-.34.54-.8.94-1.4 1.19-.59.25-1.2.3-1.81.16a2.89 2.89 0 0 1-1.56-.91l-15.85-17.94c-.55-.63-.79-1.34-.74-2.14.05-.82.38-1.5 1-2.05.61-.54 1.32-.78 2.14-.72.81.05 1.49.38 2.03.99l13.37 16.61 14.15-28.29'
      fill='var(--welcome-block-dark)'
    />
    <g fill='#fff'>
      <path d='M61.32 127.57c-1.86 11.58-3.5 22.62-4.92 33.11-.71 5.26-1.24 9.39-1.59 12.39 6.73 5.97 10.25 9.13 10.56 9.47h.01c.14-.21 1.62-2.12 4.45-5.73 1.42-1.8 2.83-3.58 4.22-5.35-.77-3.03-1.87-7.08-3.3-12.16-2.83-10.09-5.98-20.66-9.45-31.73M59.66 57.35a29.92 29.92 0 0 0-3.45-.19c-7.91 0-14.66 2.79-20.25 8.38-5.6 5.61-8.4 12.36-8.4 20.27s2.8 14.66 8.4 20.25c1.09 1.09 2.01 1.91 2.75 2.45.89.64 1.87 1.27 2.95 1.88 1.07.61 2.24 1.2 3.51 1.76 1.44.66 3.54 1.56 6.32 2.69 2.33 1.02 4.18 2.04 5.53 3.04 1.77 1.31 3.14 2.89 4.12 4.73 0 .02.15-.25.42-.79.47-.98.99-1.86 1.56-2.63 1.54-2.08 3.77-4.45 6.71-7.11 4.39-3.97 6.6-5.98 6.65-6.02l.94-.99c4.96-5.4 7.44-11.82 7.44-19.26s-2.48-13.89-7.44-19.29c-.3-.34-.62-.66-.94-.98-4.77-4.76-10.37-7.49-16.82-8.19M164.8 98.98c.99-1.66 1.56-3.07 1.7-4.25.13-1.07-.11-1.81-.7-2.19-.58-.38-1.39-.36-2.42.08-1.11.46-2.27 1.3-3.47 2.52-.6.61-4.24 4.81-10.91 12.62-6.84 8-10.97 12.9-12.39 14.7 0 0-.02.02-.03.04l-.04.05c.9.34 1.65.63 2.23.86 1.24.51 2.35 1.01 3.32 1.52 4.16 2.17 7.68 5.21 10.56 9.11 1.02 1.4 1.92 2.84 2.69 4.32h.01s.02-.05.04-.06c0-.02.02-.03.04-.05 2.23-2.89 4.08-6.02 5.54-9.37 2.92-6.71 2.47-11.28-1.33-13.7-.14-.09-.72-.4-1.74-.93-1.01-.53-1.72-.89-2.12-1.08.06-.09 1.26-1.95 3.59-5.57 2.54-3.98 4.35-6.86 5.42-8.62' />
      <path d='M161.2 98.84s-.01.08-.01.11l-.1-.1h-.01s.08-.01.13-.01M157.18 114.68h.02-.03M223.58 109.2c-1.07.51-1.93 1.41-2.59 2.71l-14.15 28.29-13.37-16.61c-.54-.61-1.22-.94-2.03-.99-.82-.06-1.53.18-2.14.72-.62.55-.95 1.23-1 2.05-.06.8.19 1.52.74 2.14l15.85 17.94c.42.47.94.78 1.56.91.62.14 1.22.08 1.81-.16.59-.25 1.06-.65 1.4-1.19 5.26-7.91 8.63-13.05 10.12-15.42 3.61-5.76 6.15-10.37 7.63-13.84.71-1.67.93-3.13.66-4.36-.25-1.11-.82-1.87-1.71-2.28-.88-.41-1.81-.38-2.78.08M108.8 52.6c19.7.57 38.97.63 57.8.18 14.01-.32 31.01-.99 50.99-2.02 4.42-.25 7.91-.81 10.48-1.66 2.33-.8 3.42-1.67 3.25-2.61-.2-.96-1.66-1.74-4.38-2.36-3.07-.66-7.21-.94-12.42-.84-26.83.34-44.64.57-53.4.71-29.3.45-47.05.83-53.23 1.15-2.56.12-4.64.56-6.24 1.33-1.44.72-2.17 1.54-2.21 2.46-.02.94.73 1.74 2.23 2.4 1.72.77 4.1 1.19 7.11 1.26M108.82 32.69c19.7.57 38.97.63 57.8.19 14.01-.33 31.01-1.01 50.99-2.03 4.42-.25 7.91-.81 10.48-1.66 2.33-.8 3.42-1.67 3.25-2.61-.2-.96-1.66-1.74-4.38-2.35-3.07-.67-7.21-.95-12.42-.85-26.83.34-44.64.57-53.4.71-29.3.45-47.05.83-53.23 1.15-2.56.12-4.64.56-6.24 1.33-1.44.72-2.17 1.54-2.21 2.46-.02.94.73 1.74 2.23 2.41 1.72.76 4.1 1.18 7.11 1.24' />
      <path d='M188.07 97.27c-.8.66-1.54 1.34-2.21 2.03-.79.83-1.49 1.68-2.09 2.56-.87 1.27-1.38 2.33-1.52 3.2.38-.46 2.16-1.39 5.34-2.8 4.15-1.83 8.32-3.21 12.5-4.13 13.04-2.85 23.33-.68 30.88 6.52 6.72 6.43 9.71 14.36 8.95 23.8-.24 2.99-.86 5.93-1.88 8.81-1.28 3.62-3.06 6.78-5.34 9.49-5.99 7.11-14.83 10.53-26.51 10.25-4.25-.08-8.18-.83-11.78-2.24-3.34-1.3-6.46-3.2-9.35-5.69-6.72-5.77-10.5-14.29-11.34-25.57-.22-2.95-.22-5.96-.01-9.03.04-.63.09-1.25.15-1.88.42-3.06.64-4.64.65-4.73-3.11 5.48-4.67 11.6-4.67 18.38 0 10.43 3.69 19.33 11.06 26.7 1.33 1.33 2.71 2.54 4.16 3.64 2.5 1.88 5.18 3.4 8.04 4.57 4.38 1.79 9.22 2.75 14.51 2.87 6.16.16 11.66-.55 16.5-2.13 6.81-2.22 12.3-6.17 16.47-11.83 2.73-3.71 4.76-8.01 6.07-12.88 1.07-3.98 1.59-7.94 1.55-11.87-.07-7.42-1.59-13.85-4.57-19.28-2.6-4.72-6.19-8.54-10.78-11.48-3.95-2.5-8.37-4.21-13.25-5.11-4.29-.79-8.29-.86-12-.23-8.14 1.36-14.66 4.06-19.56 8.09M135 64.44c-13.92.03-22.92.11-26.97.23-2.54.05-4.63.44-6.26 1.18-1.45.68-2.21 1.48-2.26 2.4-.03.94.69 1.76 2.18 2.46 1.68.82 4.03 1.3 7.04 1.43 15.22.84 39.08.83 71.59-.03 4.39-.15 7.89-.62 10.5-1.41 2.35-.74 3.45-1.58 3.3-2.54-.17-.96-1.6-1.78-4.3-2.46-3.05-.74-7.17-1.12-12.36-1.14-11.97-.16-26.12-.2-42.45-.13' />
    </g>
    <path
      d='M38.71 108.51c-.63.22-1.4.55-2.31 1-2.21 1.09-4.33 2.5-6.38 4.22-6.54 5.5-11.01 13.07-13.43 22.73-1.27 5.08-2.3 12.1-3.08 21.06-.25 2.87-.46 5.73-.62 8.56-.13 2.26-.18 3.46-.16 3.6-.04-.02-.08-.03-.13-.05v6.87c.02 5.4.79 9.51 2.33 12.33 1.67 3.08 4.37 4.92 8.12 5.52 3.28.53 6.89.85 10.83.94-.24-4.33-.22-8.67.06-13.04.19-3.53.35-6.18.46-7.94.19-3.1.4-5.73.63-7.89.36-3.11.84-6.26 1.46-9.46.54-2.56.81-3.86.81-3.88 0 .3-.16 4.92-.49 13.86-.3 7.84-.38 12.88-.27 15.12.16 2.97.33 5.17.51 6.61.24 1.84.58 3.28 1.04 4.3.48 1.08 1.17 1.9 2.07 2.46.86.53 2.06.9 3.59 1.09 1.35.17 2.77.3 4.26.39 5.33.36 12.31.46 20.94.3 13.38-.23 22.4-1.25 27.05-3.07 1.81-.71 2.98-2.45 3.51-5.21.41-2.1.54-5.69.41-10.77h.04c-.04-1.42-.11-2.95-.19-4.58-.19-3.29-.47-7.02-.85-11.2-.57-6.37-1.12-10.91-1.64-13.62.35.38 1.31 1.18 2.9 2.4 2.18 1.66 4.42 3.17 6.72 4.55 7.08 4.24 13.18 6.21 18.33 5.94 5.13-.29 11.68-4.24 19.63-11.84 3.98-3.8 7.45-7.57 10.4-11.31.03-.05.07-.1.1-.14-.77-1.49-1.67-2.93-2.69-4.32-2.88-3.9-6.4-6.93-10.56-9.11-.97-.51-2.08-1.01-3.32-1.52-.58-.24-1.33-.52-2.23-.86-.84 1.06-1.73 2.19-2.69 3.4-5.96 7.52-9.27 11.45-9.92 11.78-3.03-3.62-6.62-7.47-10.79-11.54-8.28-8.09-15.51-13.7-21.7-16.83-2.64-1.34-5.83-2.3-9.57-2.85-2.29-.34-4.09-.49-5.4-.44-.04.04-2.26 2.05-6.65 6.02-2.93 2.66-5.17 5.03-6.71 7.11-.57.77-1.09 1.64-1.56 2.63-.27.54-.41.8-.42.79-.98-1.84-2.35-3.42-4.12-4.73-1.35-1.01-3.2-2.02-5.53-3.04-2.77-1.13-4.88-2.03-6.32-2.69-1.27-.57-2.44-1.15-3.51-1.76-1.08-.61-2.07-1.23-2.95-1.88'
      fill='var(--welcome-block-light)'
    />
    <path
      d='M56.4 160.68c1.42-10.49 3.06-21.53 4.92-33.11 3.47 11.07 6.62 21.64 9.45 31.73 1.43 5.08 2.53 9.14 3.3 12.16-1.4 1.77-2.8 3.55-4.22 5.35-2.83 3.61-4.32 5.53-4.45 5.74h-.01c-.31-.36-3.83-3.52-10.56-9.49.35-3 .88-7.13 1.59-12.39'
      fill='#fff'
    />
    <path
      d='M237.11.43c-2.6-.28-5.58-.42-8.93-.43L28.43 2.43c-7.84 0-14.54 2.77-20.1 8.32h-.01C2.77 16.33 0 23.03 0 30.87v107.9c.46-2.08 1.48-15.06 3.03-38.95 1.61-24.74 2.68-38.8 3.18-42.18 1.61-10.68 3.01-18.13 4.19-22.36 1.69-6.07 4.05-10.7 7.09-13.89 3.23-3.39 7.96-5.97 14.19-7.74 5.83-1.66 14.33-2.96 25.5-3.92 1.75-.14 32.79-1.65 93.11-4.51C207.38 2.51 236.32.91 237.11.43'
      fill='var(--welcome-block-light)'
    />
  </svg>
)
export default SvgWelcomeDay08Icon
