import classNames from 'classnames'
import React from 'react'

import { getIcon, IconName } from '../icons/utils'
import styles from './RoundButton.module.css'

type RoundButtonVariant = 'normal' | 'tiny'
type RoundButtonColorVariant = 'pink' | 'white' | 'blue'

export type RoundButtonProps = React.HTMLProps<HTMLButtonElement> & {
  icon: IconName
  iconDirection?: 'right' | 'left' | 'up' | 'down'
  noHoverShadow?: boolean
  variant?: RoundButtonVariant
  color?: RoundButtonColorVariant
}

export const RoundButton: React.FC<RoundButtonProps> = ({
  icon,
  iconDirection = 'right',
  noHoverShadow,
  type = 'button',
  className,
  variant = 'normal',
  color = 'pink',
  ...props
}) => {
  return (
    <button
      type={type as 'button' | 'reset' | 'submit' | undefined}
      className={classNames(
        styles.roundButton,
        styles[`roundButton_${variant}`],
        styles[`roundButton_${color}`],
        noHoverShadow && styles.roundButton_noHoverShadow,
        iconDirection !== 'right' && styles[`rotate_${iconDirection}`],
        className,
      )}
      {...props}
    >
      <span>{getIcon(icon)}</span>
    </button>
  )
}
