import * as React from 'react'
import { SVGProps } from 'react'
const SvgTaskMatchPairsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 30 29' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 25.996a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-22a3 3 0 0 0-3-3h-8a3 3 0 0 0-3 3v22ZM23 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z'
      fill='currentColor'
    />
    <path d='M9 15a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z' fill='currentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 26a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v22Zm3 1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v22a1 1 0 0 1-1 1H3Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgTaskMatchPairsIcon
