import * as React from 'react'
import { SVGProps } from 'react'

const SvgMoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ transform: 'rotate(90deg)' }}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 16.03a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Zm4.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM23 16.03a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Zm4.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM16 11.53a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm-2.5 4.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z'
      fill={props.color ?? '#A3A3A3'}
    />
  </svg>
)

export default SvgMoreIcon
