import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { AnswerStateEnum, Evaluation } from '../../../../../../../types/commonTypes'
import { getTaskIcon } from '../../../../../../../util/game'
import { getTaskCardFocusableElementId } from '../../../../../helpers'
import { getShortAnswerStatusText } from '../../helpers'
import styles from './TaskForPlayer.module.css'
import { useCallback } from 'react'

type TaskForPlayerProps = {
  evaluation: Evaluation
  onSelectTask: (taskId: number) => void
  isSelected?: boolean
  noPointsGame?: boolean
  hasLevels?: boolean
  isCompact: boolean
}

export const TaskForPlayer: React.FC<TaskForPlayerProps> = ({
  evaluation,
  onSelectTask,
  isSelected,
  noPointsGame,
  hasLevels,
  isCompact,
}) => {
  const { t } = useTranslation()
  const { task, totalPoints, state } = evaluation

  const onSelectTaskInternal = useCallback(() => {
    onSelectTask(task.id)
  }, [onSelectTask, task.id])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>) => {
      if (e.key === 'Enter') {
        onSelectTaskInternal()
      }
    },
    [onSelectTaskInternal],
  )

  return (
    <li
      key={`taskForPlayer_${task.id}`}
      className={classNames(
        styles.taskForPlayerContainer,
        isCompact && styles.taskForPlayerContainer_compact,
        isSelected && styles.taskForPlayerContainer_selected,
        state === AnswerStateEnum.READY && styles.taskForPlayerContainer_ready,
        state === AnswerStateEnum.GRADED && styles.taskForPlayerContainer_graded,
        ![AnswerStateEnum.READY, AnswerStateEnum.GRADED].includes(state) && styles.taskForPlayerContainer_neutral,
      )}
      onClick={onSelectTaskInternal}
      id={getTaskCardFocusableElementId(task.id)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <span className={styles.iconContainer}>
        {state === AnswerStateEnum.TIME_FINISHED ? getIcon('timerGradient') : getTaskIcon(task, hasLevels)}
      </span>
      <div className={styles.nameAndDetails}>
        <span className={styles.taskName}>{task.name}</span>
        <div className={styles.details}>
          <span className={styles.statusAndTypePill}>{getShortAnswerStatusText(state, t)}</span>
          {!noPointsGame && [AnswerStateEnum.READY, AnswerStateEnum.GRADED].includes(state) && (
            <div className={styles.iconAndPointsContainer}>
              {getIcon('scoreGradient')}
              {state === AnswerStateEnum.GRADED ? totalPoints : '?'}
            </div>
          )}
        </div>
      </div>
    </li>
  )
}
