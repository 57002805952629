import { useTranslation } from 'react-i18next'
import styles from '../Sponsored.module.css'

export const SponsoredHeader = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <h1 className={styles.headerHeading}>{t('sponsored_games.header.title', 'Download sponsored Seppo games')}</h1>
        <div className={styles.headerIngress}>
          {t(
            'sponsored_games.header.ingress_1',
            'Dive into our magical collection of sponsored Seppo games! Browse through the library or search for a game using its activation code. Using a sponsored game does not require a valid Seppo license, so everyone can play!',
          )}
        </div>
        <div className={styles.headerText}>
          {t(
            'sponsored_games.header.text_1',
            'Already a Seppo user? Simply enter your Seppo email when downloading, and follow the next steps to log in to your account.',
          )}
        </div>
        <div className={styles.headerText}>
          {t(
            'sponsored_games.header.text_2',
            'P.S. You can usually find the games activation code on the game creator´s website.',
          )}
        </div>
      </div>
      <div className={styles.headerRight}>
        <div className={styles.headerNumbering}>
          <div className={styles.headerNumberingNumber}>1</div>
          <div className={styles.headerNumberingText}>
            {t('sponsored_games.header.number_1', 'Insert the game´s activation code to find the game.')}
          </div>
        </div>
        <div className={styles.headerNumbering}>
          <div className={styles.headerNumberingNumber}>2</div>
          <div className={styles.headerNumberingText}>
            {t('sponsored_games.header.number_2', 'Select to download the game.')}
          </div>
        </div>
        <div className={styles.headerNumbering}>
          <div className={styles.headerNumberingNumber}>3</div>
          <div className={styles.headerNumberingText}>
            {t('sponsored_games.header.number_3', 'Enter your details and follow the steps.')}
          </div>
        </div>
      </div>
    </div>
  )
}
