import * as React from 'react'
import { SVGProps } from 'react'
const SvgCalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M9.003 0a1 1 0 0 1 1 1v3h12V1a1 1 0 1 1 2 0v3h5a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3h-26a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h5V1a1 1 0 0 1 1-1zm13 6v2a1 1 0 1 0 2 0V6h5a1 1 0 0 1 1 1v5h-28V7a1 1 0 0 1 1-1h5v2a1 1 0 1 0 2 0V6zm8 8v15a1 1 0 0 1-1 1h-26a1 1 0 0 1-1-1V14z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
)
export default SvgCalendarIcon
