import * as React from 'react'
import { SVGProps } from 'react'
const SvgLongerArrowUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth={1.5}
    stroke='currentColor'
    className='longer_arrow_up_icon_svg__w-6 longer_arrow_up_icon_svg__h-6'
    width='1em'
    height='1em'
    {...props}
  >
    <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18' />
  </svg>
)
export default SvgLongerArrowUpIcon
