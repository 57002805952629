import classNames from 'classnames'
import React from 'react'
import { getIcon } from '../icons/utils'
import styles from './BackButton.module.css'
import { t } from 'i18next'

export const BackButton: React.FC<React.HTMLProps<HTMLButtonElement>> = ({ className, ...props }) => {
  return (
    <button className={classNames(styles.backButton, className)} {...props} type='button'>
      <div className={classNames(styles.backButtonContent, className)}>
        {getIcon('backIcon')}
        <div className={classNames(styles.backButtonText, className)}>{t('game_editor.back', 'Back')}</div>
      </div>
    </button>
  )
}
