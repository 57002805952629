import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import styles from './Search.module.css'
import { ALL_GAME_FILTER_KEYS_WITH_ACTIVATION } from '../../search/components/constants'
import { SearchInput } from './SearchInput'
import { GameFilters } from '../../../contexts/OwnGamesContextProvider'
import { RefineSearch } from '../../search/components/RefineSearch'

type SponsoredGamesSearchProps = {
  loading: boolean
  filters?: GameFilters
  setFilters?: (filters: GameFilters | Partial<GameFilters>, isPartial?: boolean) => void
  isInstructorView?: boolean
}

const getGameFiltersFromQuery = (searchParams: URLSearchParams): GameFilters => {
  return Array.from(searchParams.entries()).reduce((filters, [key, value]) => {
    return ALL_GAME_FILTER_KEYS_WITH_ACTIVATION.includes(key as keyof GameFilters)
      ? { ...filters, [key]: value }
      : filters
  }, {} as GameFilters)
}

export const SponsoredGamesSearch: React.FC<SponsoredGamesSearchProps> = ({
  loading,
  filters,
  setFilters,
  isInstructorView = false,
}) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [hasConsumedInitialUrlParams, setHasConsumedInitialUrlParams] = useState<boolean>(false)
  const [initialFilters, setInitialFilters] = useState<GameFilters>()

  useEffect(() => {
    if (!hasConsumedInitialUrlParams) {
      setInitialFilters(getGameFiltersFromQuery(searchParams))
      if (initialFilters && initialFilters.activationCode && initialFilters.activationCode != null) {
        const activationCode = initialFilters.activationCode.trim()
        setSearchParams((prev) => {
          prev.set('activationCode', activationCode)
          return prev
        })
      }

      setHasConsumedInitialUrlParams(true)
    }
  }, [hasConsumedInitialUrlParams, initialFilters, searchParams, setSearchParams])

  useEffect(() => {
    const filters = getGameFiltersFromQuery(searchParams)
    if (setFilters == null) {
      return
    }
    setTimeout(() => {
      if (setFilters) setFilters({ ...filters })
    }, 1000)
  }, [setFilters, searchParams])

  const applySearch = (value: string) => {
    if (value.trim() === '' || !value) {
      setSearchParams((prev) => {
        if (!prev.has('activationCode') || prev.get('activationCode')?.trim() === '') prev.delete('activationCode')
        else prev.set('activationCode', '')
        return prev
      })
    } else {
      setSearchParams((prev) => {
        prev.set('activationCode', value.trim())
        return prev
      })
    }
  }

  if (!hasConsumedInitialUrlParams) {
    return null
  }

  return (
    <div className={styles.mainContainer}>
      {!isInstructorView && (
        <p className={loading ? styles.loading : styles.notLoading}>
          {loading &&
            t('search_page.title_loading', {
              defaultValue: 'Loading games for %{search_term}',
              search_term: filters?.activationCode,
            })}
        </p>
      )}
      {isInstructorView && (
        <SearchInput initialValue={filters?.activationCode ? filters?.activationCode : ''} onChange={applySearch} />
      )}
      {!isInstructorView && (
        <RefineSearch
          initialValue={filters?.activationCode ? filters?.activationCode : ''}
          onChange={applySearch}
          placeholder={t('sponsored_games.games.search_placeholder', 'Activation code')}
          buttonText={t('sponsored_games.games.search', 'Search')}
          disableShortWords={false}
        />
      )}
    </div>
  )
}
