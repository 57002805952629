import { Trans, useTranslation } from 'react-i18next'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { Button } from '../../../common/components/button/Button'
import styles from './SponsoredGameDownloadForm.module.css'
import { FormErrorType, TGameCard, Try } from '../../../types/commonTypes'
import { areObjectsEqual, isEmpty } from '../../../util/functional'
import { Form } from 'react-final-form'
import classNames from 'classnames'
import { useState } from 'react'
import { ToggleFormField } from '../../../common/components/Form/ToggleFormField/ToggleFormField'
import { OnChange } from 'react-final-form-listeners'
import { GetSponsoredActivation } from '../../../api/gameTypes'
import { useUser } from '../../../contexts/userContext'

export type DownloadGameForm = {
  sponsorCode: string
  email: string
  organization: string
  acceptTerms: string
}

type SponsoredGamesDownloadFormProps = {
  sponsorCode?: string
  onClose: (hasChanges: boolean) => void
  activateGame: (formProps: DownloadGameForm, game: TGameCard) => Promise<Try<GetSponsoredActivation> | null>
  game: TGameCard
  isInstructorView: boolean
}

export type SponsorResponse = {
  email: string
  loginUrl: string
  msg: string
  product: string
  success: boolean
  errorMessage: string
}

export const SponsoredGamesDownloadForm: React.FC<SponsoredGamesDownloadFormProps> = ({
  sponsorCode,
  onClose,
  activateGame,
  game,
  isInstructorView = false,
}) => {
  const { t } = useTranslation()
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)
  const [allowSubmitting, setAllowSubmitting] = useState<boolean>(false)
  const { user } = useUser()

  const initialValues = {
    sponsorCode: sponsorCode ? sponsorCode : '',
    email: user?.email ?? '',
    organization: '',
    acceptTerms: '',
  }

  const validate = (values: DownloadGameForm): FormErrorType<DownloadGameForm> => {
    const err: any = {}

    if (game.has_activation_code && (isEmpty(values.sponsorCode) || values.sponsorCode.length < 1)) {
      setAllowSubmitting(false)
      return {
        ...err,
        sponsorCode: t('sponsored_games.download_modal.sponsorcode_required_error', 'Sponsor code is required'),
      }
    }
    if (!isInstructorView && (isEmpty(values.email) || values.email.length < 1)) {
      setAllowSubmitting(false)
      return { ...err, email: t('sponsored_games.download_modal.email_required_error', 'Email is required') }
    }
    if (!(values.email ?? '').toString().includes('@')) {
      setAllowSubmitting(false)
      return { ...err, email: t('sponsored_games.download_modal.email_not_valid_error', 'Valid email is required') }
    }
    if (isEmpty(values.organization) || values.organization.length < 1) {
      setAllowSubmitting(false)
      return {
        ...err,
        organization: t('sponsored_games.download_modal.organization_required_error', 'Organization is required'),
      }
    }
    if (!acceptTerms) {
      setAllowSubmitting(false)
      return {
        ...err,
        acceptTerms: t('sponsored_games.download_modal.terms_required_error', 'Accepting terms is required'),
      }
    }
    setAllowSubmitting(true)
    return err
  }

  const onSubmit = async (values: DownloadGameForm, e: any) => {
    return activateGame(values, game)
  }

  return (
    <>
      <Form<DownloadGameForm> initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
        {({ initialValues, values, handleSubmit }) => (
          <form className={classNames(styles.downloadSponsoredForm)} onSubmit={handleSubmit}>
            <div className={styles.downloadSponsoredFormMainSection}>
              <>
                {game.has_activation_code && !initialValues.sponsorCode && (
                  <InputFormField
                    name='sponsorCode'
                    label={t('sponsored_games.download_modal.sponsorCode', 'Activation code')}
                    placeholder={t('sponsored_games.download_modal.sponsorCode_placeholder', 'Activation code')}
                    icon='code'
                    type='text'
                    srOnlyLabel
                    fieldContainerClassName={styles.nameInputContainer}
                    tabIndex={0}
                  />
                )}
                {(!user || !isInstructorView) && (
                  <InputFormField
                    name='email'
                    label={t('sponsored_games.download_modal.email', 'E-mail')}
                    placeholder={t('sponsored_games.download_modal.email_placeholder', 'E-mail')}
                    icon='email'
                    type='text'
                    disabled={!!user}
                    srOnlyLabel
                    fieldContainerClassName={styles.nameInputContainer}
                    tabIndex={0}
                  />
                )}
                <InputFormField
                  name='organization'
                  label={t('sponsored_games.download_modal.organization', 'Your school or organization')}
                  placeholder={t(
                    'sponsored_games.download_modal.organization_placeholder',
                    'Your school or organization',
                  )}
                  icon='organization'
                  type='text'
                  srOnlyLabel
                  fieldContainerClassName={styles.nameInputContainer}
                  tabIndex={0}
                />
              </>
              <div className={styles.toggleSwitchContainer}>
                <ToggleFormField
                  name='acceptTerms'
                  tabIndex={0}
                  label={
                    <div>
                      <span
                        className={
                          acceptTerms
                            ? classNames(styles.toggleSwitchLabel, styles.toggleSwitchLabelEnabled)
                            : styles.toggleSwitchLabel
                        }
                      >
                        {t('sponsored_games.download_modal.accept_terms', 'I accept the terms*')}
                      </span>
                      <span className={styles.toggleSwitchDescription}>
                        <br />
                        <Trans
                          i18nKey='sponsored_games.download_modal.accept_terms.description'
                          defaults='The game can be used only with the email address you give when downloading the game. The email address may be shared with the game sponsor. Original exercises of the game cannot be modified. Read our full <CustomLink href="https://play.seppo.io/docs/Terms_of_Use_2.1.pdf">Terms of Use</CustomLink>'
                          values={{ what: 'world' }}
                          components={{
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            CustomLink: <a href='https://play.seppo.io/docs/Terms_of_Use_2.1.pdf' />,
                          }}
                        />
                      </span>
                    </div>
                  }
                />
                <OnChange name='acceptTerms'>
                  {(value) => {
                    setAcceptTerms(value)
                  }}
                </OnChange>
              </div>
            </div>
            <div className={styles.downloadSponsoredFormFooter}>
              <Button
                variant='outline-normal'
                onClick={() => onClose(!areObjectsEqual(values, initialValues))}
                tabIndex={0}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button type='submit' disabled={!allowSubmitting} tabIndex={0}>
                {t('common.activate', 'Activate')}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </>
  )
}
