import { useState } from 'react'
import { DatePicker } from '../../common/components/DatePicker/DatePicker'
import { ToggleSwitch } from '../../common/components/ToggleSwitch/ToggleSwitch'
import { Button } from '../../common/components/button/Button'
import { useUser } from '../../contexts/userContext'
import { TUser } from '../../types/commonTypes'
import { HIDDEN_END_MESSAGES, HIDDEN_MESSAGES } from '../WelcomeBlock/constants'
import { getUserActiveDays } from '../WelcomeBlock/helpers'

export const DebugUserMessages: React.FC = () => {
  const { user, DEVupdateUser, refreshUser } = useUser()

  const [daysActiveInput, setDaysActiveInput] = useState<string>(
    getUserActiveDays(user?.createdAt ?? new Date().toISOString()).toFixed(2),
  )

  const handleChangeCreatedAt = () => {
    const daysActiveNumber = Number.parseFloat(Number.parseFloat(daysActiveInput).toFixed(2))
    if (isNaN(daysActiveNumber)) {
      alert('Days active should be a nice number!')
    } else {
      const daysActiveMs = daysActiveNumber * 24 * 60 * 60 * 1000
      const newCreatedAtMs = new Date().getTime() - daysActiveMs
      DEVupdateUser({ createdAt: new Date(newCreatedAtMs).toISOString() })
    }
  }

  const toggleUserAttribute = (key: keyof TUser) => () => {
    if (user != null) {
      DEVupdateUser({ [key]: !user[key] })
    }
  }

  const resetUx3Shown = () => {
    DEVupdateUser({ ux3Shown: false })
  }

  const undoMessageHiding = () => {
    localStorage.removeItem(HIDDEN_MESSAGES)
    localStorage.removeItem(HIDDEN_END_MESSAGES)
  }

  const [validUntil, setValidUntil] = useState<string | undefined>(user?.activeBusiness.validUntil)

  const updateValidUntil = () => {
    if (validUntil) {
      DEVupdateUser({
        activeBusiness: { ...user?.activeBusiness, validUntil: validUntil as any },
      } as any)
    }
  }

  return (
    <>
      <h5>Change user account age</h5>
      <p>Set number of active days, decimals are allowed</p>
      <div style={{ display: 'flex', gap: 8 }}>
        <input
          style={{ width: '6rem', height: 40 }}
          type='number'
          value={daysActiveInput}
          min={0}
          onChange={(e) => setDaysActiveInput(e.target.value)}
        />
        <Button variant='outline-tiny' onClick={handleChangeCreatedAt}>
          Set days
        </Button>
        <Button variant='outline-tiny' onClick={refreshUser}>
          Refresh user
        </Button>
      </div>
      <h5>Change user type</h5>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <p>Paying</p>
        <ToggleSwitch checked={user?.isTrial} onChange={toggleUserAttribute('isTrial')} />
        <p>Trial</p>
      </div>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <p>Corporate</p>
        <ToggleSwitch checked={user?.isEducation} onChange={toggleUserAttribute('isEducation')} />
        <p>Education</p>
      </div>
      <h5>Sponsored games user</h5>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <p>Is sponsored games user: </p>
        <ToggleSwitch checked={user?.isSponsoredUser} onChange={toggleUserAttribute('isSponsoredUser')} />
      </div>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <p>Has valid sponsored games: </p>
        <ToggleSwitch checked={user?.hasValidSponsored} onChange={toggleUserAttribute('hasValidSponsored')} />
      </div>
      <h5>Click to reset any closed messages</h5>
      <Button variant='tiny' onClick={undoMessageHiding} style={{ maxWidth: 'fit-content' }}>
        undo message closing
      </Button>
      <h5>Click to see welcome to ux3 again</h5>
      <Button variant='tiny' onClick={resetUx3Shown} style={{ maxWidth: 'fit-content' }}>
        gimme welcome to ux3
      </Button>
      <h5>Business valid until date</h5>
      <DatePicker
        date={validUntil != null ? new Date(validUntil) : null}
        onChangeDate={(value) => setValidUntil(value ?? undefined)}
        isClearable
        allowFuture
        todayButton
      />
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <Button variant='tiny' onClick={updateValidUntil}>
          Set valid until
        </Button>
        <Button variant='outline-tiny' onClick={refreshUser}>
          Refresh user
        </Button>
      </div>
    </>
  )
}
