import classNames from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { getIcon, IconName } from '../../../common/components/icons/utils'
import styles from '../Sidebar.module.css'

type SidebarLinkProps = {
  to: string
  title: string
  icon: IconName
}

export const SidebarLink: React.FC<SidebarLinkProps> = ({ to, icon, title }) => {
  return (
    <li>
      <NavLink
        to={to}
        role='link'
        className={({ isActive }) => classNames(styles.sidebarLink, isActive && styles.activeSidebarLink)}
      >
        {icon && <span className={styles.iconWrapper}>{getIcon(icon)}</span>}
        <span className={styles.linkTitle}>{title}</span>
      </NavLink>
    </li>
  )
}
