import * as React from 'react'
import { SVGProps } from 'react'
const SVGSeppoLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width='3.5em' height='1em' viewBox='0 0 90 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
      <path
        d='M89.9742 0H86.3192C84.3232 0 82.6902 1.63306 82.6902 3.65495C82.6902 5.67684 84.3233 7.30991 86.3451 7.30991C88.367 7.30991 90.0001 5.67684 90.0001 3.65495C90.0001 3.62903 89.9742 0 89.9742 0ZM86.3451 5.21025C85.4897 5.21025 84.7898 4.51037 84.7898 3.65495C84.7898 2.79954 85.4897 2.09965 86.3451 2.09965C86.4229 2.09965 86.5007 2.09965 86.5784 2.12558C86.4229 2.28111 86.3451 2.5144 86.3451 2.7477C86.3451 3.24021 86.7599 3.65495 87.2524 3.65495C87.4857 3.65495 87.719 3.55127 87.8745 3.42166C87.8745 3.49942 87.9004 3.57719 87.9004 3.65495C87.9004 4.51037 87.2006 5.21025 86.3451 5.21025Z'
        fill={props.color ?? '#CC3E76'}
      />
      <path
        d='M59.9567 5.4176C57.6238 5.4176 56.3018 6.53223 55.239 7.82832V5.75458H49.7695V25.8957H55.239V19.4153C56.2499 20.5818 57.6238 21.6446 59.9827 21.6446C63.6635 21.6446 66.8519 18.5599 66.8519 13.5052V13.4533C66.8519 8.47636 63.6635 5.4176 59.9567 5.4176ZM58.6607 17.186C56.6388 17.186 55.0057 15.553 55.0057 13.5311C55.0057 11.5092 56.6388 9.87613 58.6607 9.87613C60.6826 9.87613 62.3156 11.5092 62.3156 13.5311C62.3156 15.553 60.6566 17.186 58.6607 17.186Z'
        fill={props.color ?? '#CC3E76'}
      />
      <path
        d='M0 19.3117L2.04781 16.0456C3.83641 17.212 5.75461 17.8342 7.36175 17.8342C8.32085 17.8342 8.76152 17.549 8.76152 17.0047V16.9528C8.76152 16.3307 7.88018 16.0715 6.2212 15.6049C3.16244 14.7754 0.725806 13.7385 0.725806 10.6279V10.5761C0.725806 7.28404 3.36982 5.4436 6.947 5.4436C9.22811 5.4436 11.5611 6.09164 13.3497 7.25812L11.4833 10.7057C9.87615 9.79844 8.11348 9.22817 6.89516 9.22817C6.06567 9.22817 5.625 9.53923 5.625 10.0058V10.0577C5.625 10.6539 6.53226 10.939 8.16532 11.4315C11.25 12.2869 13.6866 13.3497 13.6866 16.3825V16.4344C13.6866 19.856 11.1204 21.6446 7.33583 21.6446C4.71774 21.6446 2.12558 20.867 0 19.3117Z'
        fill={props.color ?? '#CC3E76'}
      />
      <path
        d='M14.542 13.583V13.5311C14.542 8.99483 17.7822 5.39172 22.3963 5.39172C27.7361 5.39172 30.1987 9.33181 30.1987 13.894C30.1987 14.231 30.1987 14.6458 30.1728 15.0087H19.7782C20.2188 16.6936 21.4372 17.5749 23.148 17.5749C24.47 17.5749 25.4809 17.0824 26.5956 15.9937L29.6025 18.4822C28.099 20.3744 25.9475 21.6446 22.8888 21.6446C17.9896 21.6705 14.542 18.4044 14.542 13.583ZM25.118 12.235C24.9107 10.5242 23.8738 9.40958 22.3963 9.40958C20.9706 9.40958 19.9855 10.4983 19.7004 12.235H25.118Z'
        fill={props.color ?? '#CC3E76'}
      />
      <path
        d='M76.4948 5.00293C71.777 5.00293 67.9666 8.81341 67.9666 13.5312C67.9666 18.2489 71.777 22.0594 76.4948 22.0594C81.2125 22.0594 85.023 18.2489 85.023 13.5312C85.023 8.81341 81.2125 5.00293 76.4948 5.00293ZM76.4948 17.1861C74.4729 17.1861 72.8398 15.553 72.8398 13.5312C72.8398 11.5093 74.4729 9.8762 76.4948 9.8762C78.5167 9.8762 80.1497 11.5093 80.1497 13.5312C80.1497 15.553 78.5167 17.1861 76.4948 17.1861Z'
        fill={props.color ?? '#CC3E76'}
      />
      <path
        d='M41.6821 5.4176C39.3491 5.4176 38.0271 6.53223 36.9643 7.82832V5.75458H31.4949V25.8957H36.9643V19.4153C37.9753 20.5818 39.3491 21.6446 41.708 21.6446C45.3889 21.6446 48.5772 18.5599 48.5772 13.5052V13.4533C48.5772 8.47636 45.3889 5.4176 41.6821 5.4176ZM40.386 17.186C38.3641 17.186 36.731 15.553 36.731 13.5311C36.731 11.5092 38.3641 9.87613 40.386 9.87613C42.4079 9.87613 44.041 11.5092 44.041 13.5311C44.041 15.553 42.4079 17.186 40.386 17.186Z'
        fill={props.color ?? '#CC3E76'}
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='90' height='25.8957' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default SVGSeppoLogo
