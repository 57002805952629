import { PropsWithChildren } from 'react'
import { getIcon, IconName } from '../../../common/components/icons/utils'
import styles from './LoginCard.module.css'
import classNames from 'classnames'
import { noop } from '../../../util/functional'

type LoginCardVariant = 'oneCard' | 'twoCards'

type LoginCardProps = {
  icon: IconName
  iconLabel?: string
  title: string | undefined
  smallDeviceStyles?: boolean
  big?: boolean
  nonResponsiveBig?: boolean
  removeMinHeight?: boolean
  onBack?: () => void
  variant?: LoginCardVariant
  onBackDisabled?: boolean
}
export const LoginCard: React.FC<PropsWithChildren<LoginCardProps>> = ({
  children,
  icon,
  iconLabel,
  title,
  smallDeviceStyles,
  big,
  nonResponsiveBig,
  removeMinHeight,
  onBack = noop,
  variant = 'oneCard',
  onBackDisabled = false,
}) => {
  const internalOnBack = () => {
    if (onBackDisabled) {
      return
    }
    onBack()
  }

  return (
    <div
      className={classNames(
        styles.cardContainer,
        nonResponsiveBig && styles.cardContainerIgnoreResponsiveBig,
        big && styles.cardContainerBig,
        variant === 'twoCards' && styles.cardContainerTwoCards,
        smallDeviceStyles && styles.cardContainerSmallDevice,
        removeMinHeight && styles.removeMinHeight,
      )}
    >
      <div>
        <div className={classNames(styles.iconRow, iconLabel && styles.clickableRow)} onClick={internalOnBack}>
          <span
            className={classNames(
              styles.icon,
              (iconLabel || onBackDisabled) && styles.smallerIcon,
              smallDeviceStyles && styles.iconSmallDevice,
            )}
          >
            {getIcon(icon)}
          </span>
          <span>{iconLabel}</span>
        </div>
        {title && (
          <h1
            className={classNames(
              smallDeviceStyles && styles.titleSmalDevice,
              variant === 'twoCards' && styles.twoBoxesLoginLayoutTitle,
            )}
          >
            {title}
          </h1>
        )}
      </div>
      {children}
    </div>
  )
}
