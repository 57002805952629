import * as React from 'react'
import { SVGProps } from 'react'
const SvgAudioIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g fill='currentColor'>
      <path
        clipRule='evenodd'
        d='M14 0a5.266 5.266 0 0 0-5 5.527v8.947A5.266 5.266 0 0 0 14 20h4c.02 0 .04 0 .06-.002A5.266 5.266 0 0 0 23 14.473V5.527A5.266 5.266 0 0 0 18 0zm-3 12v2.5c0 .019 0 .038-.002.057A3.266 3.266 0 0 0 14.032 18h3.936A3.266 3.266 0 0 0 21 14.5V12h-3a1 1 0 1 1 0-2h3V8h-3a1 1 0 1 1 0-2h3v-.5c0-.019 0-.038.002-.057A3.266 3.266 0 0 0 17.968 2h-3.936A3.266 3.266 0 0 0 11 5.5V6h3a1 1 0 1 1 0 2h-3v2h3a1 1 0 1 1 0 2z'
        fillRule='evenodd'
      />
      <path d='M6 10a1 1 0 0 1 1 1v4a7 7 0 0 0 7 7h4a7 7 0 0 0 7-7v-4a1 1 0 1 1 2 0v4a9 9 0 0 1-9 9h-1v6h3a1 1 0 1 1 0 2h-8a1 1 0 1 1 0-2h3v-6h-1a9 9 0 0 1-9-9v-4a1 1 0 0 1 1-1z' />
    </g>
  </svg>
)
export default SvgAudioIcon
