import classNames from 'classnames'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useMemo, useRef } from 'react'
import { Marker } from 'react-leaflet'
import styles from './DraggableBoardThumbnail.module.css'
import { GameBoard } from '../../../types'
import { THUMBNAIL_BOTTOM, THUMBNAIL_IMAGE_SIZE, getX, getY } from './BoardsOverviewHelper'
import {
  getDoorsOKStateIcon,
  getNoDoorsFromOrToWarningIcon,
  getNoDoorsFromWarningIcon,
  getNoDoorsToWarningIcon,
  getNoTasksWarningIcon,
} from './BoardThumbnailStateIcons'
import { t } from 'i18next'

const getBoardDiv = (
  board: GameBoard,
  isActive: boolean,
  noTasks: boolean,
  noDoorsTo: boolean,
  noDoorsFrom: boolean,
) => {
  return new L.DivIcon({
    html:
      `<div class='${styles.boardThumbnailImageContainer}' id='boardThumbnail-${board.mapIndex}'>` +
      `<img src='${board.url}'/>` +
      `<div class='${styles.boardThumbnailIconRow}'>` +
      (noTasks ? getNoTasksWarningIcon() : '') +
      (noDoorsFrom && noDoorsTo ? getNoDoorsFromOrToWarningIcon() : '') +
      (noDoorsFrom && !noDoorsTo ? getNoDoorsFromWarningIcon() : '') +
      (!noDoorsFrom && noDoorsTo ? getNoDoorsToWarningIcon() : '') +
      (!noDoorsFrom && !noDoorsTo ? getDoorsOKStateIcon() : '') +
      '</div>' +
      '</div>' +
      `<div class='${styles.boardThumbnailName}'>${
        t('game_editor.game_boards.board', 'Board') + ((board.mapIndex ?? 0) + 1)
      }</div>`,
    iconSize: [THUMBNAIL_IMAGE_SIZE, THUMBNAIL_IMAGE_SIZE + THUMBNAIL_BOTTOM],
    iconAnchor: [THUMBNAIL_IMAGE_SIZE / 2, THUMBNAIL_IMAGE_SIZE + THUMBNAIL_BOTTOM],
    className: classNames(
      `boardThumbnail-${board.mapIndex}`,
      styles.boardThumbnail,
      isActive && styles.activeBoardThumbnail,
    ),
  })
}

type DraggableBoardThumbnailProps = {
  board: GameBoard
  isActive: boolean
  onActivate: (board?: GameBoard) => void
  onMove: (board: GameBoard, ux3X: number, ux3Y: number) => void
  onActivateBoardToEditor: (boardIndex: number) => void
  noTasks?: boolean
  noDoorsTo?: boolean
  noDoorsFrom?: boolean
}

export const DraggableBoardThumbnail: React.FC<DraggableBoardThumbnailProps> = ({
  board,
  isActive,
  onActivate,
  onMove,
  onActivateBoardToEditor,
  noTasks,
  noDoorsFrom,
  noDoorsTo,
}) => {
  const markerRef = useRef<L.Marker>(null)
  const eventHandlers = useMemo(
    () => ({
      mouseover(evt: any) {
        onActivate(board)
      },
      mouseout(evt: any) {
        if (!evt.originalEvent?.target?.className?.includes('boardStateIcon')) onActivate()
      },
      dragend(evt: any) {
        onMove(board, evt.target._latlng.lng, evt.target._latlng.lat)
      },
      click(evt: any) {
        onActivateBoardToEditor(board.mapIndex ?? 0)
      },
    }),
    [board, onActivate, onActivateBoardToEditor, onMove],
  )

  return (
    <Marker
      ref={markerRef}
      alt={board.mapIndex + ''}
      key={board.mapIndex}
      draggable={true}
      icon={getBoardDiv(board, isActive, !!noTasks, !!noDoorsTo, !!noDoorsFrom)}
      position={[getY(board), getX(board)]}
      eventHandlers={eventHandlers}
    />
  )
}
