import { ModalPortal } from '../../common/components/Portal/Portal'
import { ToggleSwitch } from '../../common/components/ToggleSwitch/ToggleSwitch'
import { Button } from '../../common/components/button/Button'
import { CloseButton } from '../../common/components/button/CloseButton'
import { useDebug } from '../../contexts/DebugContext'
import styles from './Debug.module.css'
import { DebugAllowedOptions } from './DebugAllowedOptions'
import { DebugEditorPermissions } from './DebugEditorPermissions'
import { DebugSection } from './DebugSection'
import { DebugTheme } from './DebugTheme'
import { DebugUserMessages } from './DebugUserMessages'

type DebugModalProps = {
  onClose: () => void
}

const showOutscoped = () => {
  try {
    for (let index = 0; index < document.styleSheets.length; index++) {
      try {
        const sheet = document.styleSheets.item(index)
        if (
          sheet != null &&
          sheet.cssRules.item(sheet.cssRules.length - 1)?.cssText.startsWith('.hide-outscoped-feature')
        ) {
          sheet.deleteRule(sheet.cssRules.length - 1)
          break
        }
        if (index === document.styleSheets.length - 1) {
          throw new Error('Failed to show outscoped features, tough luck.')
        }
      } catch (_) {}
    }
  } catch (error) {
    console.warn(error)
  }
}

const reload = () => {
  window.location.reload()
}

export const DebugModal: React.FC<DebugModalProps> = ({ onClose }) => {
  const { allowUseNewEditor, toggleAllowUseNewEditor } = useDebug()

  return (
    <ModalPortal>
      <div className={styles.backdrop}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div className={styles.modalHeaderTitle}>
              <span>DEBUG OPTIONS</span>
              <span className='tiny'>
                Visible only if development flag is true. Use these to test changes in UI, but keep in mind that nothing
                is actually changed in BE, aka it might not work the same as if it was really different. Use the reset
                buttons to undo changes, or just reload the page.
              </span>
            </div>
            <CloseButton onClick={onClose} />
          </div>

          <div className={styles.modalContent}>
            <DebugSection title='Game editor & friends' initialExpanded>
              <h5>Do you want to use the new game editor?</h5>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p>nah, too borked</p>
                <ToggleSwitch checked={allowUseNewEditor} onChange={toggleAllowUseNewEditor} />
                <p style={{ marginLeft: '18px' }}>hell yeah</p>
              </div>
              <h5>Click to show outscoped features</h5>
              <div style={{ display: 'flex', gap: 16 }}>
                <Button variant='tiny' onClick={showOutscoped}>
                  Show (hopefully)
                </Button>
                <Button variant='outline-tiny' onClick={reload}>
                  Reset (reload page)
                </Button>
              </div>
              <DebugEditorPermissions />
            </DebugSection>

            <DebugSection title='Theme settings'>
              <DebugTheme />
            </DebugSection>

            <DebugSection title='User - Welcome messages & account age'>
              <DebugUserMessages />
            </DebugSection>

            <DebugSection title='User - Allowed options'>
              <DebugAllowedOptions />
            </DebugSection>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
