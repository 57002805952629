import classNames from 'classnames'
import styles from './Banner.module.css'
import { PropsWithChildren } from 'react'

type BannerProps = {
  title?: string
  color?: 'primary' | 'secondary'
}

export const BannerWithChildren: React.FC<PropsWithChildren<BannerProps>> = ({
  title,
  color = 'primary',
  children,
}) => {
  return (
    <div className={classNames(color === 'primary' ? styles.container : styles.containerSecondary)}>
      <div className={styles.textAndActions}>
        <div className={styles.title}>{title}</div>
        <div className={styles.ContentContainer}>{children}</div>
      </div>
      {color === 'primary' && <div className={styles.firstCircle} />}
    </div>
  )
}
