import { useCallback } from 'react'
import FacebookLogin, { ReactFacebookLoginInfo, ReactFacebookFailureResponse } from 'react-facebook-login'
import { useUser } from '../contexts/userContext'

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID!

function isInfo(object: any): object is ReactFacebookLoginInfo {
  return 'userID' in object
}

export const useFacebookSignIn = () => {
  const { doLogin } = useUser()

  const facebookCallbackResponse = useCallback(
    (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
      if (isInfo(userInfo)) {
        doLogin(userInfo.email || '', '', undefined, userInfo.accessToken)
      } else {
        //if (DEBUG) console.log('FB login failed')
        //if (DEBUG) console.log(userInfo)
      }
    },
    [doLogin],
  )

  const renderFacebookButton = useCallback(() => {
    return (
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        fields='name,email,picture'
        buttonStyle={{ fontSize: '0.75rem', padding: '12px' }}
        callback={facebookCallbackResponse}
      />
    )
  }, [facebookCallbackResponse])

  return {
    renderFacebookButton,
  }
}
