import * as React from 'react'
import { SVGProps } from 'react'
const SvgEyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M30.222 14.35C26.872 10.667 21.43 6.917 16 7c-5.43-.084-10.872 3.666-14.218 7.35l-.003.002a3.015 3.015 0 0 0 0 4.037l.003.003C5.052 21.995 10.48 25.83 16 25.745c5.52.086 10.949-3.75 14.222-7.352l.003-.003a3.015 3.015 0 0 0 0-4.037zm-27.05 2.784a1.14 1.14 0 0 1-.001-1.525c3.203-3.524 8.173-6.811 12.813-6.733h.032c4.64-.078 9.61 3.209 12.817 6.733.39.434.39 1.09 0 1.525-3.138 3.453-8.094 6.816-12.817 6.737h-.032c-4.722.08-9.677-3.284-12.813-6.737zM16 12.624a3.747 3.747 0 0 1 3.749 3.747v.003a3.75 3.75 0 1 1-3.75-3.75zm5.623 3.746a5.624 5.624 0 1 0-11.248 0 5.624 5.624 0 0 0 11.248.003z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
)
export default SvgEyeIcon
