import React from 'react'
import { useTranslation } from 'react-i18next'
import { LibrarySource } from '../../../api/gameTypes'
import { GameAction, TGameCard } from '../../../types/commonTypes'
import { SimpleGameCard } from '../../GamesOverview/components/card/SimpleGameCard'
import styles from './GamePreviewModalSidebar.module.css'
import { SidebarActions } from './SidebarActions'
import { SidebarInfoList } from './SidebarInfoList'
import { SidebarInfoTabs } from './SidebarInfoTabs'
import { SidebarShowMore } from './SidebarShowMore'
import { getInfoListForGame, getInfoTabsForGame } from './helpers'

type GamePreviewModalSidebarProps = {
  game: TGameCard
  onGameAction: (action: GameAction) => void
  isSponsored?: boolean
  sponsorCode?: string | undefined
}

export const GamePreviewModalSidebar: React.FC<GamePreviewModalSidebarProps> = ({
  game,
  onGameAction,
  isSponsored = false,
  sponsorCode,
}) => {
  const { t } = useTranslation()
  const infoList = getInfoListForGame(game, t)
  const infoTabs = getInfoTabsForGame(game, t)
  const isLibrary = game.librarySource !== LibrarySource.OWN

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarCardContainer}>
        <SimpleGameCard game={game} isSponsored={isSponsored} />
      </div>
      <SidebarActions
        isSponsored={isSponsored}
        isLibrary={isLibrary}
        onGameAction={onGameAction}
        isExpired={game.expired}
        sponsorCode={sponsorCode}
        hasSponsorCode={game.has_activation_code}
      />
      <div>
        <SidebarInfoList infoList={infoList} isLibrary={isLibrary} />
        <SidebarShowMore
          subjects={game.subjects}
          description={game.description}
          ages={game.ages}
          language={game.language}
          tags={game.tags}
          isLibrary={isLibrary}
        />
        <div className={styles.sidebarDivider} />
        <SidebarInfoTabs tabs={infoTabs} isLibrary={isLibrary} />
      </div>
    </div>
  )
}
