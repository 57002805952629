import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../common/components/button/Button'
import { InputField } from '../../../common/components/input/InputField'
import styles from '../Search.module.css'

type RefineSearchProps = {
  initialValue: string
  onChange: (value: string) => void
  placeholder?: string
  buttonText?: string
  disableShortWords?: boolean
}

export const RefineSearch: React.FC<RefineSearchProps> = ({
  initialValue,
  onChange,
  placeholder = '',
  buttonText = '',
  disableShortWords = true,
}) => {
  const { t } = useTranslation()

  const [value, setValue] = useState<string>(initialValue)

  const handleInputChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.value
      setValue(newValue)
    },
    [setValue],
  )

  const handleRefineSearchClick = useCallback(() => {
    onChange(value)
  }, [onChange, value])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <div className={styles.refineSearchContainer}>
      <InputField onChange={handleInputChange} value={value} icon='search' placeholder={placeholder} />
      <Button onClick={handleRefineSearchClick} disabled={disableShortWords ? value.trim().length < 3 : false}>
        {buttonText !== '' ? buttonText : t('search_page.refine_search', 'Refine Search')}
      </Button>
    </div>
  )
}
