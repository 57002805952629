import L, { CRS } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MapContainer } from 'react-leaflet'
import { useGame } from '../../../../contexts/GameContext'
import { Task } from '../../../../types/commonTypes'
import { ConnectionLine } from '../Line/ConnectionLine'
import { DraggableMarker } from '../Marker/DraggableMarker'
import { BoardCoords, BoardImage } from './BoardImage'
import { GameBoard } from '../../types'
import { DraggableDoor } from '../Door/DraggableDoor'
import { TOOLTIP_EXPLICIT_CLOSE_CLASS_ID } from '../../../../common/components/tooltip/Tooltip'

type StaticBoardProps = {
  activeBoardIndex: number
  board: GameBoard
}

const emptyCoords = {
  xZero: 0,
  yZero: 0,
  xMax: window.innerWidth,
  yMax: window.innerHeight,
  xMultiplier: 0,
  yMultiplier: 0,
  imageLeftOffset: 0,
}

export const StaticBoard: React.FC<StaticBoardProps> = ({ activeBoardIndex, board }) => {
  const mapRef = useRef<L.Map>(null)
  const { tasks, taskConnections, gameData, isReachable } = useGame()
  const [boardCoords, setBoardCoors] = useState<BoardCoords>(emptyCoords)
  const [prevBoardUrl, setPrevBoardUrl] = useState<string | null>(null)
  const boardUrl = board.url
  const boardDimensions = board.dimensions

  const filteredTasks = useMemo(() => {
    return tasks.filter(
      (t) =>
        !(
          t.mapIndex !== activeBoardIndex ||
          t.advanced.isFlash ||
          (gameData?.advancedSettings.levelsEnabled && t.level === 0)
        ),
    )
  }, [tasks, gameData?.advancedSettings.levelsEnabled, activeBoardIndex])

  const tasksLookup = useMemo(() => {
    const tasksLookup: { [key: number]: Task } = {}
    filteredTasks.forEach((aTask) => (tasksLookup[aTask.id] = aTask))
    return tasksLookup
  }, [filteredTasks])

  useEffect(() => {
    setPrevBoardUrl(boardUrl)
  }, [boardUrl])

  const handleCoordsReady = useCallback((coords: BoardCoords) => {
    setBoardCoors(coords)
    if (mapRef.current) {
      mapRef.current.setView(
        [
          (coords.yMax - coords.yZero) / 2 + coords.yZero,
          (coords.xMax - coords.xZero - coords.imageLeftOffset) / 2 + coords.xZero,
        ],
        -1,
        { animate: false },
      )
      mapRef.current.addEventListener('mousemove', (e: any) => {
        const elements = document.getElementsByClassName(TOOLTIP_EXPLICIT_CLOSE_CLASS_ID)
        if (elements.length && e.originalEvent.target.classList.value.includes('leaflet-container')) {
          for (let index = 0; index < elements.length; index++) {
            const element = elements.item(index) as HTMLElement
            element?.click()
          }
        }
      })
    }
  }, [])

  const handleMapReady = useCallback(() => {
    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.addControl(L.control.zoom({ position: 'topright' }))
      }
    }, 0)
  }, [])

  return (
    <MapContainer
      ref={mapRef}
      style={{ height: 'calc(100vh - 5.25rem - 48px)', zIndex: 0, backgroundColor: 'transparent' }}
      zoom={0}
      bounds={[
        [boardCoords.xZero, boardCoords.yZero],
        [boardCoords.xMax, boardCoords.yMax],
      ]}
      minZoom={-5}
      crs={CRS.Simple}
      attributionControl={false}
      zoomControl={false}
      whenReady={handleMapReady}
    >
      {prevBoardUrl === boardUrl && (
        <BoardImage boardUrl={boardUrl} boardDimensions={boardDimensions} onCoordsReady={handleCoordsReady} />
      )}
      {filteredTasks.map((aTask) => {
        return (
          <DraggableMarker
            key={aTask.id}
            task={aTask}
            boardCoords={boardCoords}
            notReachable={!isReachable(aTask.id)}
          />
        )
      })}
      {gameData?.rootTaskId &&
        taskConnections.map((aConnection) => {
          return (
            <ConnectionLine
              key={JSON.stringify(aConnection)}
              taskFrom={tasksLookup[aConnection.fromId]}
              taskTo={tasksLookup[aConnection.toId]}
              boardCoords={boardCoords}
              notReachable={!isReachable(aConnection.fromId)}
            />
          )
        })}
      {board.doors?.map((door) => {
        return <DraggableDoor key={door.id} door={door} board={board} boardCoords={boardCoords} />
      })}
    </MapContainer>
  )
}
