import React, { useEffect, useRef, useState } from 'react'
import styles from './Sponsored.module.css'
import { GamesOverview } from '../../composites/GamesOverview/GamesOverview'
import classNames from 'classnames'
import { getFocusableGameItemId } from '../../composites/GamesOverview/helpers'
import { LegacyEditorVersion, setLegacyEditorVersion } from '../../util/editorPreference'
import { GameDataProviderInterface, GameFilters } from '../../contexts/OwnGamesContextProvider'
import { FilterKeys } from '../search/components/types'
import { Filters } from '../search/components/Filters'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from '../../hooks/useDebounce'
import { ALL_GAME_FILTER_KEYS, ALL_SELECT_OPTION_VALUE } from '../search/components/constants'
import { SponsoredGamesSearch } from './components/SponsoredGamesSearch'
import { useTranslation } from 'react-i18next'

const SPONSORED_FILTERS: GameFilters = {
  age: '',
  language: '',
}

const getGameFiltersFromQuery = (searchParams: URLSearchParams): GameFilters => {
  return Array.from(searchParams.entries()).reduce((filters, [key, value]) => {
    return ALL_GAME_FILTER_KEYS.includes(key as keyof GameFilters) ? { ...filters, [key]: value } : filters
  }, {} as GameFilters)
}

type SponsoredProps = {
  isInstructorView: boolean
  gameData: GameDataProviderInterface
}

export const Sponsored: React.FC<SponsoredProps> = ({ isInstructorView, gameData }) => {
  const lastCurrentPage = useRef<number>(gameData.currentPage)
  const [searchParams, setSearchParams] = useSearchParams()
  const debouncedSearchParams = useDebounce(searchParams, 750)

  const { setFilters } = gameData

  const [hasConsumedInitialUrlParams, setHasConsumedInitialUrlParams] = useState<boolean>(false)
  const [initialFilters, setInitialFilters] = useState<GameFilters>(SPONSORED_FILTERS)

  const { t } = useTranslation()

  useEffect(() => {
    if (gameData.currentPage !== lastCurrentPage.current) {
      const firstGameElement = document.querySelector<HTMLElement>(`[id^=${getFocusableGameItemId('')}]`)
      firstGameElement?.focus({ preventScroll: true })
      if ((firstGameElement?.getBoundingClientRect().top ?? 0) < 0) {
        firstGameElement?.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' })
      }
    }
    lastCurrentPage.current = gameData.currentPage
  }, [gameData.currentPage])

  useEffect(() => {
    setLegacyEditorVersion(LegacyEditorVersion.LEGACY_FRAME)
  }, [])

  useEffect(() => {
    if (!hasConsumedInitialUrlParams) {
      const newFilters = getGameFiltersFromQuery(searchParams)
      newFilters.age = newFilters.age ? newFilters.age : ''
      newFilters.language = newFilters.language ? newFilters.language : ''
      setInitialFilters(newFilters)
      setHasConsumedInitialUrlParams(true)
    }
  }, [hasConsumedInitialUrlParams, searchParams])

  useEffect(() => {
    const filters = getGameFiltersFromQuery(debouncedSearchParams)
    if (filters.activationCode == null || filters.activationCode.trim().length < 3 || setFilters == null) {
      return
    }
    setFilters({ ...filters })
  }, [debouncedSearchParams, setFilters])

  const handleFilterChange = (filterKey: keyof FilterKeys, newValue: string | boolean | null) => {
    setSearchParams((prev) => {
      if (!newValue || newValue === ALL_SELECT_OPTION_VALUE) {
        if (prev.get(filterKey) != null) {
          prev.delete(filterKey)
        }
      } else {
        prev.set(filterKey, newValue.toString())
      }
      return prev
    })
  }

  if (!hasConsumedInitialUrlParams) {
    return null
  }

  return (
    <div className={classNames(isInstructorView ? styles.contentContainerInstructor : styles.contentContainer)}>
      {!isInstructorView && (
        <div className={styles.sponsoredSearchContainer}>
          <h1 style={{ paddingBottom: '1rem' }}>{t('sponsored_games.games.search_title', 'Search the library')}</h1>
          <SponsoredGamesSearch
            loading={gameData.loading}
            filters={gameData.filters}
            setFilters={gameData.setFilters}
          />
        </div>
      )}
      <GamesOverview
        filterSection={
          <Filters
            onUpdateFilter={handleFilterChange}
            initialFilters={initialFilters}
            showSubjectFilter={true}
            showDateFilter={false}
            allowAddFilter={false}
            allowRemoving={false}
          />
        }
        gameData={gameData}
        allowSorting={false}
        isInstructorView={isInstructorView}
        isSponsored={true}
      />
    </div>
  )
}
