import classNames from 'classnames'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { ArrowRightIcon } from '../../../../../common/components/icons'
import styles from './Accordion.module.css'

type AccordionProps = {
  title: string
  isOpen?: boolean
  hasAnyErrors: boolean
  forceExpandToken?: number
}

export const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({
  children,
  title,
  isOpen = false,
  hasAnyErrors,
  forceExpandToken,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen)

  const handleClickAccordion = useCallback(() => setIsAccordionOpen((prevState) => !prevState), [])

  useEffect(() => {
    if (forceExpandToken && hasAnyErrors) {
      setIsAccordionOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceExpandToken])

  return (
    <div className={styles.container}>
      <button type='button' className={styles.headerContainer} onClick={handleClickAccordion}>
        <span className={classNames(styles.accordionChevronWrapper, isAccordionOpen && styles.accordionOpen)}>
          <ArrowRightIcon color={'currentColor'} />
        </span>
        <h4 className={'normal'}>{title}</h4>
      </button>
      <div className={classNames(styles.accordionChildren, isAccordionOpen && styles.accordionChildren_visible)}>
        {children}
      </div>
    </div>
  )
}
