import * as React from 'react'
import { SVGProps } from 'react'
const SvgWandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 272.58 252.87' xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' {...props}>
    <g fill='var(--welcome-block-dark)'>
      <path d='M140.95 103.34c-.25 1.14-.5 2.37-.75 3.68-.41 2.26-.67 4.09-.77 5.5-.12 1.71-.08 3.52.1 5.44.14 1.44.36 2.99.67 4.65l29.13-30.97-.16-.65-.67-2.74-.66-2.63.05-.05-2.97-.77-2.73-.71-.63-.16-20.61 19.4M80.59 160.1c-.15.73-.3 1.46-.43 2.21-.89 4.95-1.37 10.16-1.43 15.61-.03 3.12.07 6.36.3 9.71l23.04-24.5 17.58-18.68-.04.04c-.28-2.83-.47-6.18-.58-10.03-.05-1.46-.08-2.6-.08-3.42 0-1.27.06-2.41.15-3.44.08-.9.21-1.92.41-3.06.05-.32.13-.74.23-1.26zM26.75 210.74l-.03.03c1 3.04 2.19 5.52 3.56 7.44.67.96 1.39 1.82 2.16 2.59 2.44 2.45 5.78 4.36 10.01 5.73h.01l15.55-16.54c-.65-4.24-1.01-8.54-1.09-12.88a93.72 93.72 0 0 1 1.07-15.74zM10.84 230.86c-1.07.05-2.13.25-3.17.58-1.55.5-2.91 1.28-4.08 2.33a10.783 10.783 0 0 0-2.78 3.99c-.66 1.68-.92 3.52-.77 5.52.29 4.06 2.01 6.85 5.16 8.37 1.64.79 3.68 1.19 6.13 1.2 4.49.03 7.62-1.69 9.4-5.14.63-1.25 1.05-2.65 1.24-4.19.08-.56.11-1.09.11-1.6-1.47-.26-2.82-.68-4.03-1.24-3.98-1.84-6.38-5.12-7.2-9.84' />
    </g>
    <path
      d='M171 52.4c-3.22-8.87-5.47-15.06-6.75-18.58.07 3.95.22 9.15.44 15.6.2 5.7.3 9.29.3 10.78-.4.33-3.81 2.3-10.25 5.91-6.79 3.8-10.85 5.94-12.19 6.44l33.29-5.99c-.56-2-2.18-6.72-4.86-14.17M192.79 54.78c.56.53 1.06 1.03 1.5 1.5.54-.5 6.34-6.02 17.4-16.56l-23.85 9.92c1.56 1.78 3.21 3.5 4.96 5.15'
      fill='#fff'
    />
    <g fill='var(--welcome-block-light)'>
      <path d='M140.2 107.01c.25-1.31.49-2.54.75-3.68l-21.2 19.95c-.1.52-.18.93-.23 1.26-.19 1.13-.33 2.15-.41 3.06a43.41 43.41 0 0 0-.15 3.44c0 .82.03 1.96.08 3.42.11 3.86.3 7.2.58 10.03l.04-.04 19.64-20.87s.02-.03.04-.04l.86-.93c-.31-1.67-.54-3.22-.67-4.65-.19-1.92-.22-3.73-.1-5.44.1-1.41.36-3.25.77-5.5M80.16 162.31c.14-.74.28-1.48.43-2.21l-22.61 21.27a94.783 94.783 0 0 0-1.07 15.74c.08 4.35.44 8.64 1.09 12.88l.62-.67 5-5.3L78 188.73l1.03-1.09c-.24-3.36-.34-6.59-.3-9.71.07-5.45.55-10.66 1.43-15.61M10.59 228.72c.05.74.14 1.46.25 2.14.82 4.72 3.22 8 7.2 9.84 1.22.57 2.56.98 4.03 1.24.4.07.8.13 1.2.18.95.12 2.15.18 3.6.19 3.5.02 6.45-.7 8.84-2.16 2.01-1.23 3.59-2.94 4.74-5.15.93-1.77 1.53-3.75 1.81-5.96.11-.9.17-1.74.18-2.54h-.01c-4.23-1.36-7.56-3.27-10.01-5.72-.77-.77-1.49-1.63-2.16-2.59-1.38-1.93-2.57-4.41-3.56-7.44l.03-.03c-1.74.03-3.45.31-5.12.85-.75.25-1.48.54-2.17.88-1.36.66-2.6 1.5-3.73 2.51a15.211 15.211 0 0 0-4.02 5.77c-.96 2.43-1.33 5.09-1.12 7.98M163.71 25.53c-.73 0-1.34.27-1.85.8-.51.52-.76 1.14-.75 1.88l.74 30.37-24.55 14.25c-.66.3-1.11.8-1.36 1.48s-.22 1.34.09 2c.3.65.79 1.1 1.47 1.36l24.06 6.25.63.16 2.73.71 2.97.77-.05.05.66 2.63.67 2.74.16.65 5.91 23.8c.25.68.71 1.17 1.37 1.47s1.33.33 2 .09c.68-.25 1.17-.71 1.48-1.36l14.55-24.27 30.39.75c.73 0 1.35-.25 1.88-.76.52-.52.78-1.13.79-1.85 0-.74-.26-1.36-.77-1.88l-20.67-20.77 11.25-27.68c.29-.68.29-1.35.01-2.03s-.76-1.15-1.43-1.42c-.67-.28-1.34-.28-2.03 0l-27.67 11.26L165.6 26.3c-.52-.52-1.14-.77-1.88-.77' />
      <path d='M194.29 56.27c-.44-.47-.94-.96-1.5-1.5a72.88 72.88 0 0 1-4.96-5.15l23.85-9.92c-11.06 10.54-16.86 16.06-17.4 16.56' />
    </g>
    <path
      d='M164.26 33.83c1.28 3.52 3.53 9.71 6.75 18.58 2.68 7.45 4.3 12.17 4.86 14.17l-33.29 5.99c1.34-.5 5.4-2.65 12.19-6.44 6.43-3.61 9.85-5.58 10.25-5.91 0-1.49-.1-5.08-.3-10.78-.23-6.45-.38-11.65-.44-15.6'
      fill='#fff'
    />
    <path
      d='M227.54 102.51c-.36.07-.66.29-.9.67l-8.17 12.63-15.03.76c-.45.03-.79.17-1.03.44-.25.27-.33.58-.23.93.1.36.35.66.75.89l12.86 7.61.75 14.46c.02.45.16.79.43 1.04s.58.31.94.2c.36-.1.65-.35.88-.74l7.25-12.28h.77l13.99-.15c.46 0 .82-.14 1.09-.39.26-.25.36-.56.28-.91s-.3-.65-.68-.9L229 118.69v-.82l-.14-14c0-.46-.14-.82-.41-1.08-.25-.26-.55-.36-.91-.28M250.78 24.71c-.41.14-.74.46-.96.95l-7.75 16.54-17.94 3.37c-.53.1-.92.34-1.15.71-.25.36-.3.74-.13 1.14.19.41.54.73 1.04.95l16.71 7.01 3.26 17.26c.11.53.35.93.71 1.18s.74.28 1.15.1c.41-.19.73-.53.94-1.04l6.7-15.94.91-.13 16.8-2.47c.55-.08.96-.3 1.23-.66.29-.35.35-.73.19-1.14-.14-.41-.46-.73-.95-.98l-16.35-7.66-.15-.98-2.46-16.8c-.08-.55-.3-.96-.66-1.24-.35-.27-.73-.33-1.14-.18M129.22.28c-.3.27-.47.65-.49 1.14L127.89 18l-13.94 9c-.41.28-.66.61-.74 1-.09.39 0 .72.3 1 .3.29.69.44 1.19.44l16.49.16 8.67 13.39c.27.42.6.67.99.75.4.09.74-.01 1.01-.32.28-.29.42-.68.43-1.18l.15-15.73.74-.43 13.29-7.85c.42-.26.69-.59.81-.98s.04-.73-.24-1.03c-.26-.3-.64-.46-1.14-.48l-16.38-.84-.46-.77L131.2.83c-.25-.42-.57-.69-.96-.8-.39-.12-.73-.04-1.03.23'
      fill='var(--welcome-block-light)'
    />
  </svg>
)
export default SvgWandIcon
