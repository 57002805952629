import * as React from 'react'
import { SVGProps } from 'react'

const SvgSponsored = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='25' height='29' viewBox='0 0 25 29' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.75038 0.97467C6.26974 -1.11815 2.46071 0.458896 2.18546 3.69273L2.17323 3.8364C2.04672 5.32272 3.02657 6.64801 4.42794 7H4C2 7 0 9 0 11V14C0 14.5523 0.447715 15 1 15V26C1 27.5 2.425 28.981 4 28.981H21C22.575 28.981 24 27.575 24 26V15C24.5523 15 25 14.5523 25 14V11.056C25 9 23 7 21 7H20.428C21.8294 6.64801 22.8093 5.32272 22.6828 3.8364L22.6705 3.69273C22.3953 0.458896 18.5862 -1.11815 16.1056 0.97467L12.4318 4.07411L12.428 4.07735L12.4242 4.07411L8.75038 0.97467ZM14 7.58629L13.8911 7.59555C13.756 7.60705 13.6255 7.60601 13.5 7.5937V27H21C21.45 27 22 26.525 22 26V15H14V13H23V11C23 10 22 9 21 9H14V8V7.58629ZM11.5 7.5743C11.3418 7.60149 11.1748 7.61041 11 7.59827V8V9H4C3 9 2 10 2 11.056V13H3H11V15H3V26C3 26.45 3.475 27 4 27H11.5V7.5743ZM4.17825 3.86235C4.31588 2.24543 6.2204 1.45691 7.46071 2.50332L11.1345 5.60276L5.07761 5.08723C4.52732 5.04039 4.11918 4.55631 4.16602 4.00602L4.17825 3.86235ZM20.6777 3.86235C20.5401 2.24543 18.6356 1.45691 17.3953 2.50332L13.7215 5.60276L19.7784 5.08723C20.3287 5.04039 20.7368 4.55631 20.69 4.00602L20.6777 3.86235Z'
      fill={props.color ?? '#CC3E76'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.75038 0.97467C6.26974 -1.11815 2.46071 0.458896 2.18546 3.69273L2.17323 3.8364C2.04672 5.32272 3.02657 6.64801 4.42794 7H4C2 7 0 9 0 11V14C0 14.5523 0.447715 15 1 15V26C1 27.5 2.425 28.981 4 28.981H21C22.575 28.981 24 27.575 24 26V15C24.5523 15 25 14.5523 25 14V11.056C25 9 23 7 21 7H20.428C21.8294 6.64801 22.8093 5.32272 22.6828 3.8364L22.6705 3.69273C22.3953 0.458896 18.5862 -1.11815 16.1056 0.97467L12.4318 4.07411L12.428 4.07735L12.4242 4.07411L8.75038 0.97467ZM14 7.58629L13.8911 7.59555C13.756 7.60705 13.6255 7.60601 13.5 7.5937V27H21C21.45 27 22 26.525 22 26V15H14V13H23V11C23 10 22 9 21 9H14V8V7.58629ZM11.5 7.5743C11.3418 7.60149 11.1748 7.61041 11 7.59827V8V9H4C3 9 2 10 2 11.056V13H3H11V15H3V26C3 26.45 3.475 27 4 27H11.5V7.5743ZM4.17825 3.86235C4.31588 2.24543 6.2204 1.45691 7.46071 2.50332L11.1345 5.60276L5.07761 5.08723C4.52732 5.04039 4.11918 4.55631 4.16602 4.00602L4.17825 3.86235ZM20.6777 3.86235C20.5401 2.24543 18.6356 1.45691 17.3953 2.50332L13.7215 5.60276L19.7784 5.08723C20.3287 5.04039 20.7368 4.55631 20.69 4.00602L20.6777 3.86235Z'
      fill='url(#paint0_linear_3408_266)'
      fillOpacity='0.25'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3408_266'
        x1='12.5'
        y1='0.0263672'
        x2='12.5'
        y2='28.981'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgSponsored
