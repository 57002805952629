import { Decorator, FormApi } from 'final-form'
import { ObjectAny } from '../types/commonTypes'

type Config<T> = {
  beforeSubmit?: (form: FormApi<T>) => void | boolean
  afterSubmitSucceeded?: (form: FormApi<T>) => void
  afterSubmitFailed?: (form: FormApi<T>) => void
}

const maybeAwait = (maybePromise: any, callback: any) => {
  if (maybePromise && typeof maybePromise.then === 'function') {
    // async
    maybePromise.then(callback)
  } else {
    // sync
    callback()
  }
}

// NOTE: taken from https://github.com/final-form/final-form-submit-listener
// it had no declaration file for types, so it was easier to just copy the functionality
export const createDecorator =
  <T extends ObjectAny>({ beforeSubmit, afterSubmitSucceeded, afterSubmitFailed }: Config<T>): Decorator<T> =>
  (form: FormApi<T>) => {
    const originalSubmit = form.submit

    form.submit = () => {
      if (beforeSubmit) {
        if (beforeSubmit(form) === false) {
          return
        }
      }
      const result = originalSubmit.call(form)
      if (afterSubmitSucceeded || afterSubmitFailed) {
        maybeAwait(result, () => {
          const { submitSucceeded, submitFailed } = form.getState()
          if (afterSubmitSucceeded && submitSucceeded) {
            afterSubmitSucceeded(form)
          }
          if (afterSubmitFailed && submitFailed) {
            afterSubmitFailed(form)
          }
        })
      }
      return result
    }

    return () => {
      form.submit = originalSubmit
    }
  }
