import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserContextType } from '../../contexts/userContext'
import { TUser } from '../../types/commonTypes'
import { isPastDate } from '../../util/date'
import { LicenceExpiredBanner, SponsoredGamesExpiredBanner } from './components/LicenceExpiredBanner'
import { NewAccountWelcomeBanner } from './components/NewAccountWelcomeBanner'
import { NewDashboardWelcomeBanner } from './components/NewDashboardWelcomeBanner'
import { TrialEndBanner } from './components/TrialEndBanner'
import { getUserActiveDays, welcomeBannerIconSelector, welcomeBlockDataSelector } from './helpers'
import { SEPPO_GROUPING_BUSINESS } from '../../api/typeConverters'
import { GenericDashboardBanner } from './components/GenericDashboardBanner'

type WelcomeBlockProps = Pick<UserContextType, 'updateUser'> & {
  user: TUser
}

export const WelcomeBlock: React.FC<WelcomeBlockProps> = ({ user, updateUser }) => {
  const { t } = useTranslation()

  const data = welcomeBlockDataSelector(user, t)
  const activeDays = getUserActiveDays(user.createdAt)

  const closeGenericMessage = (messageId: string) => {
    updateUser({ key: 'dashboardMessage', value: messageId })
  }

  const closeNewUiMessage = () => {
    updateUser({ key: 'ux3Shown', value: true })
  }

  if (user.isSponsoredUser) {
    if (!user.hasValidSponsored) {
      return <SponsoredGamesExpiredBanner />
    }
    // TODO: see if we want to stop showing after n days or not, if yes, n = ?
    // if (activeDays < 22) {
    //   return <NewAccountWelcomeBanner data={data} icon={welcomeBannerIconSelector(1)} language={user.language} />
    // }
  }

  if (user.activeBusiness.industry === SEPPO_GROUPING_BUSINESS) {
    // TODO: see if we want to stop showing after n days or not, if yes, n = ?
    // if (activeDays < 22) {
    //   return <NewAccountWelcomeBanner data={data} icon={welcomeBannerIconSelector(1)} language={user.language} />
    // }
  }

  if (
    user.activeBusiness.industry !== SEPPO_GROUPING_BUSINESS &&
    user.activeBusiness.validUntil != null &&
    isPastDate(user.activeBusiness.validUntil)
  ) {
    return (
      <LicenceExpiredBanner
        validUntil={user.activeBusiness.validUntil}
        hasValidSponsoredGames={user.hasValidSponsored}
      />
    )
  }

  if (user.dashboardMessage) {
    return <GenericDashboardBanner onClose={closeGenericMessage} />
  }

  if (
    !user.isTrial &&
    !user.isSponsoredUser &&
    user.activeBusiness.industry !== SEPPO_GROUPING_BUSINESS &&
    activeDays < 22
  ) {
    return <NewAccountWelcomeBanner data={data} icon={welcomeBannerIconSelector(activeDays)} language={user.language} />
  }

  if (user.isTrial) {
    return activeDays < 15 ? (
      <NewAccountWelcomeBanner data={data} icon={welcomeBannerIconSelector(activeDays)} language={user.language} />
    ) : (
      <TrialEndBanner data={data} />
    )
  }

  if (!user.ux3Shown) {
    return <NewDashboardWelcomeBanner onClose={closeNewUiMessage} />
  }

  return null
}
