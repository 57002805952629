import classNames from 'classnames'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../common/components/Modal/Modal'
import { Button } from '../../common/components/button/Button'
import { getIcon } from '../../common/components/icons/utils'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../hooks/useTrapFocus'
import styles from './ConfirmationDialog.module.css'
import { CONFIRMATION_DIALOG_ID_PREFIX } from './constants'

export type ConfirmationProps = {
  title: string | JSX.Element
  text?: string | JSX.Element
  confirmActionText?: string
  cancelActionText?: string
}

type ConfirmationDialogProps = {
  show: boolean
  confirmationProps: ConfirmationProps
  resolveConfirmation: (answer: boolean) => void
  showCancelButton?: boolean
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  show,
  confirmationProps,
  resolveConfirmation,
  showCancelButton = true,
}) => {
  const { t } = useTranslation()

  const resolveConfirmationInternalCreator = useCallback(
    (answer: boolean) => () => {
      resolveConfirmation(answer)
    },
    [resolveConfirmation],
  )
  const modalRef = useTrapFocus<HTMLDivElement>(show)
  useDisableBodyScroll(show)

  if (!show) {
    return null
  }

  return (
    <Modal>
      <div ref={modalRef} className={styles.modalContainer}>
        <h3>{confirmationProps.title}</h3>
        {confirmationProps.text && (
          <div className={classNames(styles.textContainer, 'light')}>{confirmationProps.text}</div>
        )}
        <div
          className={classNames(styles.actionsContainer, showCancelButton ? styles.doubleButton : styles.singleButton)}
        >
          {showCancelButton && (
            <Button
              id={`${CONFIRMATION_DIALOG_ID_PREFIX}_cancelButton`}
              variant='outline-normal'
              onClick={resolveConfirmationInternalCreator(false)}
              autoFocus
            >
              {getIcon('close')}
              {confirmationProps.cancelActionText ?? t('confirmation_dialog.default_cancel', 'Cancel')}
            </Button>
          )}
          <Button
            id={`${CONFIRMATION_DIALOG_ID_PREFIX}_confirmButton`}
            onClick={resolveConfirmationInternalCreator(true)}
          >
            {confirmationProps.confirmActionText ?? t('confirmation_dialog.default_confirm', 'Confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
