import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { toFailure, toSuccess, Try } from '../types/commonTypes'
import { createAxios } from './axios'
import { ImageUploadData, UploadImageParams, UploadMediaParams, UploadResourceResponse } from './fileUploadTypes'
import { TEST_USER_AUTHORIZATION } from './userApiService'

export const uploadTinymceImage = async ({
  authorization = TEST_USER_AUTHORIZATION,
  fileName,
  inputFile,
}: UploadImageParams): Promise<Try<UploadResourceResponse>> => {
  try {
    const formData = new FormData()
    formData.append('name', fileName)
    formData.append('file', inputFile)
    formData.append('hint', '')
    const { data } = await createAxios(authorization).post<ImageUploadData>('/tinymce_assets', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return toSuccess({ url: data.image.url })
  } catch (e: any) {
    return toFailure(e)
  }
}

export const uploadMedia = async ({ fileName, inputFile }: UploadMediaParams): Promise<Try<UploadResourceResponse>> => {
  try {
    const s3Client = new S3Client({
      region: process.env.REACT_APP_S3_REGION,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: process.env.REACT_APP_S3_REGION }),
        identityPoolId: process.env.REACT_APP_S3_MEDIA_BUCKET_IDENTITY_POOL_ID!,
      }),
      useAccelerateEndpoint: true,
    })
    const safeInputFileName = fileName.replace(/[^a-zA-Z0-9.]/g, '')
    const command = new PutObjectCommand({
      Body: inputFile,
      Bucket: process.env.REACT_APP_S3_MEDIA_BUCKET_NAME,
      Key: safeInputFileName,
      ACL: 'public-read',
      ContentType: inputFile.type,
    })
    await s3Client.send(command)
    return toSuccess({ url: `${process.env.REACT_APP_S3_MEDIA_UPLOADED_PATH}${safeInputFileName}` })
  } catch (e: any) {
    return toFailure(e)
  }
}
