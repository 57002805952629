import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '../../../../../../../types/commonTypes'
import { BadgeView } from '../../../../Badges/BadgeView/BadgeView'
import { SelectableBadge } from '../../../../Badges/BadgeView/SelectableBadge'
import { CollapsibleCardSection } from '../GradeAnswersPanel/components/CollapsibleCardSection'
import styles from './AwardBadge.module.css'

type AwardBadgeProps = {
  title: string
  badges: Badge[]
  automaticallyAwardedBadgeId?: number
  automaticallyAwardedBadgePoints?: number
  selectedBadgeId?: number
  setSelectedBadgeId?: (id: number) => void
}

export const AwardBadge: React.FC<AwardBadgeProps> = ({
  title,
  badges,
  automaticallyAwardedBadgeId,
  automaticallyAwardedBadgePoints,
  selectedBadgeId,
  setSelectedBadgeId,
}) => {
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState<boolean>(true)
  const toggleExpanded = () => setExpanded((prev) => !prev)

  const automaticallyAwardedBadge = badges.find((b) => b.id === automaticallyAwardedBadgeId)

  const onSelectBadge = (badge: Partial<Badge>) => {
    if (badge.id && setSelectedBadgeId) {
      setSelectedBadgeId(badge.id)
    }
  }

  return (
    <CollapsibleCardSection isExpanded={expanded} icon='badgeGradient' onToggleExpanded={toggleExpanded} title={title}>
      <div className={styles.awardBadgeContainer}>
        {automaticallyAwardedBadge != null ? (
          <>
            <span className='tiny bold grey-900'>
              {t(
                'game_editor.sidebar.award_badge.automatically_awarded_description',
                'Badge that will be automatically awarded',
              )}
            </span>
            <BadgeView badge={automaticallyAwardedBadge} points={automaticallyAwardedBadgePoints} />
          </>
        ) : badges.length === 0 ? (
          <span className={styles.noBadgesText}>
            {t('game_editor.sidebar.award_badge.no_badges_to_award', 'No badges left to award')}
          </span>
        ) : (
          <ul className={styles.badgeList}>
            {badges.map((badge) => (
              <li key={`awardBadge_${badge.id}`} className={styles.badgeListItem}>
                <SelectableBadge
                  badge={badge}
                  isSelected={selectedBadgeId === badge.id}
                  otherSelected={selectedBadgeId != null && selectedBadgeId !== badge.id}
                  showName
                  onSelect={onSelectBadge}
                  onDarkBackground
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </CollapsibleCardSection>
  )
}
