import * as React from 'react'
import { SVGProps } from 'react'
const SvgLevel3Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <linearGradient id='level_3_icon_svg__a' gradientUnits='userSpaceOnUse' x1={16} x2={16} y1={1} y2={31}>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm1.147 10.36c.981.16 1.733.528 2.256 1.104.522.565.784 1.27.784 2.112 0 .65-.171 1.253-.512 1.808-.342.544-.864.981-1.568 1.312-.694.33-1.547.496-2.56.496-.79 0-1.568-.101-2.336-.304-.758-.213-1.403-.512-1.936-.896l1.008-1.984c.426.32.917.57 1.472.752.565.17 1.141.256 1.728.256.65 0 1.162-.123 1.536-.368.373-.256.56-.613.56-1.072 0-.917-.699-1.376-2.096-1.376h-1.184v-1.712l2.304-2.608H11.84V6.8h7.84v1.68zm-12.81 7.79-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='currentColor'
      />
      <path
        d='M16.005 1C9.668 1 4.53 6.1 4.53 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.48 6.1 22.342 1 16.005 1zm1.147 10.36c.981.16 1.733.528 2.256 1.104.522.565.784 1.27.784 2.112 0 .65-.171 1.253-.512 1.808-.342.544-.864.981-1.568 1.312-.694.33-1.547.496-2.56.496-.79 0-1.568-.101-2.336-.304-.758-.213-1.403-.512-1.936-.896l1.008-1.984c.426.32.917.57 1.472.752.565.17 1.141.256 1.728.256.65 0 1.162-.123 1.536-.368.373-.256.56-.613.56-1.072 0-.917-.699-1.376-2.096-1.376h-1.184v-1.712l2.304-2.608H11.84V6.8h7.84v1.68zm-12.81 7.79-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
        fill='url(#level_3_icon_svg__a)'
        fillOpacity={0.25}
      />
    </g>
  </svg>
)
export default SvgLevel3Icon
