import { KeyboardEventHandler, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormatOptionLabelMeta,
  MenuPlacement,
  MultiValue,
  default as ReactSelect,
  SingleValue,
  StylesConfig,
  Theme,
  components,
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { IconName, getIcon } from '../icons/utils'

export enum SelectVariant {
  Small = 'small',
  Large = 'large',
  XLarge = 'xLarge',
}

interface BaseSelectProps<T extends string | number = string> {
  options: SelectOption<T>[]
  id?: string
  className?: string
  defaultValue?: SelectOption<T>
  disabled?: boolean
  variant?: SelectVariant
  isSearchable?: boolean
  isClearable?: boolean
  placeholder?: string
  autoFocus?: boolean
  menuPlacement?: MenuPlacement
  // NOTE: if passing in the prefix element, memoize it! Changing the prefix element recalculates ValueContainer.
  // If ValueContainer changes after selecting an option, it breaks onBlur when clicking outside the component.
  prefixElement?: SelectElement
}

export interface SelectProps<T extends string | number = string> extends BaseSelectProps<T> {
  value?: SelectOption<T>
  onChange?: (option: SingleValue<SelectOption<T>>) => void
}

export interface MultiSelectProps extends BaseSelectProps {
  value?: SelectOption[]
  onChange?: (options: MultiValue<SelectOption>) => void
}

export interface CreatableMultiSelectProps {
  value: SelectOption[]
  onChange: (options: MultiValue<SelectOption>) => void
  id?: string
  disabled?: boolean
  className?: string
  defaultValue?: SelectOption[]
  variant?: SelectVariant
  isClearable?: boolean
  placeholder?: string
}

export interface SelectElement {
  type: 'icon' | 'rawContent'
  icon?: {
    name: IconName
    customActiveColor?: string
  }
  rawContent?: string | JSX.Element
  styles?: React.CSSProperties
}

export interface SelectOption<T extends string | number = string> {
  value: T
  label: string
  prefixElement?: SelectElement
}

function getStyles<T extends boolean, K extends string | number = string>(
  variant: SelectVariant,
): StylesConfig<SelectOption<K>, T> {
  return {
    control: (styles) => ({
      ...styles,
      boxShadow: 'none',
      minHeight: variant === SelectVariant.XLarge ? '2.8125rem' : variant === SelectVariant.Large ? '2.5rem' : '2rem',
      fontSize: variant === SelectVariant.Small ? '0.875rem' : '1rem',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    valueContainer: (styles, props) => ({
      ...styles,
      padding: '0 8px',
      display: 'flex',
      alignItems: 'center',
      flexWrap: props.isMulti ? 'wrap' : 'nowrap',
    }),
    menu: (styles) => ({
      ...styles,
      overflow: 'hidden',
      boxShadow: '0rem 8px 16px -6px rgba(0, 0, 0, 0.1)',
      zIndex: 3,
    }),
    clearIndicator: (styles) => ({
      ...styles,
      padding: '0',
      cursor: 'pointer',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      cursor: 'pointer',
      padding: '0 8px',
    }),
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 9999,
    }),
  }
}

const createValueContainer = (prefixElement?: SelectElement) => (props: any) => {
  const { children, ...rest } = props
  const iconColor =
    prefixElement?.type === 'icon' && prefixElement?.icon != null
      ? props.hasValue && !props.disabled
        ? prefixElement.icon?.customActiveColor ?? 'var(--primary-normal)'
        : 'var(--grey-400)'
      : undefined
  return (
    <components.ValueContainer {...rest}>
      {prefixElement && (
        <span
          style={{
            color: iconColor,
            display: 'flex',
            alignItems: 'center',
            marginRight: 4,
            ...prefixElement.styles,
          }}
        >
          {prefixElement.type === 'icon' && prefixElement?.icon != null
            ? getIcon(prefixElement?.icon?.name)
            : prefixElement.rawContent}
        </span>
      )}
      {children}
    </components.ValueContainer>
  )
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        style={{
          rotate: props.selectProps.menuIsOpen ? '180deg' : '0deg',
          fontSize: '1.25rem',
          display: 'flex',
          alignItems: 'center',
          color: 'var(--grey-700)',
        }}
      >
        {getIcon('chevron')}
      </span>
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        style={{
          fontSize: '0.75rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {getIcon('close')}
      </span>
    </components.DropdownIndicator>
  )
}

function formatOptionLabel<T extends string | number = string>(
  { label, prefixElement }: SelectOption<T>,
  meta: FormatOptionLabelMeta<SelectOption<T>>,
) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {prefixElement && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            ...prefixElement.styles,
          }}
        >
          {prefixElement.type === 'icon' && prefixElement?.icon != null
            ? getIcon(prefixElement.icon.name)
            : prefixElement.rawContent}
        </span>
      )}
      {meta.context === 'value' ? (
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
        </span>
      ) : (
        label
      )}
    </div>
  )
}

const getTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral50: 'var(--grey-600)',
    primary: 'var(--primary-normal)',
    primary75: 'var(--primary-dark)',
    primary50: 'var(--primary-light)',
    primary25: 'var(--primary-background)',
  },
  borderRadius: 9,
})

export function Select<T extends string | number = string>({
  options,
  disabled = false,
  variant = SelectVariant.Large,
  prefixElement,
  isSearchable = false,
  autoFocus = false,
  placeholder,
  ...rest
}: SelectProps<T>) {
  const { t } = useTranslation()
  const ValueContainer = useMemo(() => createValueContainer(prefixElement), [prefixElement])

  return (
    <ReactSelect<SelectOption<T>>
      styles={getStyles<false, T>(variant)}
      theme={getTheme}
      components={{ ValueContainer, DropdownIndicator, ClearIndicator }}
      isDisabled={disabled}
      options={options}
      formatOptionLabel={formatOptionLabel<T>}
      isSearchable={isSearchable}
      autoFocus={autoFocus ?? false}
      menuPlacement='auto'
      placeholder={placeholder ?? t('common.select', 'Select')}
      menuPortalTarget={document.body}
      {...rest}
    />
  )
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  disabled = false,
  variant = SelectVariant.Large,
  prefixElement,
  isSearchable = false,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation()
  const ValueContainer = useMemo(() => createValueContainer(prefixElement), [prefixElement])

  return (
    <ReactSelect<SelectOption, true>
      styles={getStyles<true>(variant)}
      theme={getTheme}
      components={{ ValueContainer, DropdownIndicator, ClearIndicator }}
      isDisabled={disabled}
      options={options}
      isSearchable={isSearchable}
      formatOptionLabel={formatOptionLabel}
      menuPlacement='auto'
      isMulti
      placeholder={placeholder ?? t('common.select', 'Select')}
      {...rest}
    />
  )
}

export const CreatableMultiSelect: React.FC<CreatableMultiSelectProps> = ({
  disabled = false,
  variant = SelectVariant.Large,
  onChange,
  value,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState('')

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case ',':
      case 'Tab':
        const newValues = [...(value || []), { label: inputValue, value: inputValue }]
        onChange?.(newValues)
        setInputValue('')
        event.preventDefault()
        return
    }
  }

  return (
    <CreatableSelect<SelectOption, true>
      styles={getStyles<true>(variant)}
      theme={getTheme}
      components={{ DropdownIndicator: null, ClearIndicator }}
      isClearable
      isMulti
      menuIsOpen={false}
      inputValue={inputValue}
      value={value}
      onKeyDown={handleKeyDown}
      onInputChange={(newValue) => setInputValue(newValue)}
      onChange={onChange}
      placeholder={placeholder ?? t('common.select', 'Select')}
      isDisabled={disabled}
      {...rest}
    />
  )
}
