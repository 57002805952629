import { useTranslation } from 'react-i18next'
import styles from './BoardsOverviewModal.module.css'
import { Door, GameBoard, GameBoardSettings } from '../../../types'
import { Modal } from '../../../../../common/components/Modal/Modal'
import { getIcon } from '../../../../../common/components/icons/utils'
import { CloseButton } from '../../../../../common/components/button/CloseButton'
import L, { CRS } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { MapContainer } from 'react-leaflet'
import { useCallback, useMemo, useRef, useState } from 'react'
import { DraggableBoardThumbnail } from './DraggableBoardThumbnail'
import { DoorConnectionLine } from './DoorConnectionLine'
import { useGame } from '../../../../../contexts/GameContext'
import { useNotification } from '../../../../../contexts/NotificationContext'
import useOnClickOutside from 'use-onclickoutside'
import { Task } from '../../../../../types/commonTypes'
import {
  OVERVIEW_HEIGHT,
  OVERVIEW_WIDTH,
  THUMBNAIL_IMAGE_SIZE,
  getRelativeX,
  getRelativeY,
} from './BoardsOverviewHelper'
import { Tooltipper } from './Tooltipper'

type BoardsOverviewModalProps = {
  tasks?: Task[]
  gameBoardSettings?: GameBoardSettings
  onClose: () => void
  onActivateBoardToEditor: (boardIndex: number) => void
}

export type DoorExtended = Door & {
  twoWay?: boolean
}

export const BoardsOverviewModal: React.FC<BoardsOverviewModalProps> = ({
  tasks,
  gameBoardSettings,
  onClose,
  onActivateBoardToEditor,
}) => {
  const { t } = useTranslation()
  const { moveExplorationBoardThumbnail } = useGame()
  const { notifyError } = useNotification()
  const mapRef = useRef<L.Map>(null)
  const [activeBoard, setActiveBoard] = useState<GameBoard | undefined>()
  const modalRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(modalRef, (e: any) => {
    if (clickedElementShouldNotClose(e.target)) return
    onClose()
  })

  const handleMapReady = useCallback(() => {
    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.setZoom(0)
      }
    }, 10)
  }, [])

  const handleActivateBoardThumbnail = useCallback((board?: GameBoard) => {
    setActiveBoard(board)
  }, [])

  const handleMoveBoardThumbnail = useCallback(
    async (board: GameBoard, ux3X: number, ux3Y: number) => {
      const response = await moveExplorationBoardThumbnail(board, getRelativeX(ux3X), getRelativeY(ux3Y))
      if (!response) {
        notifyError({
          title: t('game_editor.exploration_mode.board_move_failed.title', 'Failed to save board position'),
          content: t(
            'game_editor.exploration_mode.board_move_failed.content',
            'An error occurred while saving the board new position. Please try again or contact us for support.',
          ),
          timeout: 3000,
        })
      }
    },
    [moveExplorationBoardThumbnail, notifyError, t],
  )

  const uniqueDoorsWithTwowayInfo: DoorExtended[] = useMemo(() => {
    const doors: DoorExtended[] = []
    gameBoardSettings?.gameBoards.forEach((gameBoard) => {
      gameBoard.doors?.forEach((door) => {
        const otherWayDoor = doors.find(
          (d) => d.boardIndex === door.leadsToBoardIndex && d.leadsToBoardIndex === door.boardIndex,
        )
        if (otherWayDoor) otherWayDoor.twoWay = true
        else doors.push(JSON.parse(JSON.stringify(door)))
      })
    })
    return doors
  }, [gameBoardSettings?.gameBoards])

  return (
    <>
      <Modal>
        <div ref={modalRef} className={styles.boardsOverviewModal}>
          <div className={styles.boardsOverviewHeader}>
            <div className={styles.boardsOverviewHeaderTitle}>
              <span>{getIcon('selectMap')}</span>
              <span>{t('game_editor.exploration_mode.boards_overview.modal_title', 'View game boards')}</span>
            </div>
            <CloseButton autoFocus onClick={() => onClose()} />
          </div>
          <div className={styles.boardsOverviewMainSection}>
            <div className={styles.infoText}>
              {t(
                'game_editor.exploration_mode.boards_overview.info_text',
                'Here you can see the connections between the boards as defined by the doors. You can move the boards to get a better overview.',
              )}
            </div>
            <div className={styles.boardsArea}>
              <MapContainer
                ref={mapRef}
                style={{ height: '100%', width: '100%', zIndex: 0, backgroundColor: 'transparent' }}
                bounds={[
                  [0, 0],
                  [OVERVIEW_HEIGHT * 2 - THUMBNAIL_IMAGE_SIZE * 1.5, OVERVIEW_WIDTH - THUMBNAIL_IMAGE_SIZE],
                ]}
                minZoom={0}
                maxZoom={0}
                crs={CRS.Simple}
                attributionControl={false}
                zoomControl={false}
                whenReady={handleMapReady}
              >
                {gameBoardSettings?.gameBoards.map((board, index) => {
                  return (
                    <DraggableBoardThumbnail
                      isActive={activeBoard?.mapIndex === index}
                      key={board.mapIndex}
                      board={board}
                      onActivate={handleActivateBoardThumbnail}
                      onMove={handleMoveBoardThumbnail}
                      onActivateBoardToEditor={onActivateBoardToEditor}
                      noTasks={!tasks?.filter((t) => t.mapIndex === board.mapIndex).length}
                      noDoorsFrom={
                        !uniqueDoorsWithTwowayInfo.filter(
                          (d) =>
                            (d.boardIndex === board.mapIndex && d.leadsToBoardIndex !== -1) ||
                            (d.twoWay && d.leadsToBoardIndex === board.mapIndex),
                        ).length
                      }
                      noDoorsTo={
                        !uniqueDoorsWithTwowayInfo.filter(
                          (d) =>
                            d.leadsToBoardIndex === board.mapIndex || (d.twoWay && d.boardIndex === board.mapIndex),
                        ).length
                      }
                    />
                  )
                })}
                {uniqueDoorsWithTwowayInfo.map((door, index) => {
                  return (
                    <DoorConnectionLine
                      key={index}
                      boardFrom={gameBoardSettings?.gameBoards[door.boardIndex]}
                      boardTo={gameBoardSettings?.gameBoards[door.leadsToBoardIndex]}
                      twoWay={!!door.twoWay}
                      isActive={
                        door.boardIndex === activeBoard?.mapIndex ||
                        (!!door.twoWay && door.leadsToBoardIndex === activeBoard?.mapIndex)
                      }
                    />
                  )
                })}
              </MapContainer>
            </div>
          </div>
        </div>
      </Modal>
      <Tooltipper classIdentifier='boardStateIcon' tooltipContentAttribute='data-tooltip-content' />
    </>
  )
}

const ADD_MODAL_NO_CLOSE = 'Tooltipper'
export const clickedElementShouldNotClose = (element: HTMLElement): boolean => {
  let me: HTMLElement | null = element
  const meAndParents = []
  while (me) {
    meAndParents.push(me)
    me = me.parentElement
  }

  return !!meAndParents.find((el) => typeof el.className === 'string' && el.className?.includes(ADD_MODAL_NO_CLOSE))
}
