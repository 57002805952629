import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputField } from '../../../common/components/input/InputField'
import styles from './Search.module.css'

type RefineSearchProps = {
  initialValue: string
  onChange: (value: string) => void
}

export const SearchInput: React.FC<RefineSearchProps> = ({ initialValue, onChange }) => {
  const { t } = useTranslation()

  const [value, setValue] = useState<string>(initialValue)

  const handleInputChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.value
      setValue(newValue)
    },
    [setValue],
  )

  const onSubmitInternal = useCallback(
    (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      onChange(value)
    },
    [onChange, value],
  )

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <div className={styles.refineSearchContainer}>
      <form className={styles.messageInputForm} onSubmit={onSubmitInternal}>
        <InputField
          onChange={handleInputChange}
          value={value}
          icon='search'
          className={styles.inputField}
          placeholder={t('sponsored_games.instructor.search_placeholder', 'Search')}
          button={{
            type: 'submit',
            icon: 'arrowRight',
            color: 'blue',
          }}
        />
      </form>
    </div>
  )
}
