import { TFunction } from 'i18next'
import { NewTrialParams } from '../../api/userTypes'
import { FormErrorType } from '../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../util/string'
import { ALL_ORG_TYPES, OTHER_OPTION_VALUE } from './constants'
import { AnswerStep } from './steps/AnswerStep'
import { CountryStep } from './steps/CountryStep'
import { NameStep } from './steps/NameStep'
import { OrganisationStep } from './steps/OrganisationStep'
import { FormStep, OnboardingForm } from './types'
import { SponsoredStep } from './steps/SponsoredStep'

export const nameStepValidate = (t: TFunction) => (values: Partial<OnboardingForm>) => {
  const errors = {} as FormErrorType<OnboardingForm>
  if (safeIsNullOrEmpty(values.firstName)) {
    errors.firstName = t('validation_errors.required', 'Required')
  } else if (values.firstName?.includes('#')) {
    errors.firstName = t('validation_errors.invalid_characters', "Remove invalid characters: '#'")
  }
  if (safeIsNullOrEmpty(values.lastName)) {
    errors.lastName = t('validation_errors.required', 'Required')
  } else if (values.lastName?.includes('#')) {
    errors.lastName = t('validation_errors.invalid_characters', "Remove invalid characters: '#'")
  }
  return errors
}

export const countryStepValidate = (t: TFunction) => (values: Partial<OnboardingForm>) => {
  const errors = {} as FormErrorType<OnboardingForm>
  if (!values.country) {
    errors.country = t('validation_errors.required', 'Required')
  }
  return errors
}

export const organizationValidate = (t: TFunction) => (values: Partial<OnboardingForm>) => {
  const errors = {} as FormErrorType<OnboardingForm>
  if (!values.organisationType) {
    errors.organisationType = t('validation_errors.select_one', 'Select one')
  } else if (values.organisationType === OTHER_OPTION_VALUE) {
    if (safeIsNullOrEmpty(values.organisationTypeOther)) {
      errors.organisationTypeOther = t('validation_errors.required', 'Required')
    } else if (values.organisationTypeOther?.includes('#')) {
      errors.organisationTypeOther = t('validation_errors.invalid_characters', "Remove invalid characters: '#'")
    }
  } else if (ALL_ORG_TYPES.includes(values.organisationType) && !values.organisationSubtype) {
    errors.organisationSubtype = t('validation_errors.select_one', 'Select one')
  } else if (values.organisationSubtype === OTHER_OPTION_VALUE) {
    if (safeIsNullOrEmpty(values.organisationSubtypeOther)) {
      errors.organisationSubtypeOther = t('validation_errors.required', 'Required')
    } else if (values.organisationSubtypeOther?.includes('#')) {
      errors.organisationSubtypeOther = t('validation_errors.invalid_characters', "Remove invalid characters: '#'")
    }
  }
  return errors
}

export const answerValidate = (t: TFunction) => (values: Partial<OnboardingForm>) => {
  const errors = {} as FormErrorType<OnboardingForm>
  if (!values.answer) {
    errors.answer = t('validation_errors.select_one', 'Select one')
  } else if (values.answer === OTHER_OPTION_VALUE) {
    if (safeIsNullOrEmpty(values.answerOther)) {
      errors.answerOther = t('validation_errors.required', 'Required')
    } else if (values.answerOther?.includes('#')) {
      errors.answerOther = t('validation_errors.invalid_characters', "Remove invalid characters: '#'")
    }
  }
  if (!values.termsOfService) {
    errors.termsOfService = t(
      'onboarding_wizard.tos.required_error_message',
      'Please accept our terms of service to continue',
    )
  }
  return errors
}

export const sponsoredStepValidate = (t: TFunction) => (values: Partial<OnboardingForm>) => {
  const errors = {} as FormErrorType<OnboardingForm>
  if (safeIsNullOrEmpty(values.firstName)) {
    errors.firstName = t('validation_errors.required', 'Required')
  } else if (values.firstName?.includes('#')) {
    errors.firstName = t('validation_errors.invalid_characters', "Remove invalid characters: '#'")
  }
  if (safeIsNullOrEmpty(values.lastName)) {
    errors.lastName = t('validation_errors.required', 'Required')
  } else if (values.lastName?.includes('#')) {
    errors.lastName = t('validation_errors.invalid_characters', "Remove invalid characters: '#'")
  }
  if (!values.country) {
    errors.country = t('validation_errors.required', 'Required')
  }
  if (!values.termsOfService) {
    errors.termsOfService = t(
      'onboarding_wizard.tos.required_error_message',
      'Please accept our terms of service to continue',
    )
  }
  return errors
}

export const FORM_STEPS: FormStep[] = [
  {
    component: <NameStep />,
    validateCreator: (t: TFunction) => nameStepValidate(t),
  },
  {
    component: <CountryStep />,
    validateCreator: (t: TFunction) => countryStepValidate(t),
  },
  {
    component: <OrganisationStep />,
    validateCreator: (t: TFunction) => organizationValidate(t),
  },
  {
    component: <AnswerStep />,
    validateCreator: (t: TFunction) => answerValidate(t),
  },
]

export const FORM_STEPS_SPONSORED: FormStep[] = [
  {
    component: <SponsoredStep />,
    validateCreator: (t: TFunction) => sponsoredStepValidate(t),
  },
]

export const parseFormValuesToSubmitData = (values: OnboardingForm, language: string): NewTrialParams => {
  return {
    answer: values.answer === OTHER_OPTION_VALUE ? values.answerOther! : values.answer,
    country: values.country,
    firstName: values.firstName,
    language: language,
    lastName: values.lastName,
    organisationType:
      values.organisationType === OTHER_OPTION_VALUE ? values.organisationTypeOther! : values.organisationType,
    ...(values.organisationSubtype != null && {
      organisationSubtype:
        values.organisationSubtype === OTHER_OPTION_VALUE
          ? values.organisationSubtypeOther!
          : values.organisationSubtype,
    }),
  }
}
