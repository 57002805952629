import * as React from 'react'
import { SVGProps } from 'react'
const SvgCodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 27 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.126101 8.99175C0.126101 8.99175 5.15856 1.21563 5.82985 0.436749C6.50114 -0.34213 7.0011 0.158468 7.0011 0.158468C7.0011 0.158468 7.58091 0.542417 7.0786 1.26981C6.5763 1.9972 1.65235 9.408 1.65235 9.408L7.07738 17.5467C7.56637 18.3792 7.00114 18.6578 7.00114 18.6578C7.00114 18.6578 6.30854 19.1578 5.82985 18.3792C5.35116 17.6007 0.126101 9.82425 0.126101 9.82425C0.0438714 9.701 -1.14441e-05 9.55616 -1.14441e-05 9.408C-1.14441e-05 9.25984 0.0438714 9.115 0.126101 8.99175ZM26.1573 8.99175C26.1573 8.99175 21.1248 1.21563 20.4535 0.436749C19.7822 -0.34213 19.2823 0.158468 19.2823 0.158468C19.2823 0.158468 18.7025 0.542417 19.2048 1.26981C19.7071 1.9972 24.631 9.408 24.631 9.408L19.206 17.5467C18.717 18.3792 19.2822 18.6578 19.2822 18.6578C19.2822 18.6578 19.9748 19.1578 20.4535 18.3792C20.9322 17.6007 26.1573 9.82425 26.1573 9.82425C26.2395 9.701 26.2834 9.55616 26.2834 9.408C26.2834 9.25984 26.2395 9.115 26.1573 8.99175Z'
      fill={props.color || 'currentColor'}
    />
  </svg>
)
export default SvgCodeIcon
