import { MissingWordAnswerOption } from '../types/commonTypes'

export const getMissingWordFieldHtml = (index: number, uniqueId: string, placeholderPrefix: string) => {
  return `<input id="field_${index}" class="form-control added_field_in_tinymice_editor" style="margin: 0 4px;" disabled="disabled" type="textarea" placeholder="${placeholderPrefix}-${index}" data-ux3id="${uniqueId}" />`
}

export const anyMissingWordFieldRegex =
  /<input id="field_\d+" class="form-control added_field_in_tinymice_editor" style="margin: 0 4px;" disabled="disabled" type="textarea" placeholder="[^-]*-\d+" data-ux3id="\d+"( )?(\/)?>/g

export const inValidFieldRegex =
  /<input id="field_0" class="form-control added_field_in_tinymice_editor" style="margin: 0 4px;" disabled="disabled" type="textarea" placeholder="[^-]*-\d+" data-ux3id="\d+"( )?(\/)?>/g

const getIndexIdFromMissingWordField = (field: string): string => {
  return field
    .match(/id="field_\d+"/g)![0]
    .replace('id="field_', '')
    .replace('"', '')
}

const getUx3IdFromMissingWordField = (field: string): string => {
  return (
    field
      .match(/data-ux3id="\d+"/g)?.[0]
      .replace('data-ux3id="', '')
      .replace('"', '') ?? ''
  )
}

export const removeMissingWordFieldsFromContent = (content: string): string => {
  return content.replace(anyMissingWordFieldRegex, '')
}

export const getRandomAnswerId = () => Math.floor(100000 + Math.random() * 900000).toString()

const fixFieldIndex = (field: string, index: number): string => {
  const placeholderPrefix = field.match(/placeholder="[^-]*-\d+"/g)?.[0].split('-')[0]
  return field
    .replace(/id="field_\d+"/g, `id="field_${index}"`)
    .replace(/placeholder="[^-]*-\d+"/g, `${placeholderPrefix}-${index}"`)
}

const getUx3ForLegacyField = (id: string, placeholder: string, ux3id: string) => {
  return `<input id="${id}" class="form-control added_field_in_tinymice_editor" style="margin: 0 4px;" disabled="disabled" type="textarea" placeholder="${placeholder}" data-ux3id="${ux3id}" />`
}

export const transformLegacyToUx3Description = (description: string): string => {
  const divElement = document.createElement('div')
  divElement.innerHTML = description
  const legacyFields = divElement.querySelectorAll('input:not([data-ux3id])')
  legacyFields.forEach((field, fieldIndex) => {
    field.outerHTML = getUx3ForLegacyField(
      field.getAttribute('id') ?? `field_${fieldIndex}`,
      field.getAttribute('placeholder') ?? `Word-${fieldIndex}`,
      getRandomAnswerId(),
    )
  })
  return divElement.innerHTML
}

export const getOrderedDescriptionAndAnswers = (
  description: string,
  choices: string[],
  points: number[],
): [string, MissingWordAnswerOption[]] => {
  let descriptionWithOrderedFields = description.replace(inValidFieldRegex, '')
  const allFields = descriptionWithOrderedFields.match(anyMissingWordFieldRegex)
  const orderedAnswers: MissingWordAnswerOption[] = []
  allFields?.forEach((field, index) => {
    const originalIndex = parseInt(getIndexIdFromMissingWordField(field))
    if (originalIndex !== index + 1) {
      descriptionWithOrderedFields = descriptionWithOrderedFields.replace(field, fixFieldIndex(field, index + 1))
    }
    orderedAnswers.push({
      word: choices[originalIndex - 1].replaceAll('\\', ''),
      points: points[originalIndex - 1],
      ux3id: getUx3IdFromMissingWordField(field),
    })
  })
  return [descriptionWithOrderedFields, orderedAnswers]
}
