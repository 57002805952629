import { useForm } from 'react-final-form'
import { Button } from '../../../../../common/components/button/Button'
import { ExerciseType } from '../../../../../api/gameTypes'
import { getRandomTempId } from '../../../../../util/functional'
import { CreativeAnswerOption, PointsType } from '../../../../../types/commonTypes'
import { getMissingWordFieldHtml, getRandomAnswerId } from '../../../../../util/missingWord'
import { getIcon } from '../../../../../common/components/icons/utils'

export const TaskDevPrefill: React.FC = () => {
  const { batch, change } = useForm()

  if (process.env.REACT_APP_ENV !== 'development') {
    return null
  }

  const handleClickOne = () => {
    batch(() => {
      change('name', `${getRandomItem(buzzword)} ${getRandomItem(animals)}`)
      change('subtasks', [
        {
          type: ExerciseType.CreativeExercise,
          tempId: getRandomTempId(),
          description: getRandomItem(plants),
          data: {
            pointsType: PointsType.Evaluate,
            answerOptions: [CreativeAnswerOption.Text],
            pointsForAnswer: 20,
          },
        },
      ])
    })
  }

  const handleClickAll = () => {
    const firstMissingFieldId = getRandomAnswerId()
    const firstMissingFieldHtml = getMissingWordFieldHtml(1, firstMissingFieldId, 'Field')
    const secondMissingFieldId = getRandomAnswerId()
    const secondMissingFieldHtml = getMissingWordFieldHtml(2, secondMissingFieldId, 'Field')
    batch(() => {
      change('name', `${getRandomItem(buzzword)} ${getRandomItem(animals)}`)
      change('subtasks', [
        {
          type: ExerciseType.CreativeExercise,
          tempId: getRandomTempId(),
          description: getRandomItem(plants),
          data: {
            pointsType: PointsType.Evaluate,
            answerOptions: [CreativeAnswerOption.Text],
            pointsForAnswer: 20,
          },
        },
        {
          type: ExerciseType.CombineExercise,
          tempId: getRandomTempId(),
          description: getRandomItem(plants),
          data: {
            allAnswersHaveSamePoints: false,
            answers: [
              {
                left: { text: 'left side 1' },
                right: { text: 'right side 1' },
                points: 10,
              },
              {
                left: { text: 'left side 2' },
                right: { text: 'right side 2' },
                points: 20,
              },
            ],
          },
        },
        {
          type: ExerciseType.MissingWordExercise,
          tempId: getRandomTempId(),
          description: `${firstMissingFieldHtml} ${getRandomItem(plants)} ${secondMissingFieldHtml}`,
          data: {
            allAnswersHaveSamePoints: false,
            answers: [
              {
                points: 10,
                word: 'missing word 1',
                ux3id: firstMissingFieldId,
              },
              {
                points: 20,
                word: 'missing word 2',
                ux3id: secondMissingFieldId,
              },
            ],
          },
        },
        {
          type: ExerciseType.MultichoiceExercise,
          tempId: getRandomTempId(),
          description: getRandomItem(plants),
          data: {
            allAnswersHaveSamePoints: false,
            answers: [
              {
                text: 'mc option 1',
                points: 10,
              },
              {
                text: 'mc option 2',
                points: 20,
              },
            ],
          },
        },
        {
          type: ExerciseType.PollExercise,
          tempId: getRandomTempId(),
          description: getRandomItem(plants),
          data: {
            allAnswersHaveSamePoints: false,
            answers: [
              {
                text: 'cb option 1',
                points: 10,
              },
              {
                text: 'cb option 2',
                points: 20,
              },
            ],
          },
        },
      ])
    })
  }

  return (
    <>
      <Button variant='outline-tiny' onClick={handleClickOne}>
        <span className='iconWrapperMedium'>{getIcon('debug')}</span>
        Creative
      </Button>
      <Button variant='outline-tiny' onClick={handleClickAll}>
        <span className='iconWrapperMedium'>{getIcon('debug')}</span>
        All
      </Button>
    </>
  )
}

const getRandomItem = (arr: string[]) => arr[Math.floor(Math.random() * arr.length)]

const plants = [
  'Florida Thatch Palm',
  'Sierra Pea',
  'Tree Anemone',
  'Pennsylvania Everlasting',
  'Rigid Didymodon Moss',
  'Molokai Melicope',
  'Sandcarpet',
  'Fiveleaf Yam',
  "Kirilow's Indigo",
  'Zygodon Moss',
  'Southern Monardella',
  'Pygmy Pussytoes',
  'Massachusetts Blackberry',
  'Sandblossoms',
  'Coast Beard-heath',
  'Lung Lichen',
  'Obolaria',
  'Simple Campion',
  'Spanish Cedar',
  'South Idaho Onion',
  'Sierra Blazingstar',
  "Hall's Wild Cabbage",
  'Blue Mountain Buckwheat',
  "'aku 'aku",
  'Knotted Rush',
  'Rio Grande Spikerush',
  'Summer Lupine',
  'Goldenfruit Mistletoe',
  'Moutan Peony',
  'Scott Mountain Phacelia',
  'Fewnerved Cottongrass',
  'Dogwoodleaf',
  'Coamo River Pouzolzsbush',
  'Sandmint',
  'Slender Dwarf Morning-glory',
  'Cocona',
  'Sorbaronia',
  "Isely's Milkvetch",
  'Sweetshrub',
  'Yellow Columbine',
  "Leonard's Beardtongue",
  'Zion Draba',
]

const animals = [
  'Wild turkey',
  'Blackbird',
  'Goose',
  'Egret',
  'Jackal',
  'Pintail',
  'Rattlesnake',
  'Ovenbird',
  'Python',
  'Phalarope',
  'Emu',
  'Beisa oryx',
  'Skunk',
  'Darter',
  'Campo flicker',
  'Starfish',
  'Echidna',
  'Mouflon',
  'Golden brush-tailed possum',
  'Cape clawless otter',
  'Dragon',
  'Eagle',
  'Common pheasant',
  'Warthog',
  'American alligator',
  'Chimpanzee',
  'Roan antelope',
  'Frogmouth',
  "Grant's gazelle",
  'Arctic hare',
  'Otter',
  'Cormorant',
  'Ant (unidentified)',
  'Golden brush-tailed possum',
  'Fox',
  'Caiman',
  'Hornbill',
  'White-fronted bee-eater',
  'Zebra',
  'Ornate rock dragon',
  'Sheep',
  'Golden jackal',
]

const buzzword = [
  'De-engineered',
  'Maximized',
  'Adaptive',
  'Bottom-line',
  'Extranet',
  'Help-desk',
  'Secured line',
  'Function',
  'Open-architected',
  'Integrated',
  'Advanced',
  'Orchestration',
  'Architecture',
  'Configurable',
  'Face to face',
  'Adapter',
  'Up-sized',
  'Local area network',
  'Team-oriented',
  'Policy',
  'Background',
  'Moratorium',
  'Visionary',
  'Multi-tasking',
  'Forecast',
  'Complexity',
  'Total',
  'Phased',
  'Clear-thinking',
  'Context-sensitive',
  'Face to face',
  'Impactful',
  'Protocol',
  'Object-based',
  'Homogeneous',
  'Expanded',
  'Open-source',
  'Complexity',
  'Upgradable',
  'Maximized',
  'Responsive',
  'Reactive',
]
