import { useTranslation } from 'react-i18next'
import { PopupActionMenuItem } from '../../../common/components/PopupMenu/PopupActionMenu'
import { GameAction } from '../../../types/commonTypes'
import { noop } from '../../../util/functional'
import styles from './card/Card.module.css'

type GameMoreMenuProps = {
  libraryMode?: boolean
  onMenuItemClick: (action: GameAction) => void
  isExpired: boolean
  isSponsored?: boolean
  isSponsoredInstructorView?: boolean
}

export const GameMoreMenu: React.FC<GameMoreMenuProps> = ({
  libraryMode,
  onMenuItemClick,
  isExpired,
  isSponsored = false,
  isSponsoredInstructorView = false,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <PopupActionMenuItem
        icon='view'
        text={t('context_menu.preview_game', 'Preview game')}
        onClick={() => onMenuItemClick(GameAction.VIEW)}
      />
      {isSponsored ? (
        <PopupActionMenuItem
          icon={isSponsoredInstructorView ? 'plus' : 'download'}
          text={t('sponsored_games.download_game', 'Download game')}
          onClick={!isExpired ? () => onMenuItemClick(GameAction.DOWNLOADMODAL) : noop}
          disabled={isExpired}
        />
      ) : libraryMode ? (
        <PopupActionMenuItem
          icon='text'
          text={t('context_menu.import_game', 'Import game')}
          onClick={!isExpired ? () => onMenuItemClick(GameAction.IMPORT) : noop}
          disabled={isExpired}
        />
      ) : (
        <>
          <PopupActionMenuItem
            icon='text'
            text={t('context_menu.game_in_editor', 'Open game in editor')}
            onClick={() => onMenuItemClick(GameAction.EDIT)}
          />

          <PopupActionMenuItem
            icon='duplicate'
            text={t('context_menu.duplicate', 'Duplicate game')}
            onClick={!isExpired ? () => onMenuItemClick(GameAction.COPY) : noop}
            disabled={isExpired}
          />
          <div className={styles.rowSeparator} />
          <PopupActionMenuItem
            icon='trash'
            text={t('context_menu.delete_game', 'Delete game')}
            onClick={() => onMenuItemClick(GameAction.DELETE)}
          />
        </>
      )}
    </>
  )
}
