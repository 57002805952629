import * as React from 'react'
import { SVGProps } from 'react'
const OpenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.9538 9.00045L18.9538 5.50054L12.1807 12.1589C11.7913 12.5119 11.1435 12.518 10.8178 12.1589C10.4921 11.7997 10.484 10.9558 10.8178 10.6531L17.4538 4.00054C17.4538 4.00054 15.7412 4.02727 13.9538 4.00013C13.2136 3.98889 12.947 3.44746 12.9538 3.00013C12.9606 2.5528 13.296 1.99014 13.9538 2.00013L19.9538 2.00013L20.3378 2.08827C20.5563 2.21743 20.7734 2.45689 20.8806 2.68692C20.9335 2.85709 20.9322 2.9674 20.9308 3.0777L20.9538 9.00054C20.9538 9.50017 20.5189 10.0004 19.9538 10.0006C19.3887 10.0008 18.9538 9.41518 18.9538 9.00045ZM19 18.1294V12.8953C19 12.1226 18.5 11.6294 18 11.6294C17.5 11.6294 17 12.2246 17 12.8953V18.1294C17 18.6131 16.8389 18.6294 16.5 18.6294H4.5C4.35382 18.6294 4 18.6294 4 18.1294V6.2549C4 5.62944 4.5 5.62944 4.5 5.62944H10C10.5 5.62944 11 5.12944 11 4.62944C11 4.12944 10.5 3.62944 10 3.62944H4.5C3 3.62944 2 4.62944 2 6.2549V18.1294C2 19.5113 3.31395 20.6294 4.5 20.6294H16.5C18 20.6294 19 19.6294 19 18.1294Z'
      fill={props.color ?? 'var(--primary-normal)'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.9538 9.00045L18.9538 5.50054L12.1807 12.1589C11.7913 12.5119 11.1435 12.518 10.8178 12.1589C10.4921 11.7997 10.484 10.9558 10.8178 10.6531L17.4538 4.00054C17.4538 4.00054 15.7412 4.02727 13.9538 4.00013C13.2136 3.98889 12.947 3.44746 12.9538 3.00013C12.9606 2.5528 13.296 1.99014 13.9538 2.00013L19.9538 2.00013L20.3378 2.08827C20.5563 2.21743 20.7734 2.45689 20.8806 2.68692C20.9335 2.85709 20.9322 2.9674 20.9308 3.0777L20.9538 9.00054C20.9538 9.50017 20.5189 10.0004 19.9538 10.0006C19.3887 10.0008 18.9538 9.41518 18.9538 9.00045ZM19 18.1294V12.8953C19 12.1226 18.5 11.6294 18 11.6294C17.5 11.6294 17 12.2246 17 12.8953V18.1294C17 18.6131 16.8389 18.6294 16.5 18.6294H4.5C4.35382 18.6294 4 18.6294 4 18.1294V6.2549C4 5.62944 4.5 5.62944 4.5 5.62944H10C10.5 5.62944 11 5.12944 11 4.62944C11 4.12944 10.5 3.62944 10 3.62944H4.5C3 3.62944 2 4.62944 2 6.2549V18.1294C2 19.5113 3.31395 20.6294 4.5 20.6294H16.5C18 20.6294 19 19.6294 19 18.1294Z'
      fill={props.color ?? 'var(--primary-normal)'}
    />
  </svg>
)
export default OpenIcon
