import * as React from 'react'
import { SVGProps } from 'react'
const SvgGoogleClassroomIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#google-classroom_icon_svg__a)'>
      <path d='M2.91 5.09h26.18V26.91H2.91V5.091Z' fill='#0F9D58' />
      <path
        d='M21.818 16.727a1.637 1.637 0 1 0-.002-3.274 1.637 1.637 0 0 0 .002 3.274Zm0 1.091c-1.751 0-3.636.927-3.636 2.08v1.193h7.273v-1.194c0-1.151-1.885-2.079-3.637-2.079Zm-11.636-1.09a1.637 1.637 0 0 0 0-3.273 1.637 1.637 0 0 0 0 3.272Zm0 1.09c-1.752 0-3.637.927-3.637 2.08v1.193h7.273v-1.194c0-1.151-1.885-2.079-3.636-2.079Z'
        fill='#57BB8A'
      />
      <path
        d='M16 15.272a2.18 2.18 0 0 0 2.182-2.181A2.18 2.18 0 0 0 16 10.909a2.18 2.18 0 0 0-2.182 2.181A2.18 2.18 0 0 0 16 15.273Zm0 1.455c-2.454 0-5.09 1.303-5.09 2.909v1.455H21.09v-1.455c0-1.606-2.636-2.91-5.091-2.91Z'
        fill='#F7F7F7'
      />
      <path d='M18.91 25.455h6.544v1.454H18.91v-1.454Z' fill='#F1F1F1' />
      <path
        d='M29.818 2.182H2.182A2.18 2.18 0 0 0 0 4.363v23.273a2.18 2.18 0 0 0 2.182 2.182h27.636A2.18 2.18 0 0 0 32 27.636V4.363a2.18 2.18 0 0 0-2.182-2.181Zm-.727 24.727H2.909V5.09h26.182v21.818Z'
        fill='#F4B400'
      />
      <path
        opacity={0.2}
        d='M29.818 2.182H2.182A2.18 2.18 0 0 0 0 4.363v.182a2.18 2.18 0 0 1 2.182-2.182h27.636A2.18 2.18 0 0 1 32 4.545v-.182a2.18 2.18 0 0 0-2.182-2.181Z'
        fill='#fff'
      />
      <path
        opacity={0.2}
        d='M29.818 29.636H2.182A2.18 2.18 0 0 1 0 27.454v.182a2.18 2.18 0 0 0 2.182 2.182h27.636A2.18 2.18 0 0 0 32 27.636v-.182a2.18 2.18 0 0 1-2.182 2.182Z'
        fill='#BF360C'
      />
      <path d='M25.442 26.909h-6.545l2.909 2.909h6.54l-2.904-2.91Z' fill='url(#google-classroom_icon_svg__b)' />
      <path opacity={0.2} d='M2.91 4.909h26.18v.182H2.91v-.182Z' fill='#263238' />
      <path opacity={0.2} d='M2.91 26.909h26.18v.181H2.91v-.181Z' fill='#fff' />
      <path
        d='M29.818 2.182H2.182A2.18 2.18 0 0 0 0 4.363v23.273a2.18 2.18 0 0 0 2.182 2.182h27.636A2.18 2.18 0 0 0 32 27.636V4.363a2.18 2.18 0 0 0-2.182-2.181Z'
        fill='url(#google-classroom_icon_svg__c)'
      />
    </g>
    <defs>
      <radialGradient
        id='google-classroom_icon_svg__c'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(132.032 0 0 132.031 .942 2.83)'
      >
        <stop stopColor='#fff' stopOpacity={0.1} />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id='google-classroom_icon_svg__b'
        x1={23.622}
        y1={26.949}
        x2={23.622}
        y2={29.848}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#BF360C' stopOpacity={0.2} />
        <stop offset={1} stopColor='#BF360C' stopOpacity={0.02} />
      </linearGradient>
      <clipPath id='google-classroom_icon_svg__a'>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgGoogleClassroomIcon
