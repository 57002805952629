import { EMPTY_CONTENT_FALLBACK } from '../common/constants'

export const pluralize = (count: number, singular: string, plural: string): string => {
  return `${count} ${count === 1 ? singular : plural}`
}

const isNullOrEmpty = (value: string): boolean => {
  return value == null || value?.trim()?.length === 0
}

export const safeIsNullOrEmpty = (value: unknown): boolean => {
  return typeof value === 'string' ? isNullOrEmpty(value) : value == null
}

export const safeIsTrimmedNumeric = (value: unknown): boolean => {
  return /^[0-9]*$/.test((value + '').trim())
}

export const capitalizeFirstLetter = (text: string): string => {
  if (safeIsNullOrEmpty(text)) {
    return ''
  }

  return text[0].toUpperCase() + text.slice(1).toLowerCase()
}

export const padNumWithZero = (num: number, length: number) => {
  // optional chaining + fallback for browsers that don't support padStart
  return num.toString()?.padStart?.(length, '0') ?? num.toString()
}

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)

export const dateTimeToLocalDateString = (dateTime: string | undefined, locale?: string): string => {
  if (dateTime == null) {
    return ''
  }
  const lng = locale ?? localStorage.getItem('i18nextLng') ?? 'fi-FI'
  return new Date(dateTime).toLocaleDateString(lng)
}

export const dateTimeToISOString = (dateTime: string | undefined): string => {
  if (dateTime == null) {
    return EMPTY_CONTENT_FALLBACK
  }
  const zDateTime = dateTime.toUpperCase().endsWith('Z') ? dateTime : `${dateTime}Z`
  return new Date(zDateTime).toISOString()
}

export const dateTimeToLocalDateTimeString = (dateTime: string | undefined): string => {
  if (dateTime == null) {
    return EMPTY_CONTENT_FALLBACK
  }
  const zDateTime = dateTime.toUpperCase().endsWith('Z') ? dateTime : `${dateTime}Z`
  return `${new Date(zDateTime).toLocaleDateString()} ${new Date(zDateTime).toLocaleTimeString()}`
}

export const getCurrentIsoDate = () => {
  return new Date().toISOString().split('T')[0]
}

export const getCurrentIsoTimestamp = () => {
  return new Date().toISOString()
}

export const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  //Do it twice as for example ä get's double encoded in feedback comment to &aml;auml;
  txt.innerHTML = txt.value
  return txt.value
}

export const getHtmlText = (html: string): string => {
  const el = document.createElement('div')
  el.innerHTML = html
  return el.innerText
}

export const isHtmlTextEmpty = (html: string): boolean => isNullOrEmpty(getHtmlText(html))

export const boldSubstrings = (text: string, substring: string): string => {
  const regex = new RegExp(substring, 'gi')
  const matchedParts = text.match(regex)
  const otherParts = text.split(regex)
  return matchedParts == null
    ? text
    : matchedParts.reduce((formattedText: string, currMatch: string, index: number) => {
        return formattedText + `<b>${currMatch}</b>` + otherParts?.[index + 1]
      }, otherParts[0])
}

export const compareStrings = (stringA: string, stringB: string) => {
  const locale = localStorage.getItem('i18nextLng') ?? 'en'
  return stringA.localeCompare(stringB, [locale, 'en'])
}

export const trimDoubleQuotes = (text: string): string => {
  if (text.startsWith('"')) {
    return trimDoubleQuotes(text.substring(1))
  } else if (text.endsWith('"')) {
    return trimDoubleQuotes(text.substring(0, text.length - 1))
  }
  return text
}
