import * as React from 'react'
import { SVGProps } from 'react'
const SvgMsTeamsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.123 13.04a2.77 2.77 0 0 1-1.48-1.449 2.682 2.682 0 0 1 0-2.111 2.767 2.767 0 0 1 1.48-1.45 2.85 2.85 0 0 1 2.158 0 2.766 2.766 0 0 1 1.48 1.45 2.682 2.682 0 0 1 0 2.112 2.767 2.767 0 0 1-1.48 1.449 2.851 2.851 0 0 1-2.158 0ZM26.426 23.16v-8.181h5.572v5.361c.02.483-.12.958-.397 1.357a2.99 2.99 0 0 1-1.006.87c-.422.223-.877.379-1.35.46-.47.087-.947.13-1.425.133h-1.393ZM22.99 11.335a2.747 2.747 0 0 1-1.078.212 2.706 2.706 0 0 1-1.075-.212 1.39 1.39 0 0 1-.297-.177c-.096-.07-.204-.147-.383-.232V6.734c.179-.085.286-.163.383-.233.092-.066.174-.125.297-.176a2.709 2.709 0 0 1 1.071-.213c.371-.003.738.07 1.08.213a2.766 2.766 0 0 1 1.48 1.448c.29.677.29 1.438 0 2.113a2.764 2.764 0 0 1-1.478 1.449Zm-2.48 14.073a6.588 6.588 0 0 0-.353-.086v-12.05h5.224v9.468c.018.475-.103.946-.35 1.355-.224.35-.527.646-.888.863a3.76 3.76 0 0 1-1.19.455c-.414.087-.835.13-1.257.132a6.029 6.029 0 0 1-.818-.058 2.749 2.749 0 0 1-.368-.08ZM19.112 31 1 27.932V4.068L19.112 1v30ZM5.877 10.703l8.358-.498v2.045l-3.134.136v8.95l-2.09-.12v-8.75l-3.134.124v-1.887Z'
      fill='#CC3E76'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.123 13.04a2.77 2.77 0 0 1-1.48-1.449 2.682 2.682 0 0 1 0-2.111 2.767 2.767 0 0 1 1.48-1.45 2.85 2.85 0 0 1 2.158 0 2.766 2.766 0 0 1 1.48 1.45 2.682 2.682 0 0 1 0 2.112 2.767 2.767 0 0 1-1.48 1.449 2.851 2.851 0 0 1-2.158 0ZM26.426 23.16v-8.181h5.572v5.361c.02.483-.12.958-.397 1.357a2.99 2.99 0 0 1-1.006.87c-.422.223-.877.379-1.35.46-.47.087-.947.13-1.425.133h-1.393ZM22.99 11.335a2.747 2.747 0 0 1-1.078.212 2.706 2.706 0 0 1-1.075-.212 1.39 1.39 0 0 1-.297-.177c-.096-.07-.204-.147-.383-.232V6.734c.179-.085.286-.163.383-.233.092-.066.174-.125.297-.176a2.709 2.709 0 0 1 1.071-.213c.371-.003.738.07 1.08.213a2.766 2.766 0 0 1 1.48 1.448c.29.677.29 1.438 0 2.113a2.764 2.764 0 0 1-1.478 1.449Zm-2.48 14.073a6.588 6.588 0 0 0-.353-.086v-12.05h5.224v9.468c.018.475-.103.946-.35 1.355-.224.35-.527.646-.888.863a3.76 3.76 0 0 1-1.19.455c-.414.087-.835.13-1.257.132a6.029 6.029 0 0 1-.818-.058 2.749 2.749 0 0 1-.368-.08ZM19.112 31 1 27.932V4.068L19.112 1v30ZM5.877 10.703l8.358-.498v2.045l-3.134.136v8.95l-2.09-.12v-8.75l-3.134.124v-1.887Z'
      fill='#565AA9'
    />
  </svg>
)
export default SvgMsTeamsIcon
