import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import { GameAction, TGameCard } from '../../../types/commonTypes'
import { GameListItem } from './GamesListItem'

import classNames from 'classnames'
import styles from './GamesList.module.css'

type GamesListProps = {
  games: TGameCard[]
  loading: boolean
  libraryMode?: boolean
  onGameAction: (action: GameAction, game: TGameCard) => void
  isSponsoredInstructorView?: boolean
}

export const GamesList: React.FC<GamesListProps> = ({
  games,
  loading,
  onGameAction,
  isSponsoredInstructorView = false,
}) => {
  const { fadeTransition } = useFadeAnimation(loading, true)
  const { t } = useTranslation()

  return (
    <table className={classNames(styles.gamesTable, fadeTransition)}>
      <colgroup>
        <col />
        <col />
        <col />
        <col />
        <col />
        <col style={{ width: 50 }} />
      </colgroup>
      <thead>
        <tr>
          <th>{t('games_table.header_titles.name', 'Name')}</th>
          <th>{t('games_table.header_titles.state', 'State')}</th>
          <th>{t('games_table.header_titles.users', 'Users')}</th>
          <th className={styles.lastModifiedColumn}>{t('games_table.header_titles.last_modified', 'Last modified')}</th>
          <th className={styles.infoColumn}>{t('games_table.header_titles.info', 'Info')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {games.map((game, i) => (
          <GameListItem
            key={`game_list_item__${game.id}_${i}`}
            game={game}
            onGameAction={onGameAction}
            isSponsored={game.librarySource === 'SPONSORED'}
            isSponsoredInstructorView={isSponsoredInstructorView}
          />
        ))}
      </tbody>
    </table>
  )
}
