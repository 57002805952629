import { useState } from 'react'
import { SingleValue } from 'react-select'
import { Select, SelectOption } from '../../common/components/Select/Select'
import { IconTooltip } from '../../common/components/tooltip/IconTooltip'
import {
  EVALUATOR_PERMISSIONS,
  INSTRUCTOR_PERMISSIONS,
  INSTRUCTOR_RESTRICTED_PERMISSIONS,
  STUDENT_INSTRUCTOR_PERMISSIONS,
  STUDENT_INSTRUCTOR_RESTRICTED_PERMISSIONS,
} from '../../contexts/GameContextHelper'

type DebugEditorPermissionsProps = {}

const EDITOR_PERMISSION_OPTIONS: SelectOption[] = [
  { label: 'Default for current user', value: 'default' },
  { label: 'Main instructor', value: 'instructor' },
  { label: 'Main instructor - sponsored game or package game', value: 'instructor-restricted' },
  { label: 'Student instructor', value: 'studentInstructor' },
  { label: 'Student instructor - sponsored game or package game', value: 'studentInstructor-restricted' },
  { label: 'Evaluator - any game', value: 'evaluator' },
]

const getPermissionsForOption = (option: string) => {
  switch (option) {
    case 'instructor':
      return INSTRUCTOR_PERMISSIONS
    case 'instructor-restricted':
      return INSTRUCTOR_RESTRICTED_PERMISSIONS
    case 'studentInstructor':
      return STUDENT_INSTRUCTOR_PERMISSIONS
    case 'studentInstructor-restricted':
      return STUDENT_INSTRUCTOR_RESTRICTED_PERMISSIONS
    case 'evaluator':
      return EVALUATOR_PERMISSIONS
  }
}

export const DebugEditorPermissions: React.FC<DebugEditorPermissionsProps> = () => {
  const [editorPermissions, setEditorPermissions] = useState<SelectOption>(() => {
    try {
      const key =
        localStorage.getItem('editor-permission-debug-override') != null
          ? JSON.parse(localStorage.getItem('editor-permission-debug-override')!).key
          : 'dumb-key'
      return EDITOR_PERMISSION_OPTIONS.find((o) => o.value === key) ?? EDITOR_PERMISSION_OPTIONS[0]
    } catch {
      return EDITOR_PERMISSION_OPTIONS[0]
    }
  })

  const onChangeEditorPermission = (option: SingleValue<SelectOption>) => {
    if (option == null || option.value === 'default') {
      setEditorPermissions(EDITOR_PERMISSION_OPTIONS[0])
      localStorage.removeItem('editor-permission-debug-override')
    } else {
      setEditorPermissions(option)
      localStorage.setItem(
        'editor-permission-debug-override',
        JSON.stringify({ key: option.value, value: getPermissionsForOption(option.value) }),
      )
    }
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'flex-end' }}>
        <h5>Set custom user permission</h5>
        <IconTooltip tooltipContent='Does not work while on game page. Go to another page, set permissions and go back to editor' />
      </div>
      <div style={{ width: '50%' }}>
        <Select value={editorPermissions} onChange={onChangeEditorPermission} options={EDITOR_PERMISSION_OPTIONS} />
      </div>
    </>
  )
}
