import classNames from 'classnames'
import { addHotSpot, getConfig, mouseEventToCoords, removeHotSpot } from 'react-pannellum'
import { TaskIconType } from '../../../../api/gameTypes'
import { Task } from '../../../../types/commonTypes'
import { PIN_ANCHOR_Y_FIX, PIN_HEIGHT, PIN_WIDTH } from './DraggableMarker'
import styles from './DraggableMarker.module.css'
import { TaskPinRawSvgMap, getPinIdClass } from './TaskPinHelper'

const getInnerIcon = (task: Pick<Task, 'id' | 'name' | 'advanced'>) => {
  const innerElement = document.createElement('span')
  innerElement.innerHTML = TaskPinRawSvgMap[task.advanced.iconType || TaskIconType.seppo]
  innerElement.className = classNames(styles.icon, getPinIdClass(task.id))
  innerElement.style.width = `${PIN_WIDTH}px`
  innerElement.style.height = `${PIN_HEIGHT}px`
  innerElement.style.pointerEvents = 'none'
  return innerElement
}

const getIcon = (task: Pick<Task, 'id' | 'name' | 'advanced'>) => {
  const iconElement = document.createElement('div')
  iconElement.id = 'marker360-' + task.id
  iconElement.className = getPinIdClass(task.id)
  iconElement.style.width = `${PIN_WIDTH}px`
  iconElement.style.height = `${PIN_HEIGHT}px`
  iconElement.style.position = 'absolute'
  iconElement.style.top = `-${PIN_HEIGHT - PIN_ANCHOR_Y_FIX}px`
  iconElement.style.left = `-${PIN_WIDTH / 2}px`
  iconElement.appendChild(getInnerIcon(task))
  return iconElement
}

export const refreshDraggableMarkersToMap = (
  tasks: Task[],
  onMoveTask: (taskId: number, x: number, y: number) => void,
) => {
  getConfig().hotSpots.forEach((spot: any) => {
    setTimeout(() => {
      if (spot.id?.includes('task')) removeHotSpot(spot.id, 'seppoScene')
    }, 100)
  })
  //Remove possibly existing movement icon and create new
  //The normal dragging inside pannellum did not work on Safari / Firefox, thus workaround with generic mouse-events and 'manual' moveventIcon
  const movementIcon = getIcon({ id: 0, name: '', advanced: {} })
  movementIcon.style.top = '-200px'
  movementIcon.style.left = '-200px'
  setTimeout(() => {
    document.getElementById('marker360-0')?.remove()
    document.getElementById('root')?.append(movementIcon)
  }, 50)

  tasks.forEach((aTask) => {
    const markerId = 'task_' + aTask.id + '_' + Math.random() * 10000
    const marker = {
      pitch: aTask.y,
      yaw: aTask.x,
      id: markerId,
      //Classes RFU to for example make a differencet between a door and a task pin
      cssClass: 'marker-360 is-exercise exercise_class_' + markerId,
    }
    addHotSpot(marker, 'seppoScene')
    const markerIcon = getIcon(aTask)
    getConfig()
    setTimeout(() => {
      const markerElement = document.getElementsByClassName('exercise_class_' + markerId)[0]
      markerElement.append(markerIcon)
    }, 20)

    //Drag and drop datatransfer not working properly so tranferring the data from DragStart to DragEnd via vars local to this marker
    let markerCenterXDiff = 0
    let markerCenterYDiff = 0

    function onDrag(evt: any) {
      movementIcon.style.top = evt.clientY - PIN_HEIGHT - markerCenterYDiff + 'px'
      movementIcon.style.left = evt.clientX - PIN_WIDTH / 2 - markerCenterXDiff + 'px'
      markerIcon.style.opacity = '0.01'
    }

    function onDragEnd(evt: any) {
      window.removeEventListener('mousemove', onDrag)
      window.removeEventListener('mouseup', onDragEnd)
      evt.preventDefault()
      // Make our own evt coordinates with correction to drag position relative to icon focus point
      const _evt = {
        clientX: evt.clientX - markerCenterXDiff,
        clientY: evt.clientY - markerCenterYDiff - 0.5 - PIN_ANCHOR_Y_FIX,
      }
      const newPitchAndYaw = mouseEventToCoords(_evt)
      const _tasks: Task[] = JSON.parse(JSON.stringify(tasks))
      const movedTask = _tasks.find((t) => t.id === aTask.id)
      if (movedTask) {
        movedTask.x = newPitchAndYaw[1]
        movedTask.y = newPitchAndYaw[0]
      }
      //Refresh directly locally for faster response
      refreshDraggableMarkersToMap(_tasks, onMoveTask)

      onMoveTask(aTask.id, newPitchAndYaw[1], newPitchAndYaw[0])
    }

    function onDragStart(evt: any) {
      window.addEventListener('mousemove', onDrag)
      window.addEventListener('mouseup', onDragEnd)
      const markerRect = evt.target.getBoundingClientRect()
      markerCenterXDiff = evt.clientX - (markerRect.left + markerRect.width / 2)
      markerCenterYDiff = evt.clientY - (markerRect.top + markerRect.height)
      movementIcon.replaceChildren(getInnerIcon(aTask))
    }

    markerIcon.onmousedown = onDragStart
  })
}
