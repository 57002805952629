import * as React from 'react'
import { SVGProps } from 'react'
const SvgEyeCrossedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='1em' viewBox='0 0 32 32' width='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      clipRule='evenodd'
      d='M28.595 3.588a.938.938 0 0 1-.027 1.326L5.127 27.418a.938.938 0 0 1-1.299-1.353L27.27 3.561a.938.938 0 0 1 1.325.027zm-3.663 7.448a.938.938 0 0 1 1.31-.204 26.669 26.669 0 0 1 3.984 3.572l.004.004a3.016 3.016 0 0 1 0 4.038l-.003.003c-3.275 3.604-8.708 7.442-14.23 7.355-1.38.01-2.753-.195-4.07-.604a.938.938 0 1 1 .556-1.79c1.137.352 2.321.528 3.51.518h.023c4.725.08 9.682-3.284 12.821-6.738.39-.434.39-1.09 0-1.525a24.785 24.785 0 0 0-3.7-3.319.938.938 0 0 1-.205-1.31zm-5.689-3.605a12.902 12.902 0 0 0-3.24-.38c-5.432-.085-10.878 3.67-14.225 7.352l-.003.003a3.016 3.016 0 0 0 0 4.038l.005.005a27.044 27.044 0 0 0 3.367 3.108.938.938 0 0 0 1.151-1.48 25.17 25.17 0 0 1-3.131-2.89 1.141 1.141 0 0 1 0-1.525c3.204-3.523 8.176-6.813 12.818-6.736h.026c.936-.01 1.871.099 2.78.325a.938.938 0 0 0 .452-1.82zm-3.245 3.37H16a.939.939 0 0 1 .002 1.876h-.002a3.75 3.75 0 0 0-3.749 3.75.938.938 0 1 1-1.875 0 5.624 5.624 0 0 1 5.623-5.626zm5.629 5.625a.938.938 0 0 0-1.876 0 3.751 3.751 0 0 1-3.75 3.752.938.938 0 0 0 0 1.875h.001a5.626 5.626 0 0 0 5.625-5.628z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
)
export default SvgEyeCrossedIcon
