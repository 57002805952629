import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { copyOwnGame, deleteOwnGame, getOwnGames, getOwnGamesOwners, PAGE_SIZE } from '../api/gameApiService'
import { GetSponsoredActivation, LibrarySource } from '../api/gameTypes'
import { gameCreatorDataToVm, parseGamesResponseToGameCards } from '../api/typeConverters'
import { PaginationSelectOption } from '../composites/GamesOverview/components/PaginationControls'
import { useGameNotifications } from '../hooks/useGameNotifications'
import { GameCreatorFilterOption, TGameCard, Try } from '../types/commonTypes'
import { areObjectsEqual, noop } from '../util/functional'
import { useUser } from './userContext'

export const PAGE_SIZE_OPTIONS = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
]

export type GameFilters = {
  search?: string
  language?: string
  subject?: string
  age?: string
  updatedAt?: string
  approvedBySeppo?: string
  source?: LibrarySource
  creator?: number
  status?: string
  updatedAtFrom?: string
  updatedAtUntil?: string
  activationCode?: string
}

export interface GamesSourceCount {
  total: number
  own: number
  community: number
  orgLibrary: number
  template: number
  sponsored: number
}

export type TGameAction = (gameId: number, gameName: string) => void

export enum GameSortBy {
  NAME_ASC = 'name asc',
  NAME_DESC = 'name desc',
  UPDATED_AT_ASC = 'updated_at asc',
  UPDATED_AT_DESC = 'updated_at desc',
}

export interface GameDataProviderInterface {
  currentPage: number
  pageSize: number
  pageData: TGameCard[] | undefined
  loading: boolean
  lastPage: number
  allPageOptions: PaginationSelectOption[]
  selectPageSize: (newPageSize: number) => void
  prev: () => void
  next: () => void
  setPage: (newPage: number) => void
  copyGame: TGameAction
  deleteGame: TGameAction
  importGame: TGameAction
  activateGame?: (
    email?: string,
    schoolName?: string,
    sponsoredCode?: string,
    gameId?: string,
  ) => Promise<Try<GetSponsoredActivation>> | null
  refreshGames: () => void
  sortBy?: GameSortBy
  setSortBy?: (sortBy: GameSortBy) => void
  filters?: GameFilters
  setFilters?: (filters: GameFilters | Partial<GameFilters>, isPartial?: boolean) => void
  gamesSourceCount?: GamesSourceCount
  initialFetchDone?: boolean
  gameCreators?: GameCreatorFilterOption[]
}

// TODO: Add last
const OwnGamesDataContext = createContext<GameDataProviderInterface>({
  currentPage: 1,
  pageSize: PAGE_SIZE,
  pageData: undefined,
  loading: true,
  // TODO: we need a total number of items to determine the last page so we can disable paginating after that point
  lastPage: 1,
  allPageOptions: [] as PaginationSelectOption[],
  selectPageSize: noop,
  prev: noop,
  next: noop,
  setPage: noop,
  copyGame: noop,
  deleteGame: noop,
  importGame: noop,
  refreshGames: noop,
  sortBy: GameSortBy.UPDATED_AT_DESC,
  setSortBy: noop,
})

export const OwnGamesDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageData, setPageData] = useState<TGameCard[]>()
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)
  const [loading, setLoading] = useState(true)
  const [lastPage, setLastPage] = useState<number>(1)
  const [allPageOptions, setAllPageOptions] = useState<PaginationSelectOption[]>([{ value: 1, label: '1' }])
  const [sortBy, setSortBy] = useState<GameSortBy>(GameSortBy.UPDATED_AT_DESC)
  const { user } = useUser()
  const [filtersState, setFiltersState] = useState<GameFilters>({})
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false)
  const [gameCreators, setGameCreators] = useState<GameCreatorFilterOption[]>([])

  const {
    notifyGameDeleteStart,
    notifyGameDeleteFinish,
    notifyGameDuplicateStart,
    notifyGameDuplicateFinish,
    notifyFetchingGamesFailed,
  } = useGameNotifications()

  const next = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState + 1)
  }, [loading])

  const prev = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState - 1)
  }, [loading])

  const selectPageSize = useCallback(
    (newPageSize: number) => {
      if (loading) {
        return
      }

      setCurrentPage(1)
      setPageSize(newPageSize)
    },
    [loading],
  )

  const setPage = useCallback(
    (newPage: number) => {
      if (loading) {
        return
      }

      setCurrentPage(newPage)
    },
    [loading],
  )

  useEffect(() => {
    if (user?.id == null) {
      return
    }
    const abortController = new AbortController()
    setLoading(true)
    getOwnGames({
      perPage: pageSize,
      page: currentPage,
      userId: user?.id,
      orderBy: sortBy,
      filters: filtersState,
    })
      .then((ownGamesResponse) => {
        if (ownGamesResponse.success) {
          setLastPage(Math.ceil(ownGamesResponse.value.count / pageSize))
          setPageData(parseGamesResponseToGameCards(ownGamesResponse.value, LibrarySource.OWN))
        } else {
          notifyFetchingGamesFailed()
          console.error(ownGamesResponse.error)
        }
      })
      .finally(() => {
        setLoading(false)
        setInitialFetchDone(true)
      })
    return () => abortController.abort()
  }, [currentPage, sortBy, pageSize, user?.id, notifyFetchingGamesFailed, filtersState])

  useEffect(() => {
    const calculateAllPageOptions = Array.from({ length: lastPage }, (_, i) => ({
      value: i + 1,
      label: `${i + 1}`,
    }))
    setAllPageOptions(calculateAllPageOptions)
  }, [lastPage])

  useEffect(() => {
    getOwnGamesOwners().then((response) => {
      if (response.success) {
        setGameCreators(gameCreatorDataToVm(response.value))
      }
    })
  }, [])

  const refreshGames = useCallback(() => {
    if (user?.id == null) {
      return
    }
    getOwnGames({
      perPage: pageSize,
      page: currentPage,
      userId: user?.id,
      orderBy: sortBy,
      filters: filtersState,
    }).then((ownGamesResponse) => {
      if (ownGamesResponse.success) {
        setPageData(parseGamesResponseToGameCards(ownGamesResponse.value, LibrarySource.OWN))
        setLastPage(Math.ceil(ownGamesResponse.value.count / pageSize))
      } else {
        console.error(ownGamesResponse.error)
      }
    })
  }, [pageSize, currentPage, sortBy, user?.id, filtersState])

  const copyGame = useCallback(
    async (gameId: number, gameName: string) => {
      const notifyId = notifyGameDuplicateStart(gameName)
      const copyResponse = await copyOwnGame({ gameId })
      if (copyResponse.success && pageData) {
        const combinedArray = parseGamesResponseToGameCards(copyResponse.value, LibrarySource.OWN).concat(pageData)
        setPageData(combinedArray)
      } else if (!copyResponse.success) {
        console.error(copyResponse.error)
      }
      notifyGameDuplicateFinish(notifyId, gameName, copyResponse.success)
    },
    [pageData, notifyGameDuplicateFinish, notifyGameDuplicateStart],
  )

  const deleteGame = useCallback(
    async (gameId: number, gameName: string, force = false) => {
      const notifyId = notifyGameDeleteStart(gameName)
      const deleteResponse = await deleteOwnGame({ gameId, force })
      if (deleteResponse.success && pageData) {
        const newArray = pageData.filter((game) => parseInt(game.id) !== gameId)
        setPageData(newArray)
      } else if (!deleteResponse.success) {
        console.error(deleteResponse.error)
      }
      notifyGameDeleteFinish(notifyId, gameName, deleteResponse.success)
    },
    [pageData, notifyGameDeleteStart, notifyGameDeleteFinish],
  )

  const setFilters = useCallback((newFilters: GameFilters | Partial<GameFilters>, isPartial?: boolean) => {
    setFiltersState((prev) => {
      const filters = isPartial ? { ...prev, ...newFilters } : (newFilters as GameFilters)
      const unChanged = areObjectsEqual(filters, prev)
      if (!unChanged) {
        setCurrentPage(1)
      }
      return unChanged ? prev : filters
    })
  }, [])

  return (
    <OwnGamesDataContext.Provider
      value={{
        currentPage,
        pageSize,
        pageData,
        lastPage,
        allPageOptions,
        loading,
        initialFetchDone,
        filters: filtersState,
        gameCreators,
        sortBy,
        setPage,
        selectPageSize,
        next,
        prev,
        copyGame,
        deleteGame,
        importGame: noop,
        refreshGames,
        setFilters,
        setSortBy,
      }}
    >
      {children}
    </OwnGamesDataContext.Provider>
  )
}

export const useGameData = () => {
  const context = useContext(OwnGamesDataContext)

  return context
}
