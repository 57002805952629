import 'leaflet/dist/leaflet.css'
import TextPath from 'react-leaflet-textpath'
import styles from './DoorConnectionLine.module.css'
import { GameBoard } from '../../../types'
import { THUMBNAIL_IMAGE_SIZE, getX, getY } from './BoardsOverviewHelper'

type DoorConnectionLineProps = {
  boardFrom?: GameBoard
  boardTo?: GameBoard
  twoWay: boolean
  isActive: boolean
}

export const DoorConnectionLine: React.FC<DoorConnectionLineProps> = ({ boardFrom, boardTo, twoWay, isActive }) => {
  if (!boardFrom || !boardTo) return null

  return (
    <TextPath
      key={boardFrom.mapIndex + '->' + boardTo.mapIndex + ' ' + isActive}
      weight={twoWay ? 3 : 0}
      className={isActive ? styles.activeLine : styles.normalLine}
      positions={[
        [getY(boardFrom, THUMBNAIL_IMAGE_SIZE * 0.75), getX(boardFrom)],
        [getY(boardTo, THUMBNAIL_IMAGE_SIZE * 0.75), getX(boardTo)],
      ]}
      text={twoWay ? '' : '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>'}
      center
      attributes={{
        fill: isActive ? 'var(--primary-normal)' : 'var(--grey-500)',
        'font-weight': 'bold',
      }}
    />
  )
}
