import * as React from 'react'
import { SVGProps } from 'react'
const SvgOrganizationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 32 32' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.311.808A2.576 2.576 0 0 1 5.896.263h16.208a2.576 2.576 0 0 1 2.498 1.95l1.23 4.918h1.047a.859.859 0 1 1 0 1.717h-.859v18.03a.859.859 0 0 1-.858.86H2.839a.858.858 0 0 1-.86-.86V8.849h-.858a.859.859 0 1 1 0-1.717h1.047l1.23-4.918c.139-.557.46-1.051.913-1.405zm-.373 6.323h20.124l-1.125-4.5a.858.858 0 0 0-.833-.651H5.896a.859.859 0 0 0-.833.65zm-.24 1.717V26.02h6.868v-2.576a2.576 2.576 0 0 1 2.575-2.576h1.718a2.576 2.576 0 0 1 2.575 2.576v2.576h6.869V8.848zm8.585 17.172h3.434v-2.576a.859.859 0 0 0-.858-.858H13.14a.858.858 0 0 0-.858.858zm-6.01-15.454c.474 0 .858.384.858.858v1.717a.859.859 0 1 1-1.717 0v-1.717c0-.474.385-.858.859-.858zm6.01.858a.859.859 0 1 0-1.717 0v1.717a.859.859 0 1 0 1.717 0zm4.293-.858c.474 0 .858.384.858.858v1.717a.859.859 0 1 1-1.717 0v-1.717c0-.474.385-.858.859-.858zm6.01.858a.859.859 0 1 0-1.717 0v1.717a.859.859 0 1 0 1.717 0zM6.273 15.717c.474 0 .858.384.858.859v1.717a.859.859 0 1 1-1.717 0v-1.717c0-.475.385-.859.859-.859zm6.01.859a.859.859 0 1 0-1.717 0v1.717a.859.859 0 1 0 1.717 0zm4.293-.859c.474 0 .858.384.858.859v1.717a.859.859 0 1 1-1.717 0v-1.717c0-.475.385-.859.859-.859zm6.01.859a.859.859 0 1 0-1.717 0v1.717a.859.859 0 1 0 1.717 0zM6.273 20.868c.474 0 .858.385.858.86v1.716a.859.859 0 0 1-1.717 0v-1.717c0-.474.385-.859.859-.859zm16.313.86a.859.859 0 1 0-1.717 0v1.716a.859.859 0 0 0 1.717 0z'
      fill={props.color || 'currentColor'}
    />
  </svg>
)
export default SvgOrganizationIcon
