import { useGameData } from '../../contexts/SponsoredGamesContextProvider'
import styles from './Sponsored.module.css'
import { SponsoredHeader } from './components/SponsoredHeader'
import { Select, SelectOption } from '../../common/components/Select/Select'
import { LANGUAGE_SELECT_DEFAULT_OPTION, LANGUAGE_SELECT_OPTIONS } from '../../common/constants'
import { useTheme } from '../../contexts/ThemeContext'
import i18n from '../../i18n'
import { SingleValue } from 'react-select'
import { useCallback, useEffect, useState } from 'react'
import { getIcon } from '../../common/components/icons/utils'
import { Sponsored } from './Sponsored'
import { useUser } from '../../contexts/userContext'

export const SponsoredShopPageLayout: React.FC = () => {
  const { doSilentLogout, user } = useUser()
  const options = LANGUAGE_SELECT_OPTIONS
  const { loginLanguage, loginHideLanguageSelection } = useTheme()
  const [currentLanguageOption, setCurrentLanguageOption] = useState(LANGUAGE_SELECT_DEFAULT_OPTION)
  const gameData = useGameData()

  const handleChangeLanguage = (selectOption: SingleValue<SelectOption>) => {
    i18n.changeLanguage(selectOption?.value)
    setCurrentLanguageOption(selectOption as SelectOption)
  }

  const handleSilentLogout = useCallback(() => {
    doSilentLogout()
  }, [doSilentLogout])

  /* Note! To test this view both as a logged in and out user, remove the useEffect below and uncomment the logout button */
  useEffect(() => {
    if (user) {
      handleSilentLogout()
    }
  }, [user, handleSilentLogout])

  return (
    <div className={styles.positionContainer}>
      <div className={styles.container}>
        <div className={styles.menubar}>
          <a href='https://seppo.io/' className={styles.logoContainer}>
            {getIcon('seppo')}
          </a>
          <div className={styles.languageContainer}>
            {!loginHideLanguageSelection && (
              <Select
                options={options}
                value={
                  loginLanguage
                    ? LANGUAGE_SELECT_OPTIONS.find((option) => option.value === loginLanguage) ?? currentLanguageOption
                    : currentLanguageOption
                }
                onChange={handleChangeLanguage}
                className={styles.languageSelect}
              />
            )}
          </div>
        </div>
        <div className={styles.content}>
          <SponsoredHeader />
          <Sponsored isInstructorView={false} gameData={gameData} />
        </div>
      </div>
    </div>
  )
}
