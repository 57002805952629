import { Maybe, toFailure, toSuccess, Try } from '../types/commonTypes'
import { createAxios } from './axios'
import {
  DeleteChatMessageParams,
  GetMessagingParams,
  GetMessagingResponse,
  RawMessage,
  SendChatMessageParams,
  SendChatMessageResponse,
} from './messagingTypes'
import { TEST_USER_AUTHORIZATION } from './userApiService'

export async function getMessaging({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: GetMessagingParams): Promise<Try<GetMessagingResponse>> {
  try {
    const response = await createAxios(authorization).get<GetMessagingResponse>(`/games/${gameId}/init_messaging.json`)

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function sendChatMessage({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  message,
  to,
}: SendChatMessageParams): Promise<Try<Maybe<RawMessage>>> {
  try {
    const response = await createAxios(authorization).post<SendChatMessageResponse>(`/games/${gameId}/message.json`, {
      message,
      to,
      cc: false, // RFU, statically false on purpose for now
    })

    const { data } = response

    return toSuccess(data.chat_update)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function deleteChatMessage({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  messageId,
}: DeleteChatMessageParams): Promise<Try<{}>> {
  try {
    const response = await createAxios(authorization).delete<{}>(`/messages/${messageId}.json`, {
      data: { game_id: gameId },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}
