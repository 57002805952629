import { PlayerAccount } from '../../../../../../types/commonTypes'
import styles from './PlayersAddByPlayerAccountModal.module.css'
import { ListLinePlayerAccount } from './PlayerAccountModalListLines'
import { ThreeDots } from '../../../../../../common/components/loaders/ThreeDots/ThreeDots'
import { getIcon } from '../../../../../../common/components/icons/utils'

type PlayerAccountsSublistProps = {
  onPlayerAccountSelected?: (playerAccount: PlayerAccount, toSelected: boolean) => void
  playerAccounts: PlayerAccount[] | null
  onBack: () => void
  sublistTitle: string
  searching: boolean
  searchInputValue: string
  toAddPlayerAccounts: PlayerAccount[]
}

export const PlayerAccountsSublist: React.FC<PlayerAccountsSublistProps> = ({
  onPlayerAccountSelected,
  playerAccounts,
  onBack,
  sublistTitle,
  searching,
  searchInputValue,
  toAddPlayerAccounts,
}) => {
  return (
    <div className={styles.contentList}>
      <div onClick={() => onBack()} className={styles.subResultTitle}>
        <span>{getIcon('chevron')}</span>
        {sublistTitle}
      </div>
      {searching && (
        <div className={styles.loadingIndicator}>
          <ThreeDots />
        </div>
      )}
      {playerAccounts &&
        playerAccounts.map((playerAccount, index) => {
          return (
            <ListLinePlayerAccount
              key={index}
              showSecondLine
              isSelected={!!toAddPlayerAccounts.find((pa) => pa.id === playerAccount.id)}
              onSelect={onPlayerAccountSelected}
              playerAccount={playerAccount}
              searchTerm={searchInputValue}
            />
          )
        })}
    </div>
  )
}
