import classNames from 'classnames'
import React from 'react'
import shared from '../../../common/styles/shared.module.css'
import styles from '../OnboardingWizard.module.css'
import { Trans, useTranslation } from 'react-i18next'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../../common/components/Form/SelectFormField/SelectFormField'
import { COUNTRY_SELECT_OPTIONS } from '../constants'
import { SelectVariant } from '../../../common/components/Select/Select'
import { ToggleFormField } from '../../../common/components/Form/ToggleFormField/ToggleFormField'
import { EditorPreference, setEditorPreference } from '../../../util/editorPreference'

export const SponsoredStep: React.FC = () => {
  const { t } = useTranslation()

  //When user goes through the Sponsored wizard, set the legacy editor as default
  setEditorPreference(EditorPreference.LEGACY)

  return (
    <>
      <div className={classNames(shared.flex, shared.col, shared.sAxisC)}>
        <h2>{t('onboarding_wizard.name_step.title', 'Hello!')}</h2>
        <h4 className={classNames(styles.SponsoredSpacer, styles.marginBottom10)}>
          {t('onboarding_wizard.name_step.subtitle', 'What is your name?')}
        </h4>
      </div>
      <div className={classNames(shared.flex, shared.mAxisC, shared.gap_075)}>
        <InputFormField
          autoFocus
          fieldContainerClassName={styles.inputField}
          name='firstName'
          placeholder={t('onboarding_wizard.name_step.first_name', 'First name')}
          label={t('onboarding_wizard.name_step.first_name', 'First name')}
          srOnlyLabel
          big
        />
        <InputFormField
          fieldContainerClassName={styles.inputField}
          name='lastName'
          placeholder={t('onboarding_wizard.name_step.last_name', 'Last name')}
          label={t('onboarding_wizard.name_step.last_name', 'Last name')}
          srOnlyLabel
          big
        />
      </div>
      <div className={styles.countryInput}>
        <h4 className={styles.SponsoredSpacer}>
          {t('onboarding_wizard.language_step.location', 'Where do you live?')}
        </h4>
        <SelectFormField
          name='country'
          isSearchable
          options={COUNTRY_SELECT_OPTIONS}
          placeholder={t('onboarding_wizard.language_step.select_placeholder', 'Type or select country')}
          label={t('onboarding_wizard.language_step.location', 'Where do you live?')}
          srOnlyLabel
          variant={SelectVariant.XLarge}
        />
      </div>
      <div className={classNames(styles.tosToggleContainer, styles.SponsoredSpacer)}>
        <ToggleFormField
          fieldContainerClassName={styles.toggleField}
          controlWithLabelClassName={styles.alignVertically}
          name='termsOfService'
          label={t('onboarding_wizard.tos.label', 'I accept the terms*')}
          description={
            <Trans i18nKey={'onboarding_wizard.tos.label_subtext'}>
              Read our full{' '}
              <a
                aria-label={t('onboarding_wizard.tos.aria_label', 'Terms of use')}
                href='https://play.seppo.io/terms'
                target='_blank'
                rel='noreferrer'
              >
                Terms of Use
              </a>
            </Trans>
          }
        />
      </div>
    </>
  )
}
